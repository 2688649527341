import React from 'react';
import { Box } from "@mui/material";
import Sidebar from "../../Components/Sidebar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Button from '@mui/material/Button';

function MobileApps() {
  const fileUrl = process.env.REACT_APP_DOWNLOAD_APK_URL;
	return (
		<Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Sidebar />
        <Box
          component="main"
          sx={{
            ml: "-4%",
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <div style={{ padding: "25px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", gap: "10px" }}>
              </div>
            </div>
            <h2>Aplikasi Mobile</h2>
            <p>Manajemen stok gudang lebih mudah dengan mengakses di mobile.</p>
            <p>Ukuran APK: 20Mb</p>
            <a href={fileUrl} target="_blank">
            <Button variant="outlined" size="small">Download APK</Button>
            </a>
          </div>
        </Box>
      </Box>
  );
	}
export default MobileApps;	
