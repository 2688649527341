import { React, useEffect, useState } from "react";
import { Box, Tooltip, TextField, MenuItem, Autocomplete} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { toast } from "react-hot-toast";
import { alternatingStyles } from "../../style/generalStyle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
// icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

// external
import Sidebar from "../../Components/Sidebar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getAccessToken } from "../../Services/AuthService";
import AddDataModal from "./AddDataModal";
import EditDataModal from "./EditDataModal";
import DeleteDataModal from "./DeleteDataModal";
import SearchBar from "../../Components/SearchBar";
import ImportDataModal from "./ImportDataModal";
import EditDataMasterKomoditasModal from "./EditDataMasterKomoditasModal";
import { getMasterKomoditasPromise } from "../../Services/KomoditasService";
import {
  fetchDistrict,
  fetchProvince,
  fetchRegency,
  fetchSubDistrict,
} from "../../Services/Hook/AreaService";
import { dataCompanyAgency, dataTipeGudang } from "../../constant";
import {
  getAllMasterPelakuUsaha,
  getCompany,
  getMasterGudang,
} from "./FilterService";

const alternatingRowStyles = {
  "& .MuiDataGrid-row:nth-of-type(odd)": {
    backgroundColor: "#88CC81",
  },
  "& .MuiDataGrid-row:nth-of-type(even)": {
    backgroundColor: "#B1E1AD",
  },
};

const defaultTheme = createTheme();

function MasterGudang() {
  // token
  const accessToken = getAccessToken();
  const [isLoading, setIsLoading] = useState(false);
  // form and filter
  const [dataCompany, setDataCompany] = useState([]);
  const [dataMasterPelakuUsaha, setDataMasterPelakuUsaha] = useState([]);
  const [dataMasterKomoditas, setDataMasterKomoditas] = useState([]);
  const [dataMasterGudang, setDataMasterGudang] = useState([]);
  const [dataProvince, setDataProvince] = useState([]);
  const [dataTotalRows, setDataTotalRows] = useState("");
  // form
  const [dataRegencyForm, setDataRegencyForm] = useState([]);
  const [dataDistrictForm, setDataDistrictForm] = useState([]);
  const [dataSubDistrictForm, setDataSubDistrictForm] = useState([]);

  // filter
  const [dataRegency, setDataRegency] = useState([]);
  const [dataDistrict, setDataDistrict] = useState([]);
  const [dataSubDistrict, setDataSubDistrict] = useState([]);

  const [selectedTipeGudang, setSelectedTipeGudang] = useState("");
  const [selectedProviceId, setSelectedProviceId] = useState("");
  const [selectedRegencyId, setSelectedRegencyId] = useState("");
  const [selectedDistrictId, setSelectedDistrictId] = useState("");
  const [selectedSubDistrictId, setSelectedSubDistrictId] = useState("");
  const [selectedCompanyAgency, setSelectedCompanyAgency] = useState("");
  const [selectedMasterKomoditasId, setSelectedMasterKomoditasId] = useState(
    []
  );
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedPelakuUsahaId, setSelectedPelakuUsahaId] = useState("");
  const [selectedKapasitas, setSelectedKapasitas] = useState("");

  const [selectedPage, setSelectedPage] = useState(0);
  const [selectedPageSize, setSelectedPageSize] = useState(25);

  const handleChangePage = (event, newPage) => {
    setSelectedPage(newPage);
  };

  const handleChangePageSize = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setSelectedPageSize(newSize);
    setSelectedPage(0);
  };

  const fetchMasterGudang = async (
    selectedTipeGudang,
    selectedProviceId,
    selectedRegencyId,
    selectedDistrictId,
    selectedSubDistrictId,
    selectedCompanyAgency,
    selectedCompany,
    selectedMasterKomoditasId,
    selectedPelakuUsahaId,
    selectedKapasitas,
    selectedPageSize,
    selectedPage
  ) => {
    try {
      await getMasterGudang({
        access_token: accessToken,
        setData: setDataMasterGudang,
        setDataTotalRows: setDataTotalRows,
        province_id: selectedProviceId,
        regency_id: selectedRegencyId,
        district_id: selectedDistrictId,
        sub_district_id: selectedSubDistrictId,
        tipe_gudang: selectedTipeGudang,
        company_agency: selectedCompanyAgency,
        company_ids: selectedCompany,
        master_komoditas_id: selectedMasterKomoditasId,
        master_pelaku_usaha_id: selectedPelakuUsahaId,
        kapasitas: selectedKapasitas,
        size: selectedPageSize,
        page: selectedPage,
      });
    } catch (error) {
      console.error("Failed to fetch master gudang");
    }
  };

  const fetchData = async () => {
    try {
      await fetchProvince(accessToken, setDataProvince, true);
      await getMasterKomoditasPromise(
        accessToken,
        setDataMasterKomoditas,
        true
      );
    } catch (error) {
      console.error("Failed to fetch data");
    }
  };

  useEffect(() => {
    getAllMasterPelakuUsaha(
      accessToken,
      setDataMasterPelakuUsaha,
      selectedCompanyAgency,
      true
    );
  }, [accessToken, selectedCompanyAgency]);

  useEffect(() => {
    getCompany(
      accessToken,
      setDataCompany,
      selectedCompanyAgency,
      selectedProviceId,
      selectedRegencyId,
      selectedPelakuUsahaId
    );
  }, [
    accessToken,
    selectedCompanyAgency,
    selectedProviceId,
    selectedRegencyId,
    selectedPelakuUsahaId,
  ]);

  useEffect(() => {
    fetchData();
  }, [accessToken]);

  useEffect(() => {
    fetchMasterGudang(
      selectedTipeGudang,
      selectedProviceId,
      selectedRegencyId,
      selectedDistrictId,
      selectedSubDistrictId,
      selectedCompanyAgency,
      selectedCompany,
      selectedMasterKomoditasId,
      selectedPelakuUsahaId,
      selectedKapasitas,
      selectedPageSize,
      selectedPage
    );
  }, [
    accessToken,
    selectedTipeGudang,
    selectedProviceId,
    selectedRegencyId,
    selectedDistrictId,
    selectedSubDistrictId,
    selectedCompanyAgency,
    selectedCompany,
    selectedMasterKomoditasId,
    selectedPelakuUsahaId,
    selectedKapasitas,
    selectedPageSize,
    selectedPage,
  ]);

  // filter
  const handleProvinceChange = async (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedProviceId(id);
    setSelectedPage(0);
    await fetchRegency(accessToken, setDataRegency, id, true);
    // reset
    setSelectedPage(0);
    setSelectedRegencyId("");
    setSelectedDistrictId("");
    setSelectedSubDistrictId("");
    setDataDistrict([]);
    setDataSubDistrict([]);
  };

  const handleRegencyChange = async (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedRegencyId(id);
    await fetchDistrict(accessToken, setDataDistrict, id, true);
    // reset
    setSelectedPage(0);
    setSelectedDistrictId("");
    setSelectedSubDistrictId("");
    setDataSubDistrict([]);
  };

  const handleDistrictChange = async (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedDistrictId(id);
    await fetchSubDistrict(accessToken, setDataSubDistrict, id, true);
    // reset
    setSelectedPage(0);
    setSelectedSubDistrictId("");
  };

  const handleSubDistrictChange = async (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedSubDistrictId(id);
    setSelectedPage(0);
  };

  const handleTipeGudangChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedTipeGudang(id);
    setSelectedPage(0);
  };

  const handleCompanyAgencyChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedCompanyAgency(id);
    setSelectedPage(0);
  };

  const handlePelakuUsahaChange = (event) => {
    setSelectedPelakuUsahaId(event.target.value);
    setSelectedPage(0);
  };

  const handleCompanyChange = (newValue) => {
    setSelectedCompany(newValue);
    setSelectedPage(0);
  };

  const handleMasterKomoditasChange = (newValue) => {
    setSelectedMasterKomoditasId(newValue);
    setSelectedPage(0);
  };

  const handleKapasitasChange = (newValue) => {
    setSelectedKapasitas(newValue);
    setSelectedPage(0);
  };

  // download
  const handleDownloadData = async () => {
    setIsLoading(true);
    const queryParams = new URLSearchParams();

    if (selectedProviceId && selectedProviceId !== "") {
      queryParams.append("province_id", selectedProviceId);
    }

    if (selectedRegencyId && selectedRegencyId !== "") {
      queryParams.append("regency_id", selectedRegencyId);
    }

    if (selectedDistrictId && selectedDistrictId !== "") {
      queryParams.append("district_id", selectedDistrictId);
    }

    if (selectedSubDistrictId && selectedSubDistrictId !== "") {
      queryParams.append("sub_district_id", selectedSubDistrictId);
    }

    if (selectedTipeGudang && selectedTipeGudang !== "") {
      queryParams.append("tipe_gudang", selectedTipeGudang);
    }

    if (selectedCompanyAgency && selectedCompanyAgency !== "") {
      queryParams.append("company_agency", selectedCompanyAgency);
    }

    if (selectedMasterKomoditasId && selectedMasterKomoditasId.length > 0) {
      selectedMasterKomoditasId.forEach((id) => {
        queryParams.append("master_komoditas_ids", encodeURIComponent(id));
      });
    }

    if (selectedKapasitas && selectedKapasitas !== "") {
      queryParams.append("kapasitas", selectedKapasitas);
    }

    queryParams.append("is_excel", "true");

    const queryString = queryParams.toString();
    const labelSuffix = selectedTipeGudang
      ? ` - ${
          dataTipeGudang.find((option) => option.id === selectedTipeGudang)
            ?.label
        }`
      : "";
    const apiUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/master-gudang` +
      (queryString ? `?${queryString}` : "");

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Result Gudang${labelSuffix}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to download. Response Status:", response.status);
      }
    } catch (error) {
      console.error("Error downloading template:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // import
  const [openImport, setOpenImport] = useState(false);
  const handleOpenImport = () => setOpenImport(true);
  const handleCloseImport = () => setOpenImport(false);

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleExportData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/master-gudang/export/template/base`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Template Gudang.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error(
          "Gagal Mendownload Template. Respon Status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Mendownload Template, error:", error);
    }
    setIsLoading(false);
  };

  const handleExportMasterAreaData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area/export/template`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Master Area.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error(
          "Gagal Mendownload Template. Respon Status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Mendownload Template, error:", error);
    }
    setIsLoading(false);
  };

  const handleImportData = async () => {
    setIsLoading(true);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/master-gudang/import/template`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Gudang Error.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          toast.error("Error, ada masalah di excel");
        } else if (response.status === 201) {
          toast.success("Data berhasil ditambahkan");
          handleCloseImport();
          await fetchMasterGudang(
            selectedTipeGudang,
            selectedProviceId,
            selectedRegencyId,
            selectedDistrictId,
            selectedSubDistrictId,
            selectedCompanyAgency,
            selectedMasterKomoditasId,
            selectedKapasitas
          );
        } else if (response.status === 400) {
          const data = await response.json();
          toast.error(data.detail);
        } else if (response.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error("Ada masalah");
        }
      } catch (error) {
        console.error(error);
        toast.error("Error");
      }
    }
    setIsLoading(false);
  };

  // handling modal
  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => {
    setDataRegencyForm([]);
    setDataDistrictForm([]);
    setDataSubDistrictForm([]);
    setOpenAdd(false);
  };

  // create data
  const [formData, setFormData] = useState({
    id: "",
    nama_gudang: "",
    tipe_gudang: "berpendingin",
    company_id: "",
    sub_district_id: "",
    address: "",
    longitude: 0,
    latitude: 0,
    kapasitas: 0,
    temp_master_komoditas_datas: [],
    master_komoditas_datas: [],
  });

  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleInputProvinceChange = async (value) => {
    // reset
    setDataDistrictForm([]);
    setDataSubDistrictForm([]);
    setFormData((prevFormData) => ({
      ...prevFormData,
      regency_id: "",
      district_id: "",
      sub_district_id: "",
    }));
    await fetchRegency(accessToken, setDataRegencyForm, value, true);
  };

  const handleInputRegencyChange = async (value) => {
    // reset
    setDataSubDistrictForm([]);
    setFormData((prevFormData) => ({
      ...prevFormData,
      district_id: "",
      sub_district_id: "",
    }));
    await fetchDistrict(accessToken, setDataDistrictForm, value, true);
  };

  const handleInputDistrictChange = async (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      sub_district_id: "",
    }));
    await fetchSubDistrict(accessToken, setDataSubDistrictForm, value, true);
  };

  const handleCreateSubmit = async () => {
    setIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/master-gudang`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Data berhasil dibuat!");
        setFormData({
          ...formData,
          id: "",
          nama_gudang: "",
          tipe_gudang: "berpendingin",
          company_id: "",
          province_id: "",
          regency_id: "",
          district_id: "",
          sub_district_id: "",
          address: "",
          longitude: 0,
          latitude: 0,
          kapasitas: 0,
          temp_master_komoditas_datas: [],
          master_komoditas_datas: [],
        });
        handleCloseAdd();
        await fetchMasterGudang(
          selectedTipeGudang,
          selectedProviceId,
          selectedRegencyId,
          selectedDistrictId,
          selectedSubDistrictId,
          selectedCompanyAgency,
          selectedCompany,
          selectedMasterKomoditasId,
          selectedPelakuUsahaId,
          selectedKapasitas,
          selectedPageSize,
          selectedPage
        );
      } else if (response.status === 422) {
        toast.error("Ada kesalahan saat mengisi form");
      } else if (response.status === 400 || response.status === 404) {
        const data = await response.json();
        toast.error(data.detail || data.data.message);
      } else {
        console.error("Failed to submit data");
        toast.error("Ada kesalahan saat mengisi form");
      }
    } catch (error) {
      console.error("Error during create data submission", error);
    }
    setIsLoading(false);
  };

  // delete table
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const openDeleteConfirmationModal = (itemId) => {
    setItemToDelete(itemId);
    setDeleteModalOpen(true);
  };

  const closeDeleteConfirmationModal = () => {
    setItemToDelete(null);
    setDeleteModalOpen(false);
  };

  const handleDeleteItem = async (itemId) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/master-gudang/${itemId}`;

    try {
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        toast.success("Data berhasil di hapus!");
        await fetchMasterGudang(
          selectedTipeGudang,
          selectedProviceId,
          selectedRegencyId,
          selectedDistrictId,
          selectedSubDistrictId,
          selectedCompanyAgency,
          selectedCompany,
          selectedMasterKomoditasId,
          selectedPelakuUsahaId,
          selectedKapasitas,
          selectedPageSize,
          selectedPage
        );
      } else {
        toast.error("Data gagal dihapus!");
        console.error("Failed to delete item");
      }
    } catch (error) {
      console.error("Error during item deletion", error);
    }
  };

  // edit function
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editMasterKomoditasModalOpen, setEditMasterKomoditasModalOpen] =
    useState(false);

  const handleCloseEdit = () => {
    setEditModalOpen(false);
    setEditMasterKomoditasModalOpen(false);
    setFormData({
      ...formData,
      id: "",
      nama_gudang: "",
      tipe_gudang: "berpendingin",
      company_id: "",
      province_id: "",
      regency_id: "",
      district_id: "",
      sub_district_id: "",
      address: "",
      longitude: 0,
      latitude: 0,
      kapasitas: 0,
      temp_master_komoditas_datas: [],
      master_komoditas_datas: [],
    });
  };

  const handleEditClick = async (rowData) => {
    const province_id = rowData.province.id;
    const regency_id = rowData.regency.id;
    const district_id = rowData.district.id;
    const sub_district_id = rowData.sub_district.id;
    const company_id = rowData.perusahaan.id;

    await fetchRegency(accessToken, setDataRegencyForm, province_id, true);
    await fetchDistrict(accessToken, setDataDistrictForm, regency_id, true);
    await fetchSubDistrict(
      accessToken,
      setDataSubDistrictForm,
      district_id,
      true
    );

    setFormData({
      ...formData,
      id: rowData.id,
      nama_gudang: rowData.nama_gudang,
      tipe_gudang: rowData.tipe_gudang,
      company_id: company_id,
      province_id: province_id,
      regency_id: regency_id,
      district_id: district_id,
      sub_district_id: sub_district_id,
      address: rowData.address,
      longitude: rowData.longitude,
      latitude: rowData.latitude,
      kapasitas: rowData.kapasitas,
    });
    setEditModalOpen(true);
  };

  const handleEditMasterKomoditasClick = (rowData) => {
    const initiallyAssignedData = rowData.master_komoditas?.map(
      (option) => option.id
    );

    setFormData({
      ...formData,
      id: rowData.id,
      master_komoditas_datas: initiallyAssignedData,
      temp_master_komoditas_datas: initiallyAssignedData,
    });
    setEditMasterKomoditasModalOpen(true);
  };

  const handleUpdateData = async () => {
    setIsLoading(true);
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/master-gudang/${formData.id}`;

      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("data berhasil diubah");
        handleCloseEdit();
        await fetchMasterGudang(
          selectedTipeGudang,
          selectedProviceId,
          selectedRegencyId,
          selectedDistrictId,
          selectedSubDistrictId,
          selectedCompanyAgency,
          selectedCompany,
          selectedMasterKomoditasId,
          selectedPelakuUsahaId,
          selectedKapasitas,
          selectedPageSize,
          selectedPage
        );
      } else {
        toast.error("data gagal diubah");
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setIsLoading(false);
  };

  const handleUpdateDataMasterKomoditas = async () => {
    setIsLoading(true);

    const removedData = formData.temp_master_komoditas_datas.filter(
      (item) => !formData.master_komoditas_datas.includes(item)
    );
    const newData = formData.master_komoditas_datas.filter(
      (item) => !formData.temp_master_komoditas_datas.includes(item)
    );

    if (removedData.length > 0) {
      try {
        // Create an empty URLSearchParams object
        const queryParams = new URLSearchParams();

        for (const id of removedData) {
          queryParams.append("master_komoditas_ids", id);
        }

        const queryString = queryParams.toString();
        const apiUrl =
          `${process.env.REACT_APP_API_URL}/api/v1/master-gudang/${formData.id}/unassign/komoditas` +
          (queryString ? `?${queryString}` : "");

        const response = await fetch(apiUrl, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.status === 200) {
          toast.success("data berhasil dihapus");
        } else {
          toast.error("data gagal dihapus");
          console.error("Failed to update data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }

    if (newData.length > 0) {
      try {
        // Create an empty URLSearchParams object
        const queryParams = new URLSearchParams();

        for (const id of newData) {
          queryParams.append("master_komoditas_ids", id);
        }

        const queryString = queryParams.toString();
        const apiUrl =
          `${process.env.REACT_APP_API_URL}/api/v1/master-gudang/${formData.id}/assign/komoditas` +
          (queryString ? `?${queryString}` : "");

        const response = await fetch(apiUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.status === 200) {
          toast.success("data berhasil ditambahkan");
        } else {
          toast.error("data gagal ditambahkan");
          console.error("Failed to update data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }

    await fetchMasterGudang(
      selectedTipeGudang,
      selectedProviceId,
      selectedRegencyId,
      selectedDistrictId,
      selectedSubDistrictId,
      selectedCompanyAgency,
      selectedCompany,
      selectedMasterKomoditasId,
      selectedPelakuUsahaId,
      selectedKapasitas,
      selectedPageSize,
      selectedPage
    );
    handleCloseEdit();

    setIsLoading(false);
  };

  // Locating to Google Maps
  const handleLocateMap = (params) => {
    if (!params.latitude || !params.longitude) {
      toast.error("map invalid. check latitude and longitude");
      console.error("map invalid. check latitude and longitude");
    } else {
      // Open Google Maps with the specified latitude and longitude
      const mapsLink = `https://www.google.com/maps/search/?api=1&query=${params.longitude},${params.latitude}`;
      window.open(mapsLink, "_blank");
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Sidebar />
        <Box
          component="main"
          sx={{
            ml: "-4%",
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <div style={{ padding: "25px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "20px",
                    }}
                  >
                    <SearchBar
                      id={"province-id-autocomplete"}
                      data={dataProvince}
                      value={
                        dataProvince.find(
                          (option) => option.id === selectedProviceId
                        ) ?? null
                      }
                      handleChange={handleProvinceChange}
                      label={"Provinsi"}
                    />
                    <SearchBar
                      id={"regency-id-autocomplete"}
                      data={dataRegency}
                      value={
                        dataRegency.find(
                          (option) => option.id === selectedRegencyId
                        ) ?? null
                      }
                      handleChange={handleRegencyChange}
                      label={"Kota/Kabupaten"}
                    />
                    {/* <SearchBar
                      id={"district-id-autocomplete"}
                      data={dataDistrict}
                      value={
                        dataDistrict.find(
                          (option) => option.id === selectedDistrictId
                        ) ?? null
                      }
                      handleChange={handleDistrictChange}
                      label={"Kecamatan"}
                    /> */}
                    <SearchBar
                      id={"tipe-gudang-autocomplete"}
                      data={dataTipeGudang}
                      value={
                        dataTipeGudang.find(
                          (option) => option.id === selectedTipeGudang
                        ) ?? null
                      }
                      handleChange={handleTipeGudangChange}
                      label={"Tipe Gudang"}
                    />
                    <SearchBar
                      id={"company-agency-autocomplete"}
                      data={dataCompanyAgency}
                      value={
                        dataCompanyAgency.find(
                          (option) => option.id === selectedCompanyAgency
                        ) ?? null
                      }
                      handleChange={handleCompanyAgencyChange}
                      label={"Instansi"}
                    />
                    <Autocomplete
                      id="autocomplete-master-pelaku-usaha"
                      options={dataMasterPelakuUsaha}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        setSelectedPelakuUsahaId(newValue?.id || "");
                        setSelectedPage(0);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Pelaku Usaha"
                          sx={{ width: 200, height: 40 }}
                          size="small"
                        />
                      )}
                    />
                    <TextField
                      id="outlined-select-master-komoditas-id"
                      label="Perusahaan"
                      sx={{
                        width: 200,
                        height: 40,
                      }}
                      size="small"
                      select
                      SelectProps={{
                        multiple: true,
                        value: selectedCompany,
                        onChange: (event) => {
                          handleCompanyChange(event.target.value);
                          setSelectedPage(0);
                        },
                      }}
                    >
                      {dataCompany
                        ?.sort((a, b) => a.label.localeCompare(b.label))
                        .map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.label}
                          </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                      id="outlined-select-master-komoditas-id"
                      label="Komoditas"
                      sx={{
                        width: 200,
                        height: 40,
                      }}
                      size="small"
                      select
                      SelectProps={{
                        multiple: true,
                        value: selectedMasterKomoditasId,
                        onChange: (event) => {
                          handleMasterKomoditasChange(event.target.value);
                          setSelectedPage(0);
                        },
                      }}
                    >
                      {dataMasterKomoditas
                        ?.sort((a, b) => a.label.localeCompare(b.label))
                        .map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.label}
                          </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                      id="outlined-select-kapasitas"
                      label="Kapasitas"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      value={selectedKapasitas}
                      onChange={(e) => handleKapasitasChange(e.target.value)}
                    ></TextField>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "20px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        color: "#32AE2F",
                        borderColor: "#32AE2F",
                      }}
                      onClick={handleOpenAdd}
                    >
                      Create
                    </Button>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        color: "#32AE2F",
                        borderColor: "#32AE2F",
                      }}
                      onClick={handleOpenImport}
                    >
                      Import
                    </Button>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        color: "#32AE2F",
                        borderColor: "#32AE2F",
                      }}
                      disabled={isLoading}
                      onClick={handleDownloadData}
                    >
                      {isLoading ? "Loading..." : "Download"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {/* delete modal */}
              <DeleteDataModal
                modal={{
                  open: deleteModalOpen,
                }}
                handle={{
                  handleClose: closeDeleteConfirmationModal,
                  handleDeleteItem: handleDeleteItem,
                }}
                isLoading={isLoading}
                itemDeleted={itemToDelete}
              />
              {/* create modal */}
              <AddDataModal
                modal={{
                  open: openAdd,
                }}
                handle={{
                  handleClose: handleCloseAdd,
                  handleInputChange: handleInputChange,
                  handleInputProvinceChange: handleInputProvinceChange,
                  handleInputRegencyChange: handleInputRegencyChange,
                  handleInputDistrictChange: handleInputDistrictChange,
                  handleCreateSubmit: handleCreateSubmit,
                }}
                isLoading={isLoading}
                formData={formData}
                dataOption={{
                  dataTipeGudang: dataTipeGudang,
                  dataCompany: dataCompany,
                  dataProvince: dataProvince,
                  dataRegency: dataRegencyForm,
                  dataDistrict: dataDistrictForm,
                  dataSubDistrict: dataSubDistrictForm,
                }}
              />
              {/* import modal */}
              <ImportDataModal
                modal={{
                  open: openImport,
                }}
                handle={{
                  handleClose: handleCloseImport,
                  handleFileChange: handleFileChange,
                  handleExportData: handleExportData,
                  handleExportMasterAreaData: handleExportMasterAreaData,
                  handleImportData: handleImportData,
                }}
                isLoading={isLoading}
                itemDeleted={itemToDelete}
              />
              {/* edit modal */}
              <EditDataModal
                modal={{
                  open: editModalOpen,
                }}
                handle={{
                  handleClose: handleCloseEdit,
                  handleInputChange: handleInputChange,
                  handleInputProvinceChange: handleInputProvinceChange,
                  handleInputRegencyChange: handleInputRegencyChange,
                  handleInputDistrictChange: handleInputDistrictChange,
                  handleUpdate: handleUpdateData,
                }}
                isLoading={isLoading}
                formData={formData}
                dataOption={{
                  dataTipeGudang: dataTipeGudang,
                  dataCompany: dataCompany,
                  dataProvince: dataProvince,
                  dataRegency: dataRegencyForm,
                  dataDistrict: dataDistrictForm,
                  dataSubDistrict: dataSubDistrictForm,
                }}
              />
              {/* edit modal master komoditas */}
              <EditDataMasterKomoditasModal
                modal={{
                  open: editMasterKomoditasModalOpen,
                }}
                handle={{
                  handleClose: handleCloseEdit,
                  handleInputChange: handleInputChange,
                  handleUpdate: handleUpdateDataMasterKomoditas,
                }}
                isLoading={isLoading}
                formData={formData}
                dataOption={{
                  dataMasterKomoditas: dataMasterKomoditas,
                }}
              />
            </div>
            {dataMasterGudang.length > 0 && (
              <>
                {/* <DataGrid
                  sx={{
                    mt: 2,
                    "& .super-app-theme--header": {
                      backgroundColor: "#32AE2F",
                      color: "#000000",
                      border: 2,
                      fontWeight: "bold",
                      borderColor: "#FFF",
                    },
                    ...alternatingRowStyles,
                  }}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "period", sort: "asc" }],
                    },
                    pagination: paginationModel,
                  }}
                  className="alternating-row-grid"
                  getRowId={(row) => row.id}
                  rows={dataMasterGudang}
                  columns={columns}
                  editMode="row"
                  getRowHeight={() => "auto"}
                  pagination
                  paginationMode="client"
                  pageSize={paginationModel.pageSize}
                  page={paginationModel.page}
                  onPaginationModelChange={handlePaginationChange}
                  pageSizeOptions={[5, 25, 50, 100]}
                  rowCount={dataTotalRows}
                /> */}
                <TableContainer
                  sx={{
                    ...alternatingStyles,
                    marginTop: 2,
                  }}
                >
                  <Table sx={{ minWidth: 700 }} aria-label="table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="super-app-theme--header">
                          <TableSortLabel>NAMA GUDANG</TableSortLabel>
                        </TableCell>
                        <TableCell className="super-app-theme--header">
                          <TableSortLabel>TIPE GUDANG</TableSortLabel>
                        </TableCell>
                        <TableCell className="super-app-theme--header">
                          <TableSortLabel>INSTANSI</TableSortLabel>
                        </TableCell>
                        <TableCell className="super-app-theme--header">
                          <TableSortLabel>PERUSAHAAN</TableSortLabel>
                        </TableCell>
                        <TableCell className="super-app-theme--header">
                          <TableSortLabel>KAPASITAS</TableSortLabel>
                        </TableCell>
                        <TableCell className="super-app-theme--header">
                          <TableSortLabel>KOMODITAS</TableSortLabel>
                        </TableCell>
                        <TableCell className="super-app-theme--header">
                          <TableSortLabel>PROVINSI</TableSortLabel>
                        </TableCell>
                        <TableCell className="super-app-theme--header">
                          <TableSortLabel>KOTA/KAB</TableSortLabel>
                        </TableCell>
                        <TableCell className="super-app-theme--header">
                          <TableSortLabel>KECAMATAN</TableSortLabel>
                        </TableCell>
                        <TableCell className="super-app-theme--header">
                          <TableSortLabel>KELURAHAN</TableSortLabel>
                        </TableCell>
                        <TableCell className="super-app-theme--header">
                          <TableSortLabel>ALAMAT</TableSortLabel>
                        </TableCell>
                        <TableCell className="super-app-theme--header">
                          <TableSortLabel>LATITUDE</TableSortLabel>
                        </TableCell>
                        <TableCell className="super-app-theme--header">
                          <TableSortLabel>LONGITUDE</TableSortLabel>
                        </TableCell>
                        <TableCell className="super-app-theme--header">
                          <TableSortLabel>DIBUAT PADA</TableSortLabel>
                        </TableCell>
                        <TableCell className="super-app-theme--header">
                          <TableSortLabel>DIPERBARUI PADA</TableSortLabel>
                        </TableCell>
                        <TableCell
                          className="super-app-theme--header"
                          sx={{ paddingRight: "70px" }}
                        >
                          ACTION
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataMasterGudang?.map((row, index) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            backgroundColor:
                              index % 2 === 0 ? "#B1E1AD" : "#88CC81",
                          }}
                        >
                          <TableCell align="left">{row.nama_gudang}</TableCell>
                          <TableCell align="left">{row.tipe_gudang}</TableCell>
                          <TableCell align="left">
                            {row.perusahaan.instansi}
                          </TableCell>
                          <TableCell align="left">
                            {row.perusahaan.nama_pengelola}
                          </TableCell>
                          <TableCell align="left">{row.kapasitas}</TableCell>
                          <TableCell align="left">
                            {row.total_sub_commodity}
                            <ul>
                              {row.master_komoditas?.map((data) => (
                                <li key={data.id}>{data.nama}</li>
                              ))}
                            </ul>
                          </TableCell>
                          <TableCell align="left">
                            {row.province.name}
                          </TableCell>
                          <TableCell align="left">{row.regency.name}</TableCell>
                          <TableCell align="left">
                            {row.district.name}
                          </TableCell>
                          <TableCell align="left">
                            {row.sub_district.name}
                          </TableCell>
                          <TableCell align="left">{row.address}</TableCell>
                          <TableCell align="left">{row.latitude}</TableCell>
                          <TableCell align="left">{row.longitude}</TableCell>
                          <TableCell align="left">{row.created_at}</TableCell>
                          <TableCell align="left">{row.updated_at}</TableCell>

                          <TableCell align="left">
                            {/* Actions for each row */}
                            <Tooltip title="Edit">
                              <EditIcon
                                className="textPrimary"
                                color="inherit"
                                onClick={() => handleEditClick(row)}
                              />
                            </Tooltip>
                            <Tooltip title="Assign Komoditas">
                              <DriveFileRenameOutlineIcon
                                className="textPrimary"
                                color="inherit"
                                onClick={() =>
                                  handleEditMasterKomoditasClick(row)
                                }
                              />
                            </Tooltip>
                            <Tooltip title="Delete">
                              <DeleteIcon
                                className="textPrimary"
                                color="inherit"
                                onClick={() =>
                                  openDeleteConfirmationModal(row.id)
                                }
                              />
                            </Tooltip>
                            <Tooltip title="View">
                              <VisibilityIcon
                                className="textPrimary"
                                color="inherit"
                                onClick={() => handleLocateMap(row)}
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={dataTotalRows}
                  rowsPerPage={selectedPageSize}
                  page={selectedPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangePageSize}
                />
              </>
            )}
          </div>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default MasterGudang;
