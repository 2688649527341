import {
  MapContainer,
  TileLayer,
  GeoJSON,
  Marker,
  Popup,
  LayersControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";


export default function MapLayout({
  position,
  zoom,
  scrollWheelZoom,
  geoJSONData,
  onEachFeature,
  styleFeature,
  styleContainer,
  markersData,
  renderDataDetail,
  pathOptions,
}) {
  const mapStyle = {
    width: "100%",
    height: "550px",
    marginTop: "20px",
  };

  return (
    <MapContainer
      center={position}
      zoom={zoom}
      scrollWheelZoom={scrollWheelZoom}
      style={{ ...styleContainer, ...mapStyle }}
    >
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="OpenStreetMap">
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="OpenStreetMap.HOT">
          <TileLayer
            attribution="&copy; OpenStreetMap contributors, Tiles style by Humanitarian OpenStreetMap Team hosted by OpenStreetMap France"
            url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="OpenTopoMap">
          <TileLayer
            attribution="Map data: © OpenStreetMap contributors, SRTM | Map style: © OpenTopoMap (CC-BY-SA)"
            url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Tanpa Peta">
          <TileLayer url="" />
        </LayersControl.BaseLayer>

        <LayersControl.Overlay checked name="GeoJSON Layer">
          {geoJSONData && (
            <GeoJSON
              data={geoJSONData}
              onEachFeature={onEachFeature}
              style={styleFeature}
              pathOptions={pathOptions}
            />
          )}
        </LayersControl.Overlay>
        {markersData?.data?.map((data) => {
          const isValidLocation =
            !isNaN(data.latitude) && !isNaN(data.longitude);

            const icon = L.icon({
              iconUrl: data.icon.options.iconUrl,
              iconSize: data.icon.options.iconSize,
              className: data.icon.options.className,
            });

          return isValidLocation ? (
            <Marker
              position={[data.latitude, data.longitude]}
              key={data.id}
              icon={icon}
            >
              <Popup>{renderDataDetail(data)}</Popup>
            </Marker>
          ) : null;
        })}
      </LayersControl>
    </MapContainer>
  );
}
