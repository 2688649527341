import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../../Components/Sidebar";
import { getAccessToken } from "../../Services/AuthService";
import {
  fetchCompany,
  fetchMasterGudang,
  fetchDashboardDate,
} from "./FilterService";
import { getLastFriday, formatDate } from "../../Services/TanggalService";
import DashboardFilter from "./DashboardFilter";
import DashboardSubKomoditas from "../Dashboard/DashboardSubKomoditas";
import { Grid, ImageListItem, ListItemButton } from "@mui/material";
import { getAllMasterPelakuUsaha } from "../../Services/MasterPelakuUsahaService";
import PropTypes from "prop-types";

const defaultTheme = createTheme();

const CardCommodity = ({ data, to }) => {
  return (
    <Grid key={data.id} item>
      <ImageListItem>
        <ListItemButton
          to={to}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              background: `linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 80%), url('${data.thumbnail_url}')`,
              backgroundSize: "cover",
              width: "157px",
              height: "157px",
            }}
          >
            <div>
              {data.total_stok && (
                <p
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    color: "#fff",
                    fontWeight: "600",
                    fontSize: "15px",
                    marginBottom: "-3px",
                    marginTop: "0px",
                    marginLeft: "10px",
                  }}
                >
                  {data.total_stok.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 3,
                  })}{" "}
                  Ton
                </p>
              )}
              <p
                style={{
                  fontFamily: "'Montserrat', sans-serif",
                  color: "#fff",
                  fontWeight: "300",
                  fontSize: "15px",
                  marginTop: "0px",
                  marginLeft: "10px",
                  marginBottom: "10px",
                  textTransform: "capitalize",
                }}
              >
                {data.nama}
              </p>
            </div>
          </div>
        </ListItemButton>
      </ImageListItem>
    </Grid>
  );
};

CardCommodity.propTypes = {
  data: PropTypes.object.isRequired,
};

export default function Dashboard() {
  let accessToken = getAccessToken();

  // filter data
  const [dataCompany, setDataCompany] = useState([]);
  const [dataMasterGudang, setDataMasterGudang] = useState([]);
  const [dataDashboardDate, setDataDashboardDate] = useState([]);
  const [dataDashboardSubKomoditas, setDataDashboardSubKomoditas] = useState(
    []
  );

  // selected filter
  const lastFriday = getLastFriday();
  const formattedLastFriday = formatDate(lastFriday);

  const [selectedDate, setSelectedDate] = useState(
    dataDashboardDate &&
      dataDashboardDate.tanggal &&
      dataDashboardDate.tanggal.length > 0
      ? dataDashboardDate.tanggal[0]
      : ""
  );

  const [selectedMasterGudang, setSelectedMasterGudang] = useState("");
  const [selectedMasterKomoditas, setSelectedMasterKomoditas] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedTipeGudang, setSelectedTipeGudang] = useState("");

  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedRegency, setSelectedRegency] = useState("");

  const [selectedMasterPelakuUsaha, setSelectedMasterPelakuUsaha] =
    useState("");

  const [provinces, setProvinces] = useState([]);
  const [regencies, setRegencies] = useState([]);

  const [dataMasterPelakuUsaha, setDataMasterPelakuUsaha] = useState([]);

  // role
  const [dataProfile, setDataProfile] = useState("");

  // filter
  const [dataDashboard, setDataDashboard] = useState([]);

  const handleChangeCompanyFilter = (event) => {
    setSelectedCompany(event.target.value);
    setSelectedMasterGudang("");
  };

  const handleChangeMasterGudangFilter = (event) => {
    setSelectedMasterGudang(event.target.value);
  };

  const handleChangeMasterPelakuUsahaFilter = (event) => {
    setSelectedMasterPelakuUsaha(event.target.value);
    setSelectedCompany("");
    setSelectedMasterGudang("");
  };

  const handleProvinceChange = (event) => {
    setSelectedProvince(event.target.value || "");
    setSelectedCompany("");
    setSelectedMasterGudang("");
  };

  async function fetchProvince() {
    setProvinces([]);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area/provinces`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const { data } = await response.json();
      setProvinces(data);
    } catch (e) {
      setProvinces([]);
    }
  }

  async function fetchProfile() {
    const queryParams = new URLSearchParams();

    const queryString = queryParams.toString();
    const apiUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/auth/profile` +
      (queryString ? `?${queryString}` : "");
    try {
      setDataProfile([]);
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        const { data } = await response.json();
        setDataProfile(data);
      } else if (response.status === 404) {
        setDataProfile([]);
      }
    } catch (error) {}
  }

  const fetchPelakuUsahaData = async () => {
    try {
      const data = await getAllMasterPelakuUsaha(accessToken, null, true);
      if (data.length > 0) {
        setDataMasterPelakuUsaha(data);
      }
    } catch (error) {
      console.error("Failed to fetch master pelaku usaha");
    }
  };

  async function fetchDataDashboardKomoditas(
    selectedDate = "",
    selectedMasterGudang = "",
    selectedCompany = "",
    selectedTipeGudang = "",
    selectedProvince = "",
    selectedRegency = "",
    setSelectedMasterPelakuUsaha = ""
  ) {
    setDataDashboard([]);

    const queryParams = new URLSearchParams();

    if (selectedMasterGudang && selectedMasterGudang !== "") {
      queryParams.append("master_gudang_id", selectedMasterGudang);
    }

    if (selectedCompany && selectedCompany !== "") {
      queryParams.append("company_id", selectedCompany);
    }

    if (selectedTipeGudang && selectedTipeGudang !== "") {
      queryParams.append("tipe_gudang", selectedTipeGudang);
    }

    if (selectedProvince && selectedProvince !== "") {
      queryParams.append("province_id", selectedProvince);
    }

    if (selectedRegency && selectedRegency !== "") {
      queryParams.append("regency_id", selectedRegency);
    }

    if (setSelectedMasterPelakuUsaha && setSelectedMasterPelakuUsaha !== "") {
      queryParams.append(
        "master_pelaku_usaha_id",
        setSelectedMasterPelakuUsaha
      );
    }

    if (selectedDate && selectedDate !== "") {
      queryParams.append("tanggal", selectedDate);

      const queryString = queryParams.toString();
      const apiUrl =
        `${process.env.REACT_APP_API_URL}/api/v1/dashboard/master-komoditas` +
        (queryString ? `?${queryString}` : "");
  
      try {
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });
  
        if (response.status === 200) {
          const { data } = await response.json();
          setDataDashboard(data);
        } else if (response.status === 404) {
          setDataDashboard([]);
        } else {
          throw new Error("Failed to fetch master gudang");
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function fetchDataDashboardSubKomoditas(
    selectedMasterKomoditas = "",
    selectedDate = "",
    selectedMasterGudang = "",
    selectedCompany = "",
    selectedTipeGudang = "",
    selectedProvince = "",
    selectedRegency = "",
    setSelectedMasterPelakuUsaha = ""
  ) {
    setDataDashboardSubKomoditas([]);

    const queryParams = new URLSearchParams();

    if (selectedDate && selectedDate !== "") {
      queryParams.append("tanggal", selectedDate);
    }

    if (selectedMasterGudang && selectedMasterGudang !== "") {
      queryParams.append("master_gudang_id", selectedMasterGudang);
    }

    if (selectedCompany && selectedCompany !== "") {
      queryParams.append("company_id", selectedCompany);
    }

    if (selectedTipeGudang && selectedTipeGudang !== "") {
      queryParams.append("tipe_gudang", selectedTipeGudang);
    }

    if (selectedProvince && selectedProvince !== "") {
      queryParams.append("province_id", selectedProvince);
    }

    if (selectedRegency && selectedRegency !== "") {
      queryParams.append("regency_id", selectedRegency);
    }

    if (setSelectedMasterPelakuUsaha && setSelectedMasterPelakuUsaha !== "") {
      queryParams.append(
        "master_pelaku_usaha_id",
        setSelectedMasterPelakuUsaha
      );
    }

    const queryString = queryParams.toString();

    if(selectedMasterKomoditas){
      const apiUrl =
        `${process.env.REACT_APP_API_URL}/api/v1/dashboard/master-sub-komoditas/${selectedMasterKomoditas}` +
        (queryString ? `?${queryString}` : "");
  
      try {
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });
  
        if (response.status === 200) {
          const { data } = await response.json();
          setDataDashboardSubKomoditas(data);
        } else if (response.status === 404) {
          setDataDashboardSubKomoditas([]);
        } else {
          throw new Error("Failed to fetch master gudang");
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  const sortedDataSub = [...dataDashboardSubKomoditas].sort((a, b) => {
    return a.nama.localeCompare(b.nama);
  });

  const sortedData = [...dataDashboard].sort((a, b) => {
    return a.nama.localeCompare(b.nama);
  });

  useEffect(() => {
    fetchCompany(
      accessToken,
      setDataCompany,
      selectedProvince,
      selectedMasterPelakuUsaha
    );
  }, [accessToken, selectedProvince, selectedMasterPelakuUsaha]);

  useEffect(() => {
    fetchMasterGudang(
      accessToken,
      setDataMasterGudang,
      selectedProvince,
      selectedCompany,
      ""
    );
  }, [accessToken, selectedProvince, selectedCompany]);

  useEffect(() => {
    if (dataDashboardDate.length > 0) {
      setSelectedDate(dataDashboardDate[0].tanggal);
    }
  }, [dataDashboardDate]);

  useEffect(() => {
    fetchDashboardDate(accessToken, setDataDashboardDate);
  }, [accessToken]);

  useEffect(() => {
    fetchDataDashboardKomoditas(
      selectedDate,
      selectedMasterGudang,
      selectedCompany,
      "",
      selectedProvince,
      "",
      selectedMasterPelakuUsaha
    );
  }, [
    selectedDate,
    selectedMasterGudang,
    selectedCompany,
    selectedProvince,
    selectedMasterPelakuUsaha,
  ]);

  useEffect(() => {
    fetchDataDashboardSubKomoditas(
      selectedMasterKomoditas,
      selectedDate,
      selectedMasterGudang,
      selectedCompany,
      "",
      selectedProvince,
      "",
      selectedMasterPelakuUsaha
    );
  }, [
    selectedMasterKomoditas,
    selectedDate,
    selectedMasterGudang,
    selectedCompany,
    selectedProvince,
    selectedMasterPelakuUsaha,
  ]);

  useEffect(() => {
    fetchProfile();
    fetchProvince();
    fetchPelakuUsahaData();
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Sidebar />
        <Box
          component="main"
          sx={{
            ml: "-4%",
            backgroundColor: defaultTheme.palette.grey[100],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <div
            style={{
              width: "97%",
              marginLeft: "1.7%",
              marginTop: "1.7%",
              marginBottom: "1.7%",
            }}
          >
            <div>
              {/* title */}
              <div style={{ width: "100%" }}>
                <p
                  style={{
                    color: "green",
                    bgcolor: "#000000",
                    fontFamily: "Roboto",
                    fontWeight: 600,
                    fontSize: 23,
                    textAlign: "left",
                    width: "90%",
                    textTransform: "capitalize",
                  }}
                >
                  {dataProfile.role === "admin" ? (
                    <>
                      {!selectedProvince &&
                        !selectedRegency &&
                        "Stok Gudang Nasional"}
                      {selectedProvince && "Stok Gudang Provinsi "}
                      {selectedProvince &&
                        provinces.find((prov) => prov.id === selectedProvince)
                          ?.name}
                      {selectedRegency && ", Kota/Kabupaten "}
                      {selectedRegency &&
                        regencies.find((reg) => reg.id === selectedRegency)
                          ?.name}
                      {selectedDate && " Pada "}
                      {selectedDate &&
                        new Date(selectedDate).toLocaleDateString("id-ID", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                    </>
                  ) : (
                    <>
                      {"Stok Gudang"}
                      {selectedDate &&
                        ` Pada ${new Date(selectedDate).toLocaleDateString(
                          "id-ID",
                          {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )}`}
                    </>
                  )}
                </p>
              </div>

              {/* filter */}
              <DashboardFilter
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                dataDashboardDate={dataDashboardDate}
                selectedCompany={selectedCompany}
                setSelectedCompany={setSelectedCompany}
                handleChangeCompanyFilter={handleChangeCompanyFilter}
                dataCompany={dataCompany}
                selectedMasterGudang={selectedMasterGudang}
                handleChangeMasterGudangFilter={handleChangeMasterGudangFilter}
                dataMasterGudang={dataMasterGudang}
                selectedMasterPelakuUsaha={selectedMasterPelakuUsaha}
                handleChangeMasterPelakuUsahaFilter={
                  handleChangeMasterPelakuUsahaFilter
                }
                dataMasterPelakuUsaha={dataMasterPelakuUsaha}
                selectedProvince={selectedProvince}
                handleProvinceChange={handleProvinceChange}
                setSelectedMasterKomoditas={setSelectedMasterKomoditas}
                selectedMasterKomoditas={selectedMasterKomoditas}
                dataDashboard={dataDashboard}
                provinces={provinces}
                role={dataProfile.role}
              />
            </div>

            {/* card */}

            {!selectedMasterKomoditas ? (
              <Grid container spacing={3} style={{ marginTop: "0px" }}>
                {sortedData.length > 0 ? (
                  sortedData.map((data) => (
                    <CardCommodity data={data} key={data.id} to={`/dashboard/${data.id}`} />
                  ))
                ) : (
                  <Grid item xs={12}>
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: "180px",
                        color: "#000",
                        fontWeight: "600",
                        fontSize: "14px",
                        fontFamily: "'Montserrat', sans-serif",
                      }}
                    >
                      Data kosong
                    </p>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid container spacing={3} style={{ marginTop: "0px" }}>
                {sortedDataSub.length > 0 ? (
                  sortedDataSub.map((data) => (
                    <CardCommodity data={data} key={data.id} to={`/dashboard/sub-komoditas/${data.id}`}/>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: "180px",
                        color: "#000",
                        fontWeight: "600",
                        fontSize: "14px",
                        fontFamily: "'Montserrat', sans-serif",
                      }}
                    >
                      Data kosong
                    </p>
                  </Grid>
                )}
              </Grid>
            )}
          </div>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
