import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-hot-toast";

// external
import Sidebar from "../../Components/SidebarTwo";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getAccessToken, profilePromise } from "../../Services/AuthService";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  fetchDashboardDate,
  fetchPelakuUsaha,
  fetchPerusahaan,
  fetchMasterKomoditas,
  fetchAbsensi,
} from "./FilterService";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
  Autocomplete,
  TextField,
} from "@mui/material";
import { format } from "date-fns";
import { id } from "date-fns/locale";

const defaultTheme = createTheme();

const alternatingRowStyles = {
  "& .MuiDataGrid-row:nth-of-type(odd)": {
    backgroundColor: "#88CC81",
  },
  "& .MuiDataGrid-row:nth-of-type(even)": {
    backgroundColor: "#B1E1AD",
  },
};

export default function Absensi() {
  const location = useLocation();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  // token
  const accessToken = getAccessToken();
  const [isLoading, setIsLoading] = useState(false);
  const [dataProfile, setDataProfile] = useState(null);
  const [dataMasterAbsensi, setDataMasterAbsensi] = useState([]);
  const [dataDashboardDate, setDataDashboardDate] = useState([]);
  const [dataPelakuUsaha, setDataPelakuUsaha] = useState([]);
  const [dataPerusahaan, setDataPerusahaan] = useState([]);
  const [dataMasterKomoditas, setDataMasterKomoditas] = useState([]);

  // filter
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedMasterKomoditas, setSelectedMasterKomoditas] = useState("");
  const [selectedPelakuUsaha, setSelectedPelakuUsaha] = useState("");
  const [selectedPerusahaan, setSelectedPerusahaan] = useState("");
  const [selectedStatusKomoditas, setSelectedStatusKomoditas] = useState("");

  // table

  // profile
  const fetchProfile = async (accessToken) => {
    try {
      const profile = await profilePromise(accessToken);
      setDataProfile(profile);
    } catch (error) {
      console.error("Failed to fetch profile");
    }
  };

  // data
  useEffect(() => {
    fetchProfile(accessToken);
    fetchDashboardDate(accessToken, setDataDashboardDate);
    fetchPelakuUsaha(accessToken, setDataPelakuUsaha);
    fetchMasterKomoditas(accessToken, setDataMasterKomoditas);
  }, [accessToken]);

  useEffect(() => {
    fetchPerusahaan(accessToken, setDataPerusahaan, selectedPelakuUsaha);
  }, [selectedPelakuUsaha]);

  useEffect(() => {
    if (selectedDate) {
      setIsLoading(true);
      fetchAbsensi(
        accessToken,
        (data) => {
          setDataMasterAbsensi(data);
          setIsLoading(false);
        },
        selectedDate,
        selectedMasterKomoditas,
        selectedPelakuUsaha,
        selectedPerusahaan,
        selectedStatusKomoditas
      );
    }
  }, [
    accessToken,
    selectedDate,
    selectedMasterKomoditas,
    selectedPelakuUsaha,
    selectedPerusahaan,
    selectedStatusKomoditas,
  ]);

  useEffect(() => {
    if (dataDashboardDate.length > 0) {
      const initialDate = dataDashboardDate[0].tanggal;
      setSelectedDate(initialDate);
    }
  }, [dataDashboardDate]);

  // filter date
  const handleDateChange = (event, newValue) => {
    const id = event.target.value;
    setSelectedDate(id);
  };

  // filter komoditas
  const handleKomoditasChange = (event, newValue) => {
    const id = event.target.value;
    setSelectedMasterKomoditas(id);
  };

  const handlePelakuUsahaChange = (event) => {
    const id = event.target.value;
    setSelectedPelakuUsaha(id);
  };

  const handlePerusahaanChange = (event) => {
    const id = event.target.value;
    setSelectedPerusahaan(id);
  };

  const handleStatusKomoditasChange = (event) => {
    const id = event.target.value;
    setSelectedStatusKomoditas(id);
  };

  // export
  const handleExport = async () => {
    const queryParams = new URLSearchParams();

    if (selectedDate) {
      queryParams.append("tanggal", selectedDate);
    }

    if (selectedMasterKomoditas) {
      queryParams.append("master_komoditas_id", selectedMasterKomoditas);
    }

    if (selectedPelakuUsaha) {
      queryParams.append("master_pelaku_usaha_id", selectedPelakuUsaha);
    }

    if (selectedPerusahaan) {
      queryParams.append("company_id", selectedPerusahaan);
    }

    if (selectedStatusKomoditas) {
      queryParams.append("kapasitas_status", selectedStatusKomoditas);
    }

    const queryString = queryParams.toString();
    const apiUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/attendance/export` +
      (queryString ? `?${queryString}` : "");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to export data");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "Absensi Result.xlsx";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Export failed:", error);
      toast.error("Failed to export data");
    }
  };

  // columns table
  const columns = [
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "nama_gudang",
      headerName: "NAMA GUDANG",
      width: 230,
      valueGetter: (params) => params.row.nama_gudang || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "master_komoditas",
      headerName: "KOMODITAS (TERISI/BELUM TERISI)",
      width: 280,
      renderCell: (params) => (
        <>
          {params.row.total_sub_commodity}
          <ul>
            {params.row.master_komoditas?.map((data) => (
              <li key={data.id}>
                {data.nama} {data.is_fill ? "(sudah di isi)" : "(belum di isi)"}
              </li>
            ))}
          </ul>
        </>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "percentage_fill_gudang_in_company",
      headerName: "PERSENTASE ABSENSI GUDANG",
      width: 230,
      valueGetter: (params) => {
        const value = params.row.percentage_fill_gudang_in_company;
        if (value) {
          const formattedValue = value.toString().slice(0, 4);
          return `${formattedValue}%`;
        }
        return "";
      },
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "tipe_gudang",
      headerName: "TIPE GUDANG",
      width: 230,
      valueGetter: (params) => params.row.tipe_gudang || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "perusahaan.nama_pengelola",
      headerName: "NAMA PERUSAHAAN",
      width: 280,
      valueGetter: (params) => params.row.perusahaan?.nama_pengelola || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "kapasitas",
      headerName: "KAPASITAS",
      width: 230,
      editable: false,
      valueFormatter: (params) => {
        return params.value.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 3,
        });
      },
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "province",
      headerName: "PROVINSI",
      width: 280,
      valueGetter: (params) => params.row.province?.name || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "regency",
      headerName: "KOTA/KABUPATEN",
      width: 280,
      valueGetter: (params) => params.row.regency?.name || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "district",
      headerName: "KECAMATAN",
      width: 280,
      valueGetter: (params) => params.row.district?.name || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "sub_district",
      headerName: "KELURAHAN",
      width: 280,
      valueGetter: (params) => params.row.sub_district?.name || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "address",
      headerName: "ALAMAT",
      minWidth: 280,
      valueGetter: (params) => params.row.address || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "latitude",
      headerName: "LATITUDE",
      width: 280,
      valueGetter: (params) => params.row.latitude || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "longitude",
      headerName: "LONGITUDE",
      width: 280,
      valueGetter: (params) => params.row.longitude || "",
    },
    // {
    //   field: "actions",
    //   align: "center",
    //   headerAlign: "center",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "ACTIONS",
    //   flex: 1,
    //   minWidth: 200,
    //   renderCell: (params) => (
    //     <div>
    //       <Tooltip
    //         title="Delete"
    //         onClick={() => handleOpenDeleteModal(params.row)}
    //       >
    //         <IconButton>
    //           <DeleteIcon />
    //         </IconButton>
    //       </Tooltip>
    //     </div>
    //   ),
    // },
  ];

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Sidebar />
        <Box
          component="main"
          sx={{
            padding: "20px",
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <div style={{ marginBottom: "10px" }}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <Box sx={{ minWidth: 150 }}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="label-select-tanggal"
                      sx={{ marginTop: "-5px" }}
                    >
                      Tanggal
                    </InputLabel>
                    <Select
                      labelId="label-select-tanggal"
                      id="select-tanggal"
                      value={selectedDate || ""}
                      defaultValue={""}
                      label="Tanggal"
                      name="tanggal"
                      onChange={handleDateChange}
                      sx={{ height: "40px" }}
                    >
                      {dataDashboardDate?.map((item) => (
                        <MenuItem key={item.tanggal} value={item.tanggal}>
                          {format(new Date(item.tanggal), "eeee, dd MMM yyyy", {
                            locale: id,
                          })}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 150 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      id="select-komoditas"
                      options={dataMasterKomoditas || []}
                      getOptionLabel={(option) => option.nama || ""}
                      renderOption={(props, option) => (
                        <li {...props}>{option.nama}</li>
                      )}
                      value={
                        dataMasterKomoditas.find(
                          (item) => item.id === selectedMasterKomoditas
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        handleKomoditasChange({
                          target: {
                            name: "komoditas",
                            value: newValue ? newValue.id : "",
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Komoditas"
                          variant="outlined"
                          size="small"
                          sx={{ height: "40px" }}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 150 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      id="select-pelaku-usaha"
                      options={dataPelakuUsaha || []}
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(props, option) => (
                        <li {...props}>{option.name}</li>
                      )}
                      value={
                        dataPelakuUsaha.find(
                          (item) => item.id === selectedPelakuUsaha
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        handlePelakuUsahaChange({
                          target: {
                            name: "pelaku-usaha",
                            value: newValue ? newValue.id : "",
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Pelaku Usaha"
                          variant="outlined"
                          size="small"
                          sx={{ height: "40px" }}
                          InputLabelProps={{
                            id: "label-select-pelaku-usaha",
                            sx: { marginTop: "-5px" },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 150 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      id="select-perusahaan"
                      options={dataPerusahaan || []}
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(props, option) => (
                        <li {...props}>{option.name}</li>
                      )}
                      value={
                        dataPerusahaan.find(
                          (item) => item.id === selectedPerusahaan
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        handlePerusahaanChange({
                          target: {
                            name: "perusahaan",
                            value: newValue ? newValue.id : "",
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Perusahaan"
                          variant="outlined"
                          size="small"
                          sx={{ height: "40px" }}
                          InputLabelProps={{
                            id: "label-select-perusahaan",
                            sx: { marginTop: "-5px" },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 180 }}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="label-select-status-komoditas"
                      sx={{ marginTop: "-5px" }}
                    >
                      Status Komoditas
                    </InputLabel>
                    <Select
                      labelId="label-select-status-komoditas"
                      id="select-status-komoditas"
                      value={selectedStatusKomoditas || ""}
                      defaultValue={""}
                      label="status-komoditas"
                      name="status-komoditas"
                      onChange={handleStatusKomoditasChange}
                      sx={{ height: "40px" }}
                    >
                      <MenuItem key="" value="">
                        NONE
                      </MenuItem>
                      <MenuItem key="fill" value="fill">
                        TERISI
                      </MenuItem>
                      <MenuItem key="not_fill" value="not_fill">
                        BELUM TERISI
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              {/* {dataProfile?.role === "admin" && ( */}
              <Button
                variant="outlined"
                disableRipple
                sx={{
                  color: "#32AE2F",
                  borderColor: "#32AE2F",
                }}
                onClick={handleExport}
              >
                Download
              </Button>
              {/* )} */}
            </div>
          </div>

          {isLoading ? (
            <div style={{ marginTop: "40vh" }}>
              <center>Loading...</center>
            </div>
          ) : (
            <DataGrid
              getRowHeight={() => "auto"}
              sx={{
                mt: 2,
                "& .super-app-theme--header": {
                  backgroundColor: "#32AE2F",
                  color: "#000000",
                  border: 2,
                  fontWeight: "bold",
                  borderColor: "#FFF",
                },
                ...alternatingRowStyles,
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "period", sort: "asc" }],
                },
              }}
              className="alternating-row-grid"
              loading={isLoading}
              getRowId={(row) => row.id}
              rows={dataMasterAbsensi}
              columns={columns}
              editMode="row"
            />
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
