import { React, useEffect, useState } from "react";
import { Box, Tooltip, Autocomplete, TextField } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { toast } from "react-hot-toast";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
// icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

// external
import Sidebar from "../../Components/Sidebar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getAccessToken } from "../../Services/AuthService";
import AddDataModal from "./AddDataModal";
import EditDataModal from "./EditDataModal";
import DeleteDataModal from "./DeleteDataModal";
import SearchBar from "../../Components/SearchBar";
import ImportDataModal from "./ImportDataModal";
import { getCompany } from "../../Services/CompanyService";
import {
  fetchDistrict,
  fetchProvince,
  fetchRegency,
  fetchSubDistrict,
} from "../../Services/Hook/AreaService";
import { dataCompanyAgency } from "../../constant";
import { getAllMasterPelakuUsaha } from "../../Services/MasterPelakuUsahaService";
import { getAllPelakuUsaha } from "./FilterService";

const alternatingRowStyles = {
  "& .MuiDataGrid-row:nth-of-type(odd)": {
    backgroundColor: "#88CC81",
  },
  "& .MuiDataGrid-row:nth-of-type(even)": {
    backgroundColor: "#B1E1AD",
  },
};

const defaultTheme = createTheme();

function Company() {
  // token
  const accessToken = getAccessToken();
  const [isLoading, setIsLoading] = useState(false);
  // form and filter
  const [dataCompany, setDataCompany] = useState([]);
  const [dataProvince, setDataProvince] = useState([]);
  // form
  const [dataRegencyForm, setDataRegencyForm] = useState([]);
  const [dataDistrictForm, setDataDistrictForm] = useState([]);
  const [dataSubDistrictForm, setDataSubDistrictForm] = useState([]);

  // filter
  const [dataRegency, setDataRegency] = useState([]);
  const [dataDistrict, setDataDistrict] = useState([]);
  const [dataSubDistrict, setDataSubDistrict] = useState([]);
  const [dataPelakuUsaha, setDataPelakuUsaha] = useState([]);
  const [dataMasterPelakuUsaha, setDataMasterPelakuUsaha] = useState([]);
  const [dataMasterPelakuUsahaFiltered, setDataMasterPelakuUsahaFiltered] =
    useState([]);

  const [selectedProviceId, setSelectedProviceId] = useState("");
  const [selectedRegencyId, setSelectedRegencyId] = useState("");
  const [selectedDistrictId, setSelectedDistrictId] = useState("");
  const [selectedSubDistrictId, setSelectedSubDistrictId] = useState("");
  const [selectedCompanyAgency, setSelectedCompanyAgency] = useState("");
  const [selectedPelakuUsahaId, setSelectedPelakuUsahaId] = useState("");

  const fetchCompany = async (
    selectedCompanyAgency,
    selectedProviceId,
    selectedRegencyId,
    selectedDistrictId,
    selectedSubDistrictId
  ) => {
    try {
      await getCompany(
        accessToken,
        setDataCompany,
        selectedCompanyAgency,
        false,
        selectedProviceId,
        selectedRegencyId,
        selectedDistrictId,
        selectedSubDistrictId
      );
    } catch (error) {
      console.error("Failed to fetch company");
    }
  };

  const fetchProvinceData = async () => {
    try {
      await fetchProvince(accessToken, setDataProvince, true);
    } catch (error) {
      console.error("Failed to fetch province");
    }
  };

  const fetchPelakuUsahaData = async () => {
    try {
      const data = await getAllMasterPelakuUsaha(accessToken);
      setDataMasterPelakuUsaha(data);
    } catch (error) {
      console.error("Failed to fetch master pelaku usaha");
    }
  };

  useEffect(() => {
    fetchProvinceData();
    fetchPelakuUsahaData();
  }, [accessToken]);

  useEffect(() => {
    getAllPelakuUsaha(accessToken, setDataPelakuUsaha, selectedCompanyAgency);
  }, [accessToken, selectedCompanyAgency]);

  useEffect(() => {
    fetchCompany(
      selectedCompanyAgency,
      selectedPelakuUsahaId,
      selectedProviceId,
      selectedRegencyId,
      selectedDistrictId,
      selectedSubDistrictId
    );
  }, [
    accessToken,
    selectedCompanyAgency,
    selectedPelakuUsahaId,
    selectedProviceId,
    selectedRegencyId,
    selectedDistrictId,
    selectedSubDistrictId,
  ]);

  // filter
  const handleProvinceChange = async (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedProviceId(id);
    await fetchRegency(accessToken, setDataRegency, id, true);
    // reset
    setSelectedRegencyId("");
    setSelectedDistrictId("");
    setSelectedSubDistrictId("");
    setDataDistrict([]);
    setDataSubDistrict([]);
  };

  const handleCompanyAgencyInputChange = async (event) => {
    setDataMasterPelakuUsahaFiltered(
      dataMasterPelakuUsaha.filter((map) => map.company_agency == event)
    );
  };

  const handleRegencyChange = async (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedRegencyId(id);
    await fetchDistrict(accessToken, setDataDistrict, id, true);
    // reset
    setSelectedDistrictId("");
    setSelectedSubDistrictId("");
    setDataSubDistrict([]);
  };

  const handleDistrictChange = async (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedDistrictId(id);
    await fetchSubDistrict(accessToken, setDataSubDistrict, id, true);
    // reset
    setSelectedSubDistrictId("");
  };

  const handleSubDistrictChange = async (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedSubDistrictId(id);
  };

  const handleCompanyAgencyChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedCompanyAgency(id);
  };

  // download
  const handleDownloadData = async () => {
    setIsLoading(true);
    const queryParams = new URLSearchParams();

    if (selectedProviceId && selectedProviceId !== "") {
      queryParams.append("province_id", selectedProviceId);
    }

    if (selectedRegencyId && selectedRegencyId !== "") {
      queryParams.append("regency_id", selectedRegencyId);
    }

    if (selectedDistrictId && selectedDistrictId !== "") {
      queryParams.append("district_id", selectedDistrictId);
    }

    if (selectedSubDistrictId && selectedSubDistrictId !== "") {
      queryParams.append("sub_district_id", selectedSubDistrictId);
    }

    if (selectedCompanyAgency && selectedCompanyAgency !== "") {
      queryParams.append("company_agency", selectedCompanyAgency);
    }

    queryParams.append("is_excel", "true");

    const queryString = queryParams.toString();

    const apiUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/company` +
      (queryString ? `?${queryString}` : "");

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Result Perusahaan.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to download. Response Status:", response.status);
      }
    } catch (error) {
      console.error("Error downloading template:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // import
  const [openImport, setOpenImport] = useState(false);
  const handleOpenImport = () => setOpenImport(true);
  const handleCloseImport = () => setOpenImport(false);

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleExportData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/company/export/template/base`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Template Perusahaan.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error(
          "Gagal Mendownload Template. Respon Status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Mendownload Template, error:", error);
    }
    setIsLoading(false);
  };

  const handleExportMasterAreaData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area/export/template`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Master Area.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error(
          "Gagal Mendownload Template. Respon Status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Mendownload Template, error:", error);
    }
    setIsLoading(false);
  };

  const handleImportData = async () => {
    setIsLoading(true);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/company/import/template`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Perusahaan Error.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          toast.error("Error, ada masalah di excel");
        } else if (response.status === 201) {
          toast.success("Data berhasil ditambahkan");
          handleCloseImport();
          await fetchCompany(
            selectedProviceId,
            selectedRegencyId,
            selectedDistrictId,
            selectedSubDistrictId,
            selectedCompanyAgency
          );
        } else if (response.status === 400) {
          const data = await response.json();
          toast.error(data.detail);
        } else if (response.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error("Ada masalah");
        }
      } catch (error) {
        console.error(error);
        toast.error("Error");
      }
    }
    setIsLoading(false);
  };

  // handling modal
  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => {
    setDataRegencyForm([]);
    setDataDistrictForm([]);
    setDataSubDistrictForm([]);
    setOpenAdd(false);
  };

  // create data
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    company_agency: "",
    sub_district_id: "",
    address: "",
    master_pelaku_usaha_id: "",
  });

  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleInputProvinceChange = async (value) => {
    // reset
    setDataDistrictForm([]);
    setDataSubDistrictForm([]);
    setFormData((prevFormData) => ({
      ...prevFormData,
      regency_id: "",
      district_id: "",
      sub_district_id: "",
    }));
    await fetchRegency(accessToken, setDataRegencyForm, value, true);
  };

  const handleInputRegencyChange = async (value) => {
    // reset
    setDataSubDistrictForm([]);
    setFormData((prevFormData) => ({
      ...prevFormData,
      district_id: "",
      sub_district_id: "",
    }));
    await fetchDistrict(accessToken, setDataDistrictForm, value, true);
  };

  const handleInputDistrictChange = async (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      sub_district_id: "",
    }));
    await fetchSubDistrict(accessToken, setDataSubDistrictForm, value, true);
  };

  const handleCreateSubmit = async () => {
    setIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/company`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Data berhasil dibuat!");
        await fetchCompany(
          selectedProviceId,
          selectedRegencyId,
          selectedDistrictId,
          selectedSubDistrictId,
          selectedCompanyAgency
        );
        handleCloseAdd();
        setFormData({
          ...formData,
          id: "",
          name: "",
          company_agency: "",
          province_id: "",
          regency_id: "",
          district_id: "",
          sub_district_id: "",
          address: "",
          master_pelaku_usaha_id: "",
        });
      } else if (response.status === 422) {
        toast.error("Ada kesalahan saat mengisi form");
      } else if (response.status === 400 || response.status === 404) {
        const data = await response.json();
        toast.error(data.detail || data.data.message);
      } else {
        console.error("Failed to submit data");
        toast.error("Ada kesalahan saat mengisi form");
      }
    } catch (error) {
      console.error("Error during create data submission", error);
    }
    setIsLoading(false);
  };

  // delete table
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const openDeleteConfirmationModal = (itemId) => {
    setItemToDelete(itemId);
    setDeleteModalOpen(true);
  };

  const closeDeleteConfirmationModal = () => {
    setItemToDelete(null);
    setDeleteModalOpen(false);
  };

  const handleDeleteItem = async (itemId) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/company/${itemId}`;

    try {
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        toast.success("Data berhasil di hapus!");
        await fetchCompany(
          selectedProviceId,
          selectedRegencyId,
          selectedDistrictId,
          selectedSubDistrictId,
          selectedCompanyAgency
        );
      } else {
        toast.error("Data gagal dihapus!");
        console.error("Failed to delete item");
      }
    } catch (error) {
      console.error("Error during item deletion", error);
    }
  };

  // edit function
  const [editModalOpen, setEditModalOpen] = useState(false);

  const handleCloseEdit = () => {
    setEditModalOpen(false);
    setFormData({
      ...formData,
      id: "",
      name: "",
      company_agency: "",
      province_id: "",
      regency_id: "",
      district_id: "",
      sub_district_id: "",
      address: "",
      master_pelaku_usaha_id: "",
    });
  };

  const handleEditClick = async (rowData) => {
    const province_id = rowData.row.province.id;
    const regency_id = rowData.row.regency.id;
    const district_id = rowData.row.district.id;
    const sub_district_id = rowData.row.sub_district.id;
    const master_pelaku_usaha_id = rowData.row.master_pelaku_usaha.id;

    await fetchRegency(accessToken, setDataRegencyForm, province_id, true);
    await fetchDistrict(accessToken, setDataDistrictForm, regency_id, true);
    await fetchSubDistrict(
      accessToken,
      setDataSubDistrictForm,
      district_id,
      true
    );

    const dataMasterPelakuUsahaFiltered = dataMasterPelakuUsaha.filter(
      (map) => map.company_agency == rowData.row.company_agency
    );
    setDataMasterPelakuUsahaFiltered(dataMasterPelakuUsahaFiltered);

    setFormData({
      ...formData,
      id: rowData.row.id,
      name: rowData.row.name,
      company_agency: rowData.row.company_agency,
      province_id: province_id,
      regency_id: regency_id,
      district_id: district_id,
      sub_district_id: sub_district_id,
      address: rowData.row.address,
      master_pelaku_usaha_id: master_pelaku_usaha_id,
    });
    setEditModalOpen(true);
  };

  const handleUpdateData = async () => {
    setIsLoading(true);
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/company/${formData.id}`;

      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("data berhasil diubah");
        handleCloseEdit();
        await fetchCompany(
          selectedProviceId,
          selectedRegencyId,
          selectedDistrictId,
          selectedSubDistrictId,
          selectedCompanyAgency
        );
      } else {
        toast.error("data gagal diubah");
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setIsLoading(false);
  };

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "name",
      headerName: "NAMA",
      width: 300,
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "company_agency",
      headerName: "INSTANSI",
      width: 120,
      valueGetter: (params) => params.row.company_agency || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "master_pelaku_usaha",
      headerName: "PELAKU USAHA",
      width: 250,
      valueGetter: (params) => params.row.master_pelaku_usaha?.name || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "province.name",
      headerName: "PROVINSI",
      width: 180,
      valueGetter: (params) => params.row.province?.name || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "regency.name",
      headerName: "KOTA/KAB",
      width: 200,
      valueGetter: (params) => params.row.regency?.name || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "district.name",
      headerName: "KECAMATAN",
      width: 200,
      valueGetter: (params) => params.row.district?.name || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "sub_district.name",
      headerName: "KELURAHAN",
      width: 200,
      valueGetter: (params) => params.row.sub_district?.name || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "address",
      headerName: "ALAMAT",
      width: 230,
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "created_at",
      headerName: "DIBUAT PADA",
      width: 120,
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "updated_at",
      headerName: "DIPERBARUI PADA",
      width: 120,
    },
    {
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
      field: "actions",
      type: "actions",
      headerName: "ACTION",
      flex: 1,
      minWidth: 200,
      cellClassName: "actions",
      getActions: (rowData) => {
        let actionList = [
          <Tooltip title="Edit" key={rowData.id}>
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              color="inherit"
              onClick={() => handleEditClick(rowData)}
            />
          </Tooltip>,
          <Tooltip title="Delete" key={rowData.id}>
            <GridActionsCellItem
              icon={<DeleteIcon />}
              key={rowData.row.id}
              label="Delete"
              color="inherit"
              onClick={() => openDeleteConfirmationModal(rowData.id)}
            />
          </Tooltip>,
        ];
        return actionList;
      },
    },
  ];

  const sortedDataCompany = [...dataCompany].sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Sidebar />
        <Box
          component="main"
          sx={{
            ml: "-4%",
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <div style={{ padding: "25px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "20px",
                    }}
                  >
                    <SearchBar
                      id={"province-id-autocomplete"}
                      data={dataProvince}
                      value={
                        dataProvince.find(
                          (option) => option.id === selectedProviceId
                        ) ?? null
                      }
                      handleChange={handleProvinceChange}
                      label={"Provinsi"}
                    />
                    <SearchBar
                      id={"regency-id-autocomplete"}
                      data={dataRegency}
                      value={
                        dataRegency.find(
                          (option) => option.id === selectedRegencyId
                        ) ?? null
                      }
                      handleChange={handleRegencyChange}
                      label={"Kota/Kabupaten"}
                    />
                    {/* <SearchBar
                      id={"district-id-autocomplete"}
                      data={dataDistrict}
                      value={
                        dataDistrict.find(
                          (option) => option.id === selectedDistrictId
                        ) ?? null
                      }
                      handleChange={handleDistrictChange}
                      label={"Kecamatan"}
                    />
                    <SearchBar
                      id={"sub-district-id-autocomplete"}
                      data={dataSubDistrict}
                      value={
                        dataSubDistrict.find(
                          (option) => option.id === selectedSubDistrictId
                        ) ?? null
                      }
                      handleChange={handleSubDistrictChange}
                      label={"Kelurahan"}
                    /> */}
                    <SearchBar
                      id={"company-agency-autocomplete"}
                      data={dataCompanyAgency}
                      value={
                        dataCompanyAgency.find(
                          (option) => option.id === selectedCompanyAgency
                        ) ?? null
                      }
                      handleChange={handleCompanyAgencyChange}
                      label={"Instansi"}
                    />
                    <Autocomplete
                      id="outlined-select-master-pelaku-usaha-id"
                      options={
                        dataPelakuUsaha
                          ? dataPelakuUsaha.sort((a, b) =>
                              a.label.localeCompare(b.label)
                            )
                          : []
                      }
                      getOptionLabel={(option) => option.label || ""}
                      renderOption={(props, option) => (
                        <li {...props}>{option.label}</li>
                      )}
                      value={
                        dataPelakuUsaha.find(
                          (item) => item.id === selectedPelakuUsahaId
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setSelectedPelakuUsahaId(newValue ? newValue.id : "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Pelaku Usaha"
                          variant="outlined"
                          size="small"
                          sx={{
                            width: 200,
                            height: 40,
                          }}
                        />
                      )}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "20px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        color: "#32AE2F",
                        borderColor: "#32AE2F",
                      }}
                      onClick={handleOpenAdd}
                    >
                      Create
                    </Button>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        color: "#32AE2F",
                        borderColor: "#32AE2F",
                        mr: 1,
                      }}
                      onClick={handleOpenImport}
                    >
                      Import
                    </Button>
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        color: "#32AE2F",
                        borderColor: "#32AE2F",
                      }}
                      disabled={isLoading}
                      onClick={handleDownloadData}
                    >
                      {isLoading ? "Loading..." : "Download"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {/* delete modal */}
              <DeleteDataModal
                modal={{
                  open: deleteModalOpen,
                }}
                handle={{
                  handleClose: closeDeleteConfirmationModal,
                  handleDeleteItem: handleDeleteItem,
                }}
                isLoading={isLoading}
                itemDeleted={itemToDelete}
              />
              {/* create modal */}
              <AddDataModal
                modal={{
                  open: openAdd,
                }}
                handle={{
                  handleClose: handleCloseAdd,
                  handleInputChange: handleInputChange,
                  handleInputProvinceChange: handleInputProvinceChange,
                  handleInputRegencyChange: handleInputRegencyChange,
                  handleInputDistrictChange: handleInputDistrictChange,
                  handleCompanyAgencyChange: handleCompanyAgencyInputChange,
                  handleCreateSubmit: handleCreateSubmit,
                }}
                isLoading={isLoading}
                formData={formData}
                dataOption={{
                  dataCompanyAgency: dataCompanyAgency,
                  dataProvince: dataProvince,
                  dataRegency: dataRegencyForm,
                  dataDistrict: dataDistrictForm,
                  dataSubDistrict: dataSubDistrictForm,
                  dataMasterPelakuUsaha: dataMasterPelakuUsahaFiltered,
                }}
              />
              {/* import modal */}
              <ImportDataModal
                modal={{
                  open: openImport,
                }}
                handle={{
                  handleClose: handleCloseImport,
                  handleFileChange: handleFileChange,
                  handleExportData: handleExportData,
                  handleExportMasterAreaData: handleExportMasterAreaData,
                  handleImportData: handleImportData,
                }}
                isLoading={isLoading}
                itemDeleted={itemToDelete}
              />
              {/* edit modal */}
              <EditDataModal
                modal={{
                  open: editModalOpen,
                }}
                handle={{
                  handleClose: handleCloseEdit,
                  handleInputChange: handleInputChange,
                  handleInputProvinceChange: handleInputProvinceChange,
                  handleInputRegencyChange: handleInputRegencyChange,
                  handleInputDistrictChange: handleInputDistrictChange,
                  handleCompanyAgencyChange: handleCompanyAgencyInputChange,
                  handleUpdate: handleUpdateData,
                }}
                isLoading={isLoading}
                formData={formData}
                dataOption={{
                  dataCompanyAgency: dataCompanyAgency,
                  dataProvince: dataProvince,
                  dataRegency: dataRegencyForm,
                  dataDistrict: dataDistrictForm,
                  dataSubDistrict: dataSubDistrictForm,
                  dataMasterPelakuUsaha: dataMasterPelakuUsahaFiltered,
                }}
              />
            </div>
            {dataCompany.length > 0 && (
              <DataGrid
                sx={{
                  mt: 2,
                  "& .super-app-theme--header": {
                    backgroundColor: "#32AE2F",
                    color: "#000000",
                    border: 2,
                    fontWeight: "bold",
                    borderColor: "#FFF",
                  },
                  ...alternatingRowStyles,
                }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "period", sort: "asc" }],
                  },
                }}
                className="alternating-row-grid"
                getRowId={(row) => row.id}
                rows={sortedDataCompany}
                columns={columns}
                editMode="row"
                getRowHeight={() => "auto"}
              />
            )}
          </div>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Company;
