import axios from 'axios';

export const getCompany = async (
  access_token, 
  setData, 
  company_agency = null, 
  onlyForInput = false, 
  master_pelaku_usaha_id = null,
  province_id = null,
  regency_id = null,
  district_id = null,
  sub_district_id = null,
  selectedPelakuUsahaId
) => {
  const queryParams = new URLSearchParams();
  
  if (company_agency) {
    queryParams.append('company_agency', company_agency);
  }
  
  if (master_pelaku_usaha_id) {
    queryParams.append('master_pelaku_usaha_id', master_pelaku_usaha_id);
  }
  
  if (province_id) {
    queryParams.append('province_id', province_id);
  }
  
  if (regency_id) {
    queryParams.append('regency_id', regency_id);
  }
  
  if (district_id) {
    queryParams.append('district_id', district_id);
  }
  
  if (sub_district_id) {
    queryParams.append('sub_district_id', sub_district_id);
  }

  if (selectedPelakuUsahaId && selectedPelakuUsahaId !== "") {
    queryParams.append("master_pelaku_usaha_id", selectedPelakuUsahaId);
  }

  const queryString = queryParams.toString();
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/company` + (queryString ? `?${queryString}` : '');
  
  try{
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
        
    let result_data = response?.data?.data;

    if(onlyForInput){
      result_data = result_data.map(item => ({
        label: item.name,
        id: item.id,
      }));
    }

    setData(result_data)
  }catch(e){
    setData([])
  }
};
