import { React, useState } from "react";
import { Modal, Box, TextField, IconButton, MenuItem, Autocomplete } from "@mui/material";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";
import CloseIcon from "@mui/icons-material/Close";

function EditDataModal({
  modal,
  isLoading,
  handle,
  formData,
  dataOption,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={modal.open}
      onClose={handle.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        border: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          ...style,
          width: 500,
          height: 640,
          border: "0px solid #000",
          boxShadow: 24,
          borderRadius: 3,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            overflowY: "auto",
            borderRadius: "0 0 3px 3px",
            flex: 1,
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "rgba(0, 0, 0, 0.54)",
            }}
            onClick={handle.handleClose}
          >
            <CloseIcon />
          </IconButton>
          <div className="label">
            <p
              className="text-wrapper"
              style={{
                bgcolor: "#000000",
                fontFamily: "Roboto",
                fontWeight: 600,
                fontSize: 18,
                textAlign: "center",
              }}
            >
              Edit Data
            </p>
          </div>
          <TextField
            id="outlined-select-nama-perusahaan"
            label="Nama Perusahaan"
            required={true}
            sx={{ width: "94%", mt: 2, ml: "3%" }}
            size="small"
            value={formData.name}
            onChange={(e) =>
              handle.handleInputChange("name", e.target.value)
            }
          ></TextField>
          <TextField
            id="outlined-select-company-agency"
            label="Instansi"
            required={true}
            sx={{ width: "94%", mt: 2, ml: "3%" }}
            size="small"
            select
            value={formData.company_agency}
            onChange={(e) => {
              handle.handleInputChange("company_agency", e.target.value);
              handle.handleCompanyAgencyChange(e.target.value);
              handle.handleInputChange("master_pelaku_usaha_id", '');
            }}
          >
            {dataOption?.dataCompanyAgency?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="outlined-select-master_pelaku_usaha"
            label="Pelaku Usaha"
            required={true}
            sx={{ width: "94%", mt: 2, ml: "3%" }}
            size="small"
            select
            value={formData.master_pelaku_usaha_id}
            onChange={(e) => handle.handleInputChange("master_pelaku_usaha_id", e.target.value)}
          >
            {dataOption?.dataMasterPelakuUsaha?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
<Box sx={{ width: "94%", mt: 2, ml: "3%" }}>
  <Autocomplete
    id="outlined-select-province-id"
    options={dataOption?.dataProvince || []}
    getOptionLabel={(option) => option.label || ""}
    renderOption={(props, option) => (
      <li {...props}>
        {option.label}
      </li>
    )}
    value={dataOption?.dataProvince?.find(item => item.id === formData.province_id) || null}
    onChange={(event, newValue) => {
      handle.handleInputProvinceChange(newValue ? newValue.id : '');
      handle.handleInputChange('province_id', newValue ? newValue.id : '');
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Provinsi"
        required
        variant="outlined"
        size="small"
      />
    )}
  />
</Box>
<Box sx={{ width: "94%", mt: 2, ml: "3%" }}>
  <Autocomplete
    id="outlined-select-regency-id"
    options={dataOption?.dataRegency || []}
    getOptionLabel={(option) => option.label || ""}
    renderOption={(props, option) => (
      <li {...props}>
        {option.label}
      </li>
    )}
    value={dataOption?.dataRegency?.find(item => item.id === formData.regency_id) || null}
    onChange={(event, newValue) => {
      handle.handleInputRegencyChange(newValue ? newValue.id : '');
      handle.handleInputChange('regency_id', newValue ? newValue.id : '');
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Kota/Kabupaten"
        required
        variant="outlined"
        size="small"
      />
    )}
  />
</Box>
<Box sx={{ width: "94%", mt: 2, ml: "3%" }}>
  <Autocomplete
    id="outlined-select-district-id"
    options={dataOption?.dataDistrict || []}
    getOptionLabel={(option) => option.label || ""}
    renderOption={(props, option) => (
      <li {...props}>
        {option.label}
      </li>
    )}
    value={dataOption?.dataDistrict?.find(item => item.id === formData.district_id) || null}
    onChange={(event, newValue) => {
      handle.handleInputDistrictChange(newValue ? newValue.id : '');
      handle.handleInputChange('district_id', newValue ? newValue.id : '');
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Kecamatan"
        required
        variant="outlined"
        size="small"
      />
    )}
  />
</Box>
<Box sx={{ width: "94%", mt: 2, ml: "3%" }}>
  <Autocomplete
    id="outlined-select-sub-district-id"
    options={dataOption?.dataSubDistrict || []}
    getOptionLabel={(option) => option.label || ""}
    renderOption={(props, option) => (
      <li {...props}>
        {option.label}
      </li>
    )}
    value={dataOption?.dataSubDistrict?.find(item => item.id === formData.sub_district_id) || null}
    onChange={(event, newValue) => {
      handle.handleInputChange('sub_district_id', newValue ? newValue.id : '');
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Kelurahan"
        required
        variant="outlined"
        size="small"
      />
    )}
  />
</Box>
          <TextField
            id="outlined-select-address"
            label="Alamat"
            sx={{ width: "94%", mt: 2, ml: "3%" }}
            size="small"
            value={formData.address}
            onChange={(e) =>
              handle.handleInputChange("address", e.target.value)
            }
          ></TextField>
        </div>
        <BootstrapButton
          variant="contained"
          disableRipple
          disabled={isLoading}
          onClick={handle.handleUpdate}
          sx={{
            width: "94%",
            ml: "2%",
            mt: 4,
            borderRadius: 2.5,
            backgroundColor: "#32AE2F",
            "&:hover": {
              backgroundColor: "#1d691b",
              boxShadow: "none",
            },
          }}
        >
          {isLoading ? "Loading..." : "SUBMIT"}
        </BootstrapButton>
      </Box>
    </Modal>
  );
}

export default EditDataModal;
