import { React, useEffect, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { toast } from "react-hot-toast";
import {DataGrid, GridActionsCellItem,} from "@mui/x-data-grid";
// icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

// external
import Sidebar from "../../Components/Sidebar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getAccessToken, profilePromise } from '../../Services/AuthService';
import { getMasterSubKomoditasPromise, getMasterKomoditasPromise } from "../../Services/KomoditasService";
import AddDataModal from "./AddDataModal";
import EditDataModal from "./EditDataModal";
import DeleteDataModal from "./DeleteDataModal";
import SearchBar from "../../Components/SearchBar";
import ImportDataModal from "./ImportDataModal";

const alternatingRowStyles = {
  "& .MuiDataGrid-row:nth-of-type(odd)": {
    backgroundColor: "#88CC81",
  },
  "& .MuiDataGrid-row:nth-of-type(even)": {
    backgroundColor: "#B1E1AD",
  },
};

const defaultTheme = createTheme();

function SubKomoditas() {
  // token
  const accessToken = getAccessToken();
  const [isLoading, setIsLoading] = useState(false);
  const [dataProfile, setDataProfile] = useState(null);
  const [dataSubKomoditas, setDataSubKomoditas] = useState([]);
  const [dataMasterKomoditasOption, setDataMasterKomoditasOption] = useState([]);

  const [selectedMasterKomoditasId, setSelectedMasterKomoditasId] = useState("");

  const fetchProfile = async (accessToken) => {
    try {
      const profile = await profilePromise(accessToken);
      setDataProfile(profile);
    } catch (error) {
      console.error("Failed to fetch profile")
    }
  };

  const fetchSubKomoditas = async (selectedMasterKomoditasId) => {
    try {
      await getMasterSubKomoditasPromise(accessToken, setDataSubKomoditas, selectedMasterKomoditasId);
    } catch (error) {
      console.error("Failed to fetch master komoditas")
    }
  };

  const fetchMasterKomoditas = async () => {
    try {
      await getMasterKomoditasPromise(accessToken, setDataMasterKomoditasOption, true);
    } catch (error) {
      console.error("Failed to fetch master komoditas")
    }
  };

  useEffect(() => {
    fetchProfile(accessToken);
    fetchMasterKomoditas();
  }, [accessToken])

  useEffect(() => {
    fetchSubKomoditas(selectedMasterKomoditasId);
  }, [accessToken, selectedMasterKomoditasId])

  // filter
  const handleMasterKomoditasChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedMasterKomoditasId(id);
  };

  // download
  const handleDownloadData = async () => {
    setIsLoading(true);
    const queryParams = new URLSearchParams();

    if (selectedMasterKomoditasId && selectedMasterKomoditasId !== "") {
      queryParams.append("master_komoditas_id", selectedMasterKomoditasId);
    }

    queryParams.append("is_excel", "true");

    const queryString = queryParams.toString();
    const labelSuffix = selectedMasterKomoditasId ? ` - ${dataMasterKomoditasOption.find(option => option.id === selectedMasterKomoditasId)?.label}` : "";
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/sub-komoditas` +
      (queryString ? `?${queryString}` : "");

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Result Sub Komoditas${labelSuffix}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to download. Response Status:", response.status);
      }
    } catch (error) {
      console.error("Error downloading:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // import
  const [openImport, setOpenImport] = useState(false);
  const handleOpenImport = () => setOpenImport(true);
  const handleCloseImport = () => setOpenImport(false);

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleExportData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/sub-komoditas/export/template/base`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Template Sub Komoditas.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error(
          "Gagal Mendownload Template. Respon Status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Mendownload Template, error:", error);
    }
    setIsLoading(false);
  };

  const handleImportData = async () => {
    setIsLoading(true);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/sub-komoditas/import/template`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "User Error.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          toast.error("Error, ada masalah di excel");
        } else if (response.status === 201) {
          toast.success("Data berhasil ditambahkan");
          handleCloseImport();
          await fetchSubKomoditas(selectedMasterKomoditasId);
        } else if (response.status === 400) {
          const data = await response.json();
          toast.error(data.detail);
        } else if (response.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error("Ada masalah");
        }
      } catch (error) {
        console.error(error);
        toast.error("Error");
      }
    }
    setIsLoading(false);
  };

  // handling modal
  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  // create data
  const [formData, setFormData] = useState({
    id: "",
    master_komoditas_id: "",
    nama: "",
    image: "",
  });

  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleImageChange = (file) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: file,
    }));
  };

  const handleCreateSubmit = async () => {
    setIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/sub-komoditas`;

    try {
      const formDataObj = new FormData();
      formDataObj.append("master_komoditas_id", formData.master_komoditas_id);
      formDataObj.append("nama", formData.nama);
      if (formData.image) {
        formDataObj.append("image", formData.image);
      }

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formDataObj,
      });

      if (response.ok) {
        toast.success("Data berhasil dibuat!");
        await fetchSubKomoditas(selectedMasterKomoditasId);
        handleCloseAdd();
        setFormData({
          ...formData,
          id: '',
          nama: '',
          master_komoditas_id: '',
          image: '',
        });
      } else if (response.status === 422) {
        toast.error("Ada kesalahan saat mengisi form");
      } else if (response.status === 400 || response.status === 404) {
        const data = await response.json();
        toast.error(data.detail || data.data.message)
      } else {
        console.error("Failed to submit data");
        toast.error("Ada kesalahan saat mengisi form");
      }
    } catch (error) {
      console.error("Error during create data submission", error);
    }
    setIsLoading(false);
  };

  // delete table
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const openDeleteConfirmationModal = (itemId) => {
    setItemToDelete(itemId);
    setDeleteModalOpen(true);
  };

  const closeDeleteConfirmationModal = () => {
    setItemToDelete(null);
    setDeleteModalOpen(false);
  };

  const handleDeleteItem = async (itemId) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/sub-komoditas/${itemId}`;

    try {
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        toast.success("Data berhasil di hapus!");
        await fetchSubKomoditas(selectedMasterKomoditasId);
      } else {
        toast.error("Data gagal dihapus!");
        console.error("Failed to delete item");
      }
    } catch (error) {
      console.error("Error during item deletion", error);
    }
  };

  // edit function
  const [editModalOpen, setEditModalOpen] = useState(false);

  const handleCloseEdit = () => {
    setEditModalOpen(false);
    setFormData({
      ...formData,
      id: '',
      nama: '',
      master_komoditas_id: '',
    })
  };

  const handleEditClick = (rowData) => {
    setFormData({
      ...formData,
      id: rowData.row.id,
      nama: rowData.row.nama,
      master_komoditas_id: rowData.row.master_komoditas.id,
    })
    setEditModalOpen(true);
  };

  const handleUpdateData = async () => {
    setIsLoading(true);

    const formDataObj = new FormData();
    formDataObj.append("master_komoditas_id", formData.master_komoditas_id);
    formDataObj.append("nama", formData.nama);
    if (formData.image) {
      formDataObj.append("image", formData.image);
    }

    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/sub-komoditas/${formData.id}`;

      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formDataObj,
      });

      if (response.status === 200) {
        toast.success("data berhasil diubah");
        handleCloseEdit();
        await fetchSubKomoditas(selectedMasterKomoditasId);
      } else {
        toast.error("data gagal diubah");
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setIsLoading(false);
  };

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "master_komoditas.nama",
      headerName: "KOMODITAS",
      width: 300,
      valueGetter: (params) => params.row.master_komoditas?.nama || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "nama",
      headerName: "SUB KOMODITAS",
      width: 300,
    },
    {
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
      field: "image",
      headerName: "IMAGE",
      width: 300,
      cellClassName: "IMAGE",
      renderCell: (params) => {
        const { thumbnail_url, nama } = params.row;

        if (!thumbnail_url) {
          return null; // Return nothing if thumbnail_url is not found
        }

        return (
          <div style={{ width: 800, height: 800, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={thumbnail_url} style={{ maxWidth: '100%', maxHeight: '100%' }} alt={nama} />
          </div>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
      field: "actions",
      type: "actions",
      headerName: "ACTION",
      flex: 1,
      minWidth: 200,
      cellClassName: "actions",
      getActions: (rowData) => {
        let actionList = [];
        if (dataProfile?.role === "admin") {
          actionList = [
            <Tooltip title="Edit" key={rowData.id}>
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                color="inherit"
                onClick={() => handleEditClick(rowData)}
              />
            </Tooltip>,
            // <Tooltip title="Delete" key={rowData.id}>
            //   <GridActionsCellItem
            //     icon={<DeleteIcon />}
            //     key={rowData.row.id}
            //     label="Delete"
            //     color="inherit"
            //     onClick={() => openDeleteConfirmationModal(rowData.id)}
            //   />
            // </Tooltip>,
          ];
        }
        return actionList;
      },
    },
  ];

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Sidebar />
        <Box
          component="main"
          sx={{
            ml: "-4%",
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <div style={{ padding: "25px" }}>
            <div style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
              justifyContent: 'space-between'
            }}>
              <SearchBar
                id={"master-komoditas-autocomplete"}
                data={dataMasterKomoditasOption}
                value={
                  dataMasterKomoditasOption.find(
                    (option) => option.id === selectedMasterKomoditasId
                  ) ?? null
                }
                handleChange={handleMasterKomoditasChange}
                label={"Komoditas"}
              />
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '20px'
              }}>
                {
                  dataProfile?.role === 'admin' && <Button
                    variant="outlined"
                    disableRipple
                    sx={{
                      color: "#32AE2F",
                      borderColor: "#32AE2F",
                    }}
                    onClick={handleOpenAdd}
                  >
                    Create
                  </Button>
                }
                {
                  dataProfile?.role === 'admin' && <Button
                    variant="outlined"
                    disableRipple
                    sx={{
                      color: "#32AE2F",
                      borderColor: "#32AE2F",
                    }}
                    onClick={handleOpenImport}
                  >
                    Import
                  </Button>
                }
                <Button
                  variant="outlined"
                  disableRipple
                  sx={{
                    color: "#32AE2F",
                    borderColor: "#32AE2F",
                  }}
                  disabled={isLoading}
                  onClick={handleDownloadData}
                >
                  {isLoading ? "Loading..." : "Download"}
                </Button>
              </div>
            </div>
            <div>
              {/* delete modal */}
              <DeleteDataModal
                modal={{
                  open: deleteModalOpen
                }}
                handle={{
                  handleClose: closeDeleteConfirmationModal,
                  handleDeleteItem: handleDeleteItem,
                }}
                isLoading={isLoading}
                itemDeleted={itemToDelete}
              />
              {/* create modal */}
              <AddDataModal
                modal={{
                  open: openAdd
                }}
                handle={{
                  handleClose: handleCloseAdd,
                  handleInputChange: handleInputChange,
                  handleImageChange: handleImageChange,
                  handleCreateSubmit: handleCreateSubmit,
                }}
                isLoading={isLoading}
                formData={formData}
                dataOption={{
                  dataMasterKomoditasOption: dataMasterKomoditasOption
                }}
              />
              {/* import modal */}
              <ImportDataModal
                modal={{
                  open: openImport
                }}
                handle={{
                  handleClose: handleCloseImport,
                  handleFileChange: handleFileChange,
                  handleExportData: handleExportData,
                  handleImportData: handleImportData,
                }}
                isLoading={isLoading}
                itemDeleted={itemToDelete}
              />
              {/* edit modal */}
              <EditDataModal
                modal={{
                  open: editModalOpen
                }}
                handle={{
                  handleClose: handleCloseEdit,
                  handleInputChange: handleInputChange,
                  handleImageChange: handleImageChange,
                  handleUpdate: handleUpdateData,
                }}
                isLoading={isLoading}
                formData={formData}
                dataOption={{
                  dataMasterKomoditasOption: dataMasterKomoditasOption
                }}
              />
            </div>
            {
              dataSubKomoditas.length > 0 && <DataGrid
                sx={{
                  mt: 2,
                  "& .super-app-theme--header": {
                    backgroundColor: "#32AE2F",
                    color: "#000000",
                    border: 2,
                    fontWeight: "bold",
                    borderColor: "#FFF",
                  },
                  ...alternatingRowStyles,
                }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "period", sort: "asc" }],
                  },
                }}
                className="alternating-row-grid"
                getRowId={(row) => row.id}
                rows={dataSubKomoditas}
                columns={columns}
                editMode="row"
              />
            }
          </div>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default SubKomoditas;
