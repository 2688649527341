import { React } from "react";
import { MenuItem, FormControl, InputLabel, Select, Box } from "@mui/material";
import { format } from "date-fns";
import { id } from 'date-fns/locale';

import { generatePreviousFridayDates } from "../../Services/TanggalService";
import PropTypes from "prop-types";

function DashboardFilter({
  selectedDate,
  setSelectedDate,
  dataDashboardDate,

  selectedMasterGudang,
  handleChangeMasterGudangFilter,
  dataMasterGudang,

  selectedCompany,
  handleChangeCompanyFilter,
  dataCompany,

  selectedMasterPelakuUsaha,
  handleChangeMasterPelakuUsahaFilter,
  dataMasterPelakuUsaha,

  selectedProvince,
  handleProvinceChange,
  provinces,

  selectedMasterKomoditas,
  setSelectedMasterKomoditas,
  dataDashboard,

  role,
}) {
  const previousFridayDates = generatePreviousFridayDates();

  return (
    <div
      style={{
        // width: "100%",
        display: "flex",
        // justifyContent: "space-around",
        gap: "5px",
        flexWrap: "wrap",
      }}
    >
      <Box sx={{ minWidth: 180 }}>
        <FormControl fullWidth>
          <InputLabel id="label-select-date">Tanggal</InputLabel>
          <Select
            labelId="label-select-date"
            id="select-date"
            value={
              selectedDate ||
              (dataDashboardDate[0] && dataDashboardDate[0].tanggal) ||
              ""
            }
            label="Tanggal"
            name="date"
            onChange={(e) => setSelectedDate(e.target.value)}
          >
            {dataDashboardDate?.map((item) => (
              <MenuItem key={item.tanggal} value={item.tanggal}>
                {format(new Date(item.tanggal), "eeee, dd MMM yyyy", { locale: id })}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {role === "admin" && (
        <Box sx={{ minWidth: 180 }}>
          <FormControl fullWidth>
            <InputLabel id="label-select-province">Provinsi</InputLabel>
            <Select
              labelId="label-select-province"
              id="select-province"
              value={selectedProvince}
              defaultValue={""}
              label="Provinsi"
              name="province"
              onChange={handleProvinceChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {provinces?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      <Box sx={{ minWidth: 180 }}>
        <FormControl fullWidth>
          <InputLabel id="label-select-pelaku-usaha">Pelaku Usaha</InputLabel>
          <Select
            labelId="label-select-gudang"
            id="select-pelaku-usaha"
            value={selectedMasterPelakuUsaha}
            defaultValue={""}
            label="Pelaku Usaha"
            name="pelaku_usaha"
            onChange={handleChangeMasterPelakuUsahaFilter}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {dataMasterPelakuUsaha?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ minWidth: 180 }}>
        <FormControl fullWidth>
          <InputLabel id="label-select-perusahaan">Perusahaan</InputLabel>
          <Select
            labelId="label-select-perusahaan"
            id="select-perusahaan"
            value={selectedCompany}
            defaultValue={""}
            label="Perusahaan"
            name="perusahaan"
            onChange={handleChangeCompanyFilter}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {dataCompany?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ minWidth: 180 }}>
        <FormControl fullWidth>
          <InputLabel id="label-select-gudang">Gudang</InputLabel>
          <Select
            labelId="label-select-gudang"
            id="select-gudang"
            value={selectedMasterGudang}
            defaultValue={""}
            label="Gudang"
            name="gudang"
            onChange={handleChangeMasterGudangFilter}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {dataMasterGudang?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nama_gudang}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ minWidth: 180 }}>
        <FormControl fullWidth>
          <InputLabel id="label-select-komoditas">Komoditas</InputLabel>
          <Select
            labelId="label-select-komoditas"
            id="select-komoditas"
            value={selectedMasterKomoditas}
            defaultValue={""}
            label="komoditas"
            name="komoditas"
            onChange={(e) => setSelectedMasterKomoditas(e.target.value)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {dataDashboard?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nama}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
}

DashboardFilter.propTypes = {
  selectedMasterPelakuUsaha: PropTypes.any.isRequired,
  role: PropTypes.string,
  selectedDate: PropTypes.string.isRequired,
  selectedMasterGudang: PropTypes.string.isRequired,
  selectedProvince: PropTypes.string.isRequired,
  selectedCompany: PropTypes.string.isRequired,
  dataCompany: PropTypes.array.isRequired,
  dataMasterGudang: PropTypes.array.isRequired,
  dataMasterPelakuUsaha: PropTypes.array.isRequired,
  provinces: PropTypes.array.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  handleChangeCompanyFilter: PropTypes.func.isRequired,
  handleChangeMasterGudangFilter: PropTypes.func.isRequired,
  handleProvinceChange: PropTypes.func.isRequired,
  handleChangeMasterPelakuUsahaFilter: PropTypes.func.isRequired,
};

export default DashboardFilter;
