import axios from 'axios';

export const getAllMasterPelakuUsaha = async (
  access_token,
  company_agency = null,
  is_dashboard = null,
) => {
  // Create an empty URLSearchParams object
  const queryParams = new URLSearchParams();
  
  if (company_agency) {
    queryParams.append('company_agency', company_agency);
  }

  if (is_dashboard) {
    queryParams.append('is_dashboard', is_dashboard);
  }

  const queryString = queryParams.toString();
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/master_pelaku_usaha` + (queryString ? `?${queryString}` : '');
  
  try{
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    
    return response?.data?.data;
  }catch(e){
    return []
  }
};

export const getAllMasterPelakuUsahaCustom = async (access_token, customParam) => {
  try {
    // Construct the query string from customParam
    const queryString = new URLSearchParams(customParam).toString();

    // Append the query string to the API URL
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/master_pelaku_usaha?${queryString}`;

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + access_token,
        'Content-Type': 'application/json'
      },
    });

    if (!response.ok) {
      return {
        data: []
      };
    }

    const datas = await response.json();
    return datas;
  } catch (error) {
    return {
      data: []
    };
  }
};

