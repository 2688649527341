import { React } from "react";
import { MenuItem, FormControl, InputLabel, Select, Box } from "@mui/material";
import { format } from 'date-fns';
import { id } from 'date-fns/locale';

import { generatePreviousFridayDates } from "../../../Services/TanggalService";
import PropTypes from "prop-types";

function DashboardMasterKomoditasFilter({
  selectedDate,
  setSelectedDate,
  dataDashboardDate,

  masterKomoditasId,
  handleChangeMasterKomoditasFilter,
  dataMasterKomoditasInside,

  firstMonth,
  setFirstMonth,
  secondMonth,
  setSecondMonth,

  handleYearChange,
  selectedYear,
  years,

  selectedMasterGudang,
  handleChangeMasterGudangFilter,
  dataMasterGudang,

  selectedTipeGudang,
  handleChangeTipeGudangFilter,

  selectedMasterPelakuUsaha,
  handleChangeMasterPelakuUsahaFilter,
  dataMasterPelakuUsaha,

  selectedCompany,
  handleChangeCompanyFilter,
  dataCompany,

  selectedProvince,
  setSelectedProvince,
  provinces,

  selectedRegency,
  setSelectedRegency,
  regencies,

  role,
}) {
  const previousFridayDates = generatePreviousFridayDates(
    firstMonth,
    secondMonth,
    selectedYear
  );

  const handleProvinceChange = (event) => {
    const selectedProvinceValue = event.target.value;

    setSelectedProvince(selectedProvinceValue || "");
    setSelectedRegency("");
  };

  return (
    <div
      style={{
        display: "flex",
        // justifyContent: "space-around",
        gap: "5px",
        flexWrap: "wrap",
      }}
    >
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="label-select-first-month">Bulan Awal</InputLabel>
          <Select
            labelId="label-select-first-month"
            id="select-first-month"
            value={firstMonth}
            defaultValue={firstMonth}
            label="Bulan Awal"
            name="first_month"
            onChange={(e) => {
              setFirstMonth(e.target.value);
            }}
          >
            {Array.from({ length: 12 }, (_, index) => index + 1).map(
              (month) => (
                <MenuItem key={month} value={month}>
                  {new Date(
                    new Date().getFullYear(),
                    month - 1,
                    1
                  ).toLocaleString("id-ID", { month: "long" })}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="label-select-last-month">Bulan Akhir</InputLabel>
          <Select
            labelId="label-select-last-month"
            id="select-last-month"
            value={secondMonth}
            defaultValue={secondMonth}
            label="Bulan Akhir"
            name="last_month"
            onChange={(e) => {
              setSecondMonth(e.target.value);
            }}
          >
            {Array.from({ length: 12 }, (_, index) => index + 1).map(
              (month) => (
                <MenuItem key={month} value={month}>
                  {new Date(
                    new Date().getFullYear(),
                    month - 1,
                    1
                  ).toLocaleString("id-ID", { month: "long" })}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Box>

      {years?.length > 0 && (
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="label-select-year">Tahun</InputLabel>
            <Select
              labelId="label-select-year"
              id="select-year"
              value={selectedYear}
              defaultValue={selectedYear}
              label="Tahun"
              name="year"
              onChange={handleYearChange}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="label-select-date">Tanggal</InputLabel>
          <Select
            labelId="label-select-date"
            id="select-date"
            value={              selectedDate ||
              (dataDashboardDate[0] && dataDashboardDate[0].tanggal) ||
              ""}
            defaultValue={""}
            label="Tanggal"
            name="date"
            onChange={(e) => setSelectedDate(e.target.value)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {dataDashboardDate?.map((item) => (
              <MenuItem key={item.tanggal} value={item.tanggal}>
                {format(new Date(item.tanggal), "eeee, dd MMM yyyy", { locale: id })}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {role === "admin" && (
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="label-select-province">Provinsi</InputLabel>
            <Select
              labelId="label-select-province"
              id="select-province"
              value={selectedProvince}
              defaultValue={""}
              label="Provinsi"
              name="province"
              onChange={handleProvinceChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {provinces?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {/* {
        role === 'admin' && <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="label-select-regency">Kota/Kabupaten</InputLabel>
            <Select
              labelId="label-select-regency"
              id="select-regency"
              value={selectedRegency}
              defaultValue={''}
              label="Kota/Kabupaten"
              name="regency"
              onChange={(event) => setSelectedRegency(event.target.value)}
            >
              <MenuItem value=""><em>None</em></MenuItem>
              {regencies?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      } */}

      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="label-select-pelaku-usaha">Pelaku Usaha</InputLabel>
          <Select
            labelId="label-select-gudang"
            id="select-pelaku-usaha"
            value={selectedMasterPelakuUsaha}
            defaultValue={""}
            label="Pelaku Usaha"
            name="pelaku_usaha"
            onChange={handleChangeMasterPelakuUsahaFilter}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {dataMasterPelakuUsaha?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="label-select-perusahaan">Perusahaan</InputLabel>
          <Select
            labelId="label-select-perusahaan"
            id="select-perusahaan"
            value={selectedCompany}
            defaultValue={""}
            label="Perusahaan"
            name="perusahaan"
            onChange={handleChangeCompanyFilter}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {dataCompany?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="label-select-tipe-gudang">Tipe Gudang</InputLabel>
          <Select
            labelId="label-select-tipe-gudang"
            id="select-tipe-gudang"
            value={selectedTipeGudang}
            defaultValue={''}
            label="Tipe Gudang"
            name="tipe_gudang"
            onChange={handleChangeTipeGudangFilter}
          >
            <MenuItem value=""><em>None</em></MenuItem>
            {dataTipeGudang.map((item) => (
              <MenuItem key={item.id} value={item.label}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box> */}

      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="label-select-gudang">Gudang</InputLabel>
          <Select
            labelId="label-select-gudang"
            id="select-gudang"
            value={selectedMasterGudang}
            defaultValue={""}
            label="Gudang"
            name="gudang"
            onChange={handleChangeMasterGudangFilter}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {dataMasterGudang?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nama_gudang}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="label-select-gudang">Komoditas</InputLabel>
          <Select
            labelId="label-select-gudang"
            id="select-gudang"
            value={masterKomoditasId}
            defaultValue={""}
            label="Gudang"
            name="gudang"
            onChange={handleChangeMasterKomoditasFilter}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {dataMasterKomoditasInside
              ?.slice()
              .sort((a, b) => (a.nama || "").localeCompare(b.nama || ""))
              .map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.nama}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
}

DashboardMasterKomoditasFilter.propTypes = {
  selectedMasterPelakuUsaha: PropTypes.any.isRequired,
  role: PropTypes.string,
  firstMonth: PropTypes.number.isRequired,
  secondMonth: PropTypes.number.isRequired,
  selectedYear: PropTypes.number.isRequired,
  selectedDate: PropTypes.string.isRequired,
  selectedMasterGudang: PropTypes.string.isRequired,
  selectedTipeGudang: PropTypes.string.isRequired,
  selectedProvince: PropTypes.string.isRequired,
  selectedRegency: PropTypes.string.isRequired,
  selectedCompany: PropTypes.string.isRequired,
  years: PropTypes.array.isRequired,
  dataCompany: PropTypes.array.isRequired,
  dataMasterGudang: PropTypes.array.isRequired,
  dataMasterPelakuUsaha: PropTypes.array.isRequired,
  provinces: PropTypes.array.isRequired,
  regencies: PropTypes.array.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  setFirstMonth: PropTypes.func.isRequired,
  setSecondMonth: PropTypes.func.isRequired,
  handleYearChange: PropTypes.func.isRequired,
  handleChangeCompanyFilter: PropTypes.func.isRequired,
  handleChangeMasterGudangFilter: PropTypes.func.isRequired,
  setSelectedProvince: PropTypes.func.isRequired,
  handleChangeMasterPelakuUsahaFilter: PropTypes.func.isRequired,
  handleChangeTipeGudangFilter: PropTypes.func.isRequired,
  setSelectedRegency: PropTypes.func.isRequired,
};

export default DashboardMasterKomoditasFilter;
