export async function fetchProvince(token, setProvinces, onlyForInput = false) {
  setProvinces([]);
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/area/provinces`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();

    let result_data = data.data;
    
    if(onlyForInput){
      result_data = result_data.map(item => ({
        label: item.name,
        id: item.id,
      }));
    }

    setProvinces(result_data);
  } catch (e) {
    setProvinces([]);
  }
}

export async function fetchRegency(token, setRegencies, provinceValue = '', onlyForInput = false) {
  setRegencies([]);
  try {
    if (provinceValue) {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area/regencies?province_id=${provinceValue}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();

        let result_data = data.data;
        
        if(onlyForInput){
          result_data = result_data.map(item => ({
            label: item.name,
            id: item.id,
          }));
        }

        setRegencies(result_data);
      } else {
        throw new Error("Failed to fetch area options");
      }
    }
  } catch (e) {
    setRegencies([]);
  }
}

export async function fetchDistrict(token, setDistricts, regencyValue = '', onlyForInput = false) {
  setDistricts([]);
  try {
    if (regencyValue) {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area/districts?regency_id=${regencyValue}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();

        let result_data = data.data;
        
        if(onlyForInput){
          result_data = result_data.map(item => ({
            label: item.name,
            id: item.id,
          }));
        }

        setDistricts(result_data);
      } else {
        throw new Error("Failed to fetch area options");
      }
    }
  } catch (e) {
    setDistricts([]);
  }
}

export async function fetchSubDistrict(token, setSubDistricts, districtValue = '', onlyForInput = false) {
  setSubDistricts([]);
  try {
    if (districtValue) {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area/sub-districts?district_id=${districtValue}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();

        let result_data = data.data;
        
        if(onlyForInput){
          result_data = result_data.map(item => ({
            label: item.name,
            id: item.id,
          }));
        }

        setSubDistricts(result_data);
      } else {
        throw new Error("Failed to fetch area options");
      }
    }
  } catch (e) {
    setSubDistricts([]);
  }
}
