import React from 'react'
import {
  Modal,
  Box,
  Button
} from "@mui/material";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";


function DeleteDataModal({
  isLoading, modalStyle,
  deleteModalOpen,
  handleCloseDeleteModal, handleDelete
}) {
  return (
    <Modal
      open={deleteModalOpen}
      onClose={handleCloseDeleteModal}
      aria-labelledby="status-modal-title"
      aria-describedby="status-modal-description"
      sx={{ border: 0 }}
    >
      <Box sx={modalStyle}>
        <h2>Konfirmasi</h2>
        <p>Apakah anda yakin untuk menghapus data ini?</p>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <Button
            variant="outlined"
            disableRipple
            sx={{
              width: 190,
              height: 50,
              mt: 2,
              color: "#b34469",
              borderColor: "#b34469",
            }}
            onClick={handleCloseDeleteModal}
          >
            Cancel
          </Button>
          <BootstrapButton
            variant="contained"
            disableRipple
            sx={{ width: 182, mt: 2, height: 50,  color: "#FFF" }}
            onClick={handleDelete}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "DELETE"}
          </BootstrapButton>
        </div>
      </Box>
    </Modal>
  )
}

export default DeleteDataModal
