import { React, useEffect, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { toast } from "react-hot-toast";
import {
  DataGrid,
  GridActionsCellItem,
} from "@mui/x-data-grid";
// icons
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

// external
import Sidebar from "../../Components/Sidebar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getAccessToken, profilePromise } from '../../Services/AuthService';
import AddDataModal from "./AddDataModal";
import EditDataModal from "./EditDataModal";
import DeleteDataModal from "./DeleteDataModal";
import SearchBar from "../../Components/SearchBar";
import ImportDataModal from "./ImportDataModal";
import { getUserPromise } from "../../Services/UserService";
import { getCompany } from "../../Services/CompanyService";
import { getMasterGudang } from "../../Services/MasterGudangService";
import EditDataCompanyModal from "./EditDataCompanyModal";
import EditDataMasterGudangModal from "./EditDataMasterGudangModal";
import ViewDataModal from "./ViewDataModal";

const alternatingRowStyles = {
  "& .MuiDataGrid-row:nth-of-type(odd)": {
    backgroundColor: "#88CC81",
  },
  "& .MuiDataGrid-row:nth-of-type(even)": {
    backgroundColor: "#B1E1AD",
  },
};

const defaultTheme = createTheme();

function User() {
  // token
  const accessToken = getAccessToken();
  const [isLoading, setIsLoading] = useState(false);
  const [dataProfile, setDataProfile] = useState(null);
  const [dataUser, setDataUser] = useState([]);
  const [dataCompany, setDataCompany] = useState([]);
  const [dataMasterGudang, setDataMasterGudang] = useState([]);
  const dataUserRole = [
    {
      "id": "admin",
      "label": "Admin",
    },
    {
      "id": "user",
      "label": "User",
    },
  ];

  const [selectedUserRole, setSelectedUserRole] = useState("");

  const fetchProfile = async (accessToken) => {
    try {
      const profile = await profilePromise(accessToken);
      setDataProfile(profile);
    } catch (error) {
      console.error("Failed to fetch profile")
    }
  };

  const fetchUser = async (selectedUserRole) => {
    try {
      await getUserPromise(accessToken, setDataUser, selectedUserRole);
    } catch (error) {
      console.error("Failed to fetch user")
    }
  };

  const fetchCompany = async () => {
    try {
      await getCompany(accessToken, setDataCompany, null, true);
    } catch (error) {
      console.error("Failed to fetch company")
    }
  };

  useEffect(() => {
    fetchProfile(accessToken);
    fetchCompany();
  }, [accessToken])

  useEffect(() => {
    fetchUser(selectedUserRole);
  }, [accessToken, selectedUserRole])

  // filter
  const handleRoleChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedUserRole(id);
  };

  // download
  const handleDownloadData = async () => {
    setIsLoading(true);
    const queryParams = new URLSearchParams();

    if (selectedUserRole && selectedUserRole !== "") {
      queryParams.append("user_role", selectedUserRole);
    }

    queryParams.append("is_excel", "true");

    const queryString = queryParams.toString();
    const labelSuffix = selectedUserRole ? ` - ${dataUserRole.find(option => option.id === selectedUserRole)?.label}` : "";
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/user` +
      (queryString ? `?${queryString}` : "");

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Result User${labelSuffix}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to download. Response Status:", response.status);
      }
    } catch (error) {
      console.error("Error downloading template:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // import
  const [openImport, setOpenImport] = useState(false);
  const handleOpenImport = () => setOpenImport(true);
  const handleCloseImport = () => setOpenImport(false);

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleExportData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/export/template/base`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Template User.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error(
          "Gagal Mendownload Template. Respon Status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Mendownload Template, error:", error);
    }
    setIsLoading(false);
  };

  const handleImportData = async () => {
    setIsLoading(true);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/user/import/template`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "User Error.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          toast.error("Error, ada masalah di excel");
        } else if (response.status === 201) {
          toast.success("Data berhasil ditambahkan");
          handleCloseImport();
          await fetchUser(selectedUserRole);
        } else if (response.status === 400) {
          const data = await response.json();
          toast.error(data.detail);
        } else if (response.status === 500) {
          toast.error("Internal Server Error");
        } else {
          toast.error("Ada masalah");
        }
      } catch (error) {
        console.error(error);
        toast.error("Error");
      }
    }
    setIsLoading(false);
  };

  // handling modal
  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  // create data
  const [formData, setFormData] = useState({
    id: "",
    username: "",
    email: "",
    name: "",
    password: "",
    no_handphone: "",
    user_role: "",
    temp_companies: [],
    temp_master_gudangs: [],
    companies: [],
    master_gudangs: [],
  });

  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleCreateSubmit = async () => {
    setIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/user`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Data berhasil dibuat!");
        await fetchUser(selectedUserRole);
        handleCloseAdd();
        setFormData({
          ...formData,
          id: "",
          username: "",
          email: "",
          name: "",
          password: "",
          no_handphone: "",
          user_role: "",
          temp_companies: [],
          temp_master_gudangs: [],
          companies: [],
          master_gudangs: [],
        })
      } else if (response.status === 422) {
        toast.error("Ada kesalahan saat mengisi form");
      } else if (response.status === 400 || response.status === 404) {
        const data = await response.json();
        toast.error(data.detail || data.data.message)
      } else {
        console.error("Failed to submit data");
        toast.error("Ada kesalahan saat mengisi form");
      }
    } catch (error) {
      console.error("Error during create data submission", error);
    }
    setIsLoading(false);
  };

  // delete table
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const openDeleteConfirmationModal = (itemId) => {
    setItemToDelete(itemId);
    setDeleteModalOpen(true);
  };

  const closeDeleteConfirmationModal = () => {
    setItemToDelete(null);
    setDeleteModalOpen(false);
  };

  const handleDeleteItem = async (itemId) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/user/${itemId}`;

    try {
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        toast.success("Data berhasil di hapus!");
        await fetchUser(selectedUserRole);
      } else {
        toast.error("Data gagal dihapus!");
        console.error("Failed to delete item");
      }
    } catch (error) {
      console.error("Error during item deletion", error);
    }
  };

  // edit function
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editCompanyModalOpen, setEditCompanyModalOpen] = useState(false);
  const [editMasterGudangModalOpen, setEditMasterGudangModalOpen] = useState(false);
  
  const handleCloseEdit = () => {
    setEditModalOpen(false);
    setEditCompanyModalOpen(false);
    setEditMasterGudangModalOpen(false);
    setFormData({
      ...formData,
      id: "",
      username: "",
      email: "",
      name: "",
      password: "",
      no_handphone: "",
      user_role: "",
      temp_companies: [],
      temp_master_gudangs: [],
      companies: [],
      master_gudangs: [],
    })
  };
  
  const handleEditClick = (rowData) => {
    setFormData({
      ...formData,
      id: rowData.row.id,
      username: rowData.row.username,
      email: rowData.row.email,
      name: rowData.row.name,
      password: "",
      no_handphone: rowData.row.no_handphone,
      user_role: rowData.row.user_role,
    })
    setEditModalOpen(true);
  };

  const [viewModalOpen, setViewModalOpen] = useState(false);
  
  const handleCloseView = () => {
    setViewModalOpen(false);
    setFormData({
      ...formData,
      id: "",
      username: "",
      email: "",
      name: "",
      password: "",
      no_handphone: "",
      user_role: "",
      temp_companies: [],
      temp_master_gudangs: [],
      companies: [],
      master_gudangs: [],
    })
  };

  const handleViewClick = (rowData) => {
    setFormData({
      ...formData,
      id: rowData.row.id,
      username: rowData.row.username,
      email: rowData.row.email,
      name: rowData.row.name,
      password: "",
      no_handphone: rowData.row.no_handphone,
      user_role: rowData.row.user_role,
      companies: rowData.row.company,
      master_gudangs: rowData.row.master_gudang,
    })
    setViewModalOpen(true);
  };

  const handleEditCompanyClick = (rowData) => {
    const initiallyAssignedData = rowData.row.company?.map(
      (option) => option.id
    );

    setFormData({
      ...formData,
      id: rowData.row.id,
      companies: initiallyAssignedData,
      temp_companies: initiallyAssignedData,
    })
    setEditCompanyModalOpen(true);
  };

  const handleEditMasterGudangClick = async (rowData) => {
    setDataMasterGudang([]);
    const initiallyAssignedDataMasterGudang = rowData.row.master_gudang?.map(
      (option) => option.id
    );

    const initiallyAssignedDataCompany = rowData.row.company?.map(
      (option) => option.id
    );

    if (initiallyAssignedDataCompany.length > 0) {
      await getMasterGudang({
        access_token: accessToken,
        setData: setDataMasterGudang,
        company_ids: initiallyAssignedDataCompany,
        onlyForInput: true
      });
    }

    setFormData({
      ...formData,
      id: rowData.row.id,
      master_gudangs: initiallyAssignedDataMasterGudang,
      temp_master_gudangs: initiallyAssignedDataMasterGudang,
    })
    setEditMasterGudangModalOpen(true);
  };

  const handleUpdateData = async () => {
    setIsLoading(true);
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/user/${formData.id}`;

      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("data berhasil diubah");
        handleCloseEdit();
        await fetchUser(selectedUserRole);
      } else {
        toast.error("data gagal diubah");
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setIsLoading(false);
  };

  const handleUpdateDataCompany = async () => {
    setIsLoading(true);

    const removedData = formData.temp_companies.filter(item => !formData.companies.includes(item));
    const newData = formData.companies.filter(item => !formData.temp_companies.includes(item));

    if (removedData.length > 0) {
      try {
        // Create an empty URLSearchParams object
        const queryParams = new URLSearchParams();

        for (const id of removedData) {
          queryParams.append('company_ids', id);
        }

        const queryString = queryParams.toString();
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/user/${formData.id}/unassign/company` + (queryString ? `?${queryString}` : '');

        const response = await fetch(apiUrl, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.status === 200) {
          toast.success("data berhasil dihapus");

        } else {
          toast.error("data gagal dihapus");
          console.error("Failed to update data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }

    if (newData.length > 0) {
      try {
        // Create an empty URLSearchParams object
        const queryParams = new URLSearchParams();

        for (const id of newData) {
          queryParams.append('company_ids', id);
        }

        const queryString = queryParams.toString();
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/user/${formData.id}/assign/company` + (queryString ? `?${queryString}` : '');

        const response = await fetch(apiUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.status === 200) {
          toast.success("data berhasil ditambahkan");
        } else {
          toast.error("data gagal ditambahkan");
          console.error("Failed to update data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }

    await fetchUser(selectedUserRole);
    handleCloseEdit();

    setIsLoading(false);
  };

  const handleUpdateDataMasterGudang = async () => {
    setIsLoading(true);

    const removedData = formData.temp_master_gudangs.filter(item => !formData.master_gudangs.includes(item));
    const newData = formData.master_gudangs.filter(item => !formData.temp_master_gudangs.includes(item));

    if (removedData.length > 0) {
      try {
        // Create an empty URLSearchParams object
        const queryParams = new URLSearchParams();

        for (const id of removedData) {
          queryParams.append('master_gudang_ids', id);
        }

        const queryString = queryParams.toString();
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/user/${formData.id}/unassign/master-gudang` + (queryString ? `?${queryString}` : '');

        const response = await fetch(apiUrl, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.status === 200) {
          toast.success("data berhasil dihapus");

        } else {
          toast.error("data gagal dihapus");
          console.error("Failed to update data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }

    if (newData.length > 0) {
      try {
        // Create an empty URLSearchParams object
        const queryParams = new URLSearchParams();

        for (const id of newData) {
          queryParams.append('master_gudang_ids', id);
        }

        const queryString = queryParams.toString();
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/user/${formData.id}/assign/master-gudang` + (queryString ? `?${queryString}` : '');

        const response = await fetch(apiUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.status === 200) {
          toast.success("data berhasil ditambahkan");
        } else {
          toast.error("data gagal ditambahkan");
          console.error("Failed to update data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }

    await fetchUser(selectedUserRole);
    handleCloseEdit();

    setIsLoading(false);
  };

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "username",
      headerName: "USERNAME",
      width: 300,
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "name",
      headerName: "NAMA",
      width: 300,
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "no_handphone",
      headerName: "NO HP",
      width: 250,
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "email",
      headerName: "EMAIL",
      width: 250,
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "user_role",
      headerName: "ROLE",
      width: 100,
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "company.name",
      headerName: "PERUSAHAAN",
      minWidth: 200,
      renderCell: (params) => {
        const companies = params.row.company;
        const displayedCompanies = companies.slice(0, 3).map((data) => data.name);
        const hasMore = companies.length > 3;

        return (
          <p>
            {displayedCompanies.join(', ')}
            {hasMore && ', ...'}
          </p>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "master_gudang.nama_gudang",
      headerName: "GUDANG",
      minWidth: 200,
      renderCell: (params) => {
        const masterGudangs = params.row.master_gudang;
        const displayedMasterGudangs = masterGudangs.slice(0, 3).map((data) => data.nama_gudang);;
        const hasMore = masterGudangs.length > 3;

        return (
          <p>
            {displayedMasterGudangs.join(', ')}
            {hasMore && ', ...'}
          </p>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
      field: "actions",
      type: "actions",
      headerName: "ACTION",
      flex: 1,
      minWidth: 200,
      cellClassName: "actions",
      getActions: (rowData) => {
        let actionList = [];
        if (dataProfile?.role === "admin") {
          actionList = [
            <Tooltip title="View" key={rowData.id}>
              <GridActionsCellItem
                icon={<VisibilityIcon />}
                label="View"
                className="textPrimary"
                color="inherit"
                onClick={() => handleViewClick(rowData)}
              />
            </Tooltip>,
            <Tooltip title="Edit" key={rowData.id}>
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                color="inherit"
                onClick={() => handleEditClick(rowData)}
              />
            </Tooltip>,
            <Tooltip title="Assign Perusahaan" key={rowData.id}>
              <GridActionsCellItem
                icon={<CorporateFareIcon />}
                className="textPrimary"
                label="Assign Perusahaan"
                color="inherit"
                onClick={() => handleEditCompanyClick(rowData)}
              />
            </Tooltip>,
            <Tooltip title="Assign Gudang" arrow key={rowData.id}>
              <GridActionsCellItem
                icon={<WarehouseIcon />}
                className="textPrimary"
                label="Assign Gudang"
                color="inherit"
                onClick={() => handleEditMasterGudangClick(rowData)}
              />
            </Tooltip>,
            <Tooltip title="Delete" key={rowData.id}>
              <GridActionsCellItem
                icon={<DeleteIcon />}
                key={rowData.row.id}
                label="Delete"
                color="inherit"
                onClick={() => openDeleteConfirmationModal(rowData.id)}
              />
            </Tooltip>,
          ];
        }
        return actionList;
      },
    },
  ];

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Sidebar />
        <Box
          component="main"
          sx={{
            ml: "-4%",
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <div style={{ padding: "25px" }}>
            <div style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
              justifyContent: 'space-between'
            }}>
              <SearchBar
                id={"role-autocomplete"}
                data={dataUserRole}
                value={
                  dataUserRole.find(
                    (option) => option.id === selectedUserRole
                  ) ?? null
                }
                handleChange={handleRoleChange}
                label={"Role"}
              />
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '20px'
              }}>
                {
                  dataProfile?.role === 'admin' && <Button
                    variant="outlined"
                    disableRipple
                    sx={{
                      color: "#32AE2F",
                      borderColor: "#32AE2F",
                    }}
                    onClick={handleOpenAdd}
                  >
                    Create
                  </Button>
                }
                {
                  dataProfile?.role === 'admin' && <Button
                    variant="outlined"
                    disableRipple
                    sx={{
                      color: "#32AE2F",
                      borderColor: "#32AE2F",
                    }}
                    onClick={handleOpenImport}
                  >
                    Import
                  </Button>
                }
                {
                  dataProfile?.role === 'admin' && <Button
                    variant="outlined"
                    disableRipple
                    sx={{
                      color: "#32AE2F",
                      borderColor: "#32AE2F",
                    }}
                    disabled={isLoading}
                    onClick={handleDownloadData}
                  >
                    {isLoading ? "Loading..." : "Download"}
                  </Button>
                }
              </div>
            </div>
            <div>
              {/* delete modal */}
              <DeleteDataModal
                modal={{
                  open: deleteModalOpen
                }}
                handle={{
                  handleClose: closeDeleteConfirmationModal,
                  handleDeleteItem: handleDeleteItem,
                }}
                isLoading={isLoading}
                itemDeleted={itemToDelete}
              />
              {/* create modal */}
              <AddDataModal
                modal={{
                  open: openAdd
                }}
                handle={{
                  handleClose: handleCloseAdd,
                  handleInputChange: handleInputChange,
                  handleCreateSubmit: handleCreateSubmit,
                }}
                isLoading={isLoading}
                formData={formData}
                dataOption={{
                  dataUserRole: dataUserRole
                }}
              />
              {/* import modal */}
              <ImportDataModal
                modal={{
                  open: openImport
                }}
                handle={{
                  handleClose: handleCloseImport,
                  handleFileChange: handleFileChange,
                  handleExportData: handleExportData,
                  handleImportData: handleImportData,
                }}
                isLoading={isLoading}
                itemDeleted={itemToDelete}
              />
              {/* view modal */}
              <ViewDataModal
                modal={{
                  open: viewModalOpen
                }}
                handle={{
                  handleClose: handleCloseView,
                }}
                isLoading={isLoading}
                formData={formData}
              />
              {/* edit modal */}
              <EditDataModal
                modal={{
                  open: editModalOpen
                }}
                handle={{
                  handleClose: handleCloseEdit,
                  handleInputChange: handleInputChange,
                  handleUpdate: handleUpdateData,
                }}
                isLoading={isLoading}
                formData={formData}
              />
              {/* edit modal company */}
              <EditDataCompanyModal
                modal={{
                  open: editCompanyModalOpen
                }}
                handle={{
                  handleClose: handleCloseEdit,
                  handleInputChange: handleInputChange,
                  handleUpdate: handleUpdateDataCompany,
                }}
                isLoading={isLoading}
                formData={formData}
                dataOption={{
                  dataCompany: dataCompany
                }}
              />
              {/* edit modal master gudang */}
              <EditDataMasterGudangModal
                modal={{
                  open: editMasterGudangModalOpen
                }}
                handle={{
                  handleClose: handleCloseEdit,
                  handleInputChange: handleInputChange,
                  handleUpdate: handleUpdateDataMasterGudang,
                }}
                isLoading={isLoading}
                formData={formData}
                dataOption={{
                  dataMasterGudang: dataMasterGudang
                }}
              />
            </div>
            {
              dataUser.length > 0 && <DataGrid
                sx={{
                  mt: 2,
                  "& .super-app-theme--header": {
                    backgroundColor: "#32AE2F",
                    color: "#000000",
                    border: 2,
                    fontWeight: "bold",
                    borderColor: "#FFF",
                  },
                  ...alternatingRowStyles,
                }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "period", sort: "asc" }],
                  },
                }}
                className="alternating-row-grid"
                getRowId={(row) => row.id}
                rows={dataUser}
                columns={columns}
                editMode="row"
                getRowHeight={() => 'auto'}
              />
            }
          </div>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default User;
