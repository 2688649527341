import React, { useState } from "react";
import {
  Modal,
  Box,
  TextField,
  IconButton,
  MenuItem,
  Button,
  Autocomplete,
} from "@mui/material";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";
import CloseIcon from "@mui/icons-material/Close";
import MapPickerModal from "./MapPickerModal";

function AddDataModal({ modal, isLoading, handle, formData, dataOption }) {
  const [mapModalOpen, setMapModalOpen] = useState(false);

  const handleOpenMapModal = () => {
    setMapModalOpen(true);
  };

  const handleCloseMapModal = () => {
    setMapModalOpen(false);
  };

  const handleSelectLocation = (position) => {
    handle.handleInputChange("latitude", position.lat);
    handle.handleInputChange("longitude", position.lng);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 2,
  };

  return (
    <Modal
      open={modal.open}
      onClose={handle.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        border: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          ...style,
          width: 500,
          border: "0px solid #000",
          boxShadow: 24,
          borderRadius: 3,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            right: 10,
            color: "rgba(0, 0, 0, 0.54)",
          }}
          onClick={handle.handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            overflow: "auto",
            maxHeight: "80vh",
            mt: 3
          }}
        >
          <div className="label">
            <p
              className="text-wrapper"
              style={{
                bgcolor: "#000000",
                fontFamily: "Roboto",
                fontWeight: 600,
                fontSize: 18,
                textAlign: "center",
              }}
            >
              Tambah Data
            </p>
          </div>
          <TextField
            id="outlined-select-nama-gudang"
            label="Nama Gudang"
            required={true}
            sx={{ width: "94%", mt: 2, ml: "3%" }}
            size="small"
            value={formData.nama_gudang}
            onChange={(e) =>
              handle.handleInputChange("nama_gudang", e.target.value)
            }
          ></TextField>
          <TextField
            id="outlined-select-tipe-gudang"
            label="Tipe Gudang"
            required={true}
            sx={{ width: "94%", mt: 2, ml: "3%" }}
            size="small"
            select
            value={formData.tipe_gudang}
            onChange={(e) =>
              handle.handleInputChange("tipe_gudang", e.target.value)
            }
          >
            {dataOption?.dataTipeGudang?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Box sx={{ width: "94%", mt: 2, ml: "3%" }}>
            <Autocomplete
              id="outlined-select-company-id"
              options={dataOption?.dataCompany || []}
              getOptionLabel={(option) => option.label || ""}
              renderOption={(props, option) => (
                <li {...props}>{option.label}</li>
              )}
              value={
                dataOption?.dataCompany?.find(
                  (item) => item.id === formData.company_id
                ) || null
              }
              onChange={(event, newValue) => {
                handle.handleInputChange(
                  "company_id",
                  newValue ? newValue.id : ""
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Perusahaan"
                  required
                  size="small"
                />
              )}
            />
          </Box>
          <Box sx={{ width: "94%", mt: 2, ml: "3%" }}>
            <Autocomplete
              id="outlined-select-province-id"
              options={dataOption?.dataProvince || []}
              getOptionLabel={(option) => option.label || ""}
              renderOption={(props, option) => (
                <li {...props}>{option.label}</li>
              )}
              value={
                dataOption?.dataProvince?.find(
                  (item) => item.id === formData.province_id
                ) || null
              }
              onChange={(event, newValue) => {
                handle.handleInputProvinceChange(newValue ? newValue.id : "");
                handle.handleInputChange(
                  "province_id",
                  newValue ? newValue.id : ""
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Provinsi" required size="small" />
              )}
            />
          </Box>
          <Box sx={{ width: "94%", mt: 2, ml: "3%" }}>
            <Autocomplete
              id="outlined-select-regency-id"
              options={dataOption?.dataRegency || []}
              getOptionLabel={(option) => option.label || ""}
              renderOption={(props, option) => (
                <li {...props}>{option.label}</li>
              )}
              value={
                dataOption?.dataRegency?.find(
                  (item) => item.id === formData.regency_id
                ) || null
              }
              onChange={(event, newValue) => {
                handle.handleInputRegencyChange(newValue ? newValue.id : "");
                handle.handleInputChange(
                  "regency_id",
                  newValue ? newValue.id : ""
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Kota/Kabupaten"
                  required
                  size="small"
                />
              )}
            />
          </Box>
          <Box sx={{ width: "94%", mt: 2, ml: "3%" }}>
            <Autocomplete
              id="outlined-select-district-id"
              options={dataOption?.dataDistrict || []}
              getOptionLabel={(option) => option.label || ""}
              renderOption={(props, option) => (
                <li {...props}>{option.label}</li>
              )}
              value={
                dataOption?.dataDistrict?.find(
                  (item) => item.id === formData.district_id
                ) || null
              }
              onChange={(event, newValue) => {
                handle.handleInputDistrictChange(newValue ? newValue.id : "");
                handle.handleInputChange(
                  "district_id",
                  newValue ? newValue.id : ""
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Kecamatan"
                  required
                  size="small"
                />
              )}
            />
          </Box>
          <Box sx={{ width: "94%", mt: 2, ml: "3%" }}>
            <Autocomplete
              id="outlined-select-sub-district-id"
              options={dataOption?.dataSubDistrict || []}
              getOptionLabel={(option) => option.label || ""}
              renderOption={(props, option) => (
                <li {...props}>{option.label}</li>
              )}
              value={
                dataOption?.dataSubDistrict?.find(
                  (item) => item.id === formData.sub_district_id
                ) || null
              }
              onChange={(event, newValue) => {
                handle.handleInputChange(
                  "sub_district_id",
                  newValue ? newValue.id : ""
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Kelurahan"
                  required
                  size="small"
                />
              )}
            />
          </Box>
          <TextField
            id="outlined-select-address"
            label="Alamat"
            sx={{ width: "94%", mt: 2, ml: "3%" }}
            size="small"
            value={formData.address}
            onChange={(e) =>
              handle.handleInputChange("address", e.target.value)
            }
          ></TextField>
          <TextField
            id="outlined-select-kapasitas"
            label="Kapasitas"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            required={true}
            sx={{ width: "94%", mt: 2, ml: "3%" }}
            size="small"
            value={formData.kapasitas}
            onChange={(e) =>
              handle.handleInputChange("kapasitas", e.target.value)
            }
          ></TextField>
          <TextField
            id="outlined-select-latitude"
            label="Latitude"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            sx={{ width: "94%", mt: 2, ml: "3%" }}
            size="small"
            value={formData.latitude}
            onChange={(e) =>
              handle.handleInputChange("latitude", e.target.value)
            }
          ></TextField>
          <TextField
            id="outlined-select-longitudse"
            InputLabelProps={{
              shrink: true,
            }}
            label="Longitude"
            type="number"
            sx={{ width: "94%", mt: 2, ml: "3%" }}
            size="small"
            value={formData.longitude}
            onChange={(e) =>
              handle.handleInputChange("longitude", e.target.value)
            }
          ></TextField>
          <Button
            variant="outlined"
            onClick={handleOpenMapModal}
            sx={{ width: "94%", mt: 2, ml: "3%" }}
          >
            Pilih Melalui Map
          </Button>
          <BootstrapButton
            variant="contained"
            disableRipple
            disabled={isLoading}
            onClick={handle.handleCreateSubmit}
            sx={{
              width: "94%",
              ml: "2%",
              mt: 4,
              borderRadius: 2.5,
              backgroundColor: "#32AE2F",
              "&:hover": {
                backgroundColor: "#1d691b",
                boxShadow: "none",
              },
            }}
          >
            {isLoading ? "Loading..." : "SUBMIT"}
          </BootstrapButton>
          <MapPickerModal
            open={mapModalOpen}
            handleClose={handleCloseMapModal}
            handleSelectLocation={handleSelectLocation}
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default AddDataModal;
