import axios from 'axios';

export const getAllPelakuUsaha = async (
    access_token,
    setDataPelakuUsaha,
    selectedCompanyAgency,
    is_dashboard = null
  ) => {
    // Create an empty URLSearchParams object
    const queryParams = new URLSearchParams();
  
    if (selectedCompanyAgency && selectedCompanyAgency !== "") {
      queryParams.append("company_agency", selectedCompanyAgency);
    }
  
    if (is_dashboard) {
      queryParams.append("is_dashboard", is_dashboard);
    }
  
    const queryString = queryParams.toString();
    const apiUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/master_pelaku_usaha` +
      (queryString ? `?${queryString}` : "");
  
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
  
      let result_data = response?.data?.data;
  
      result_data = result_data.map((item) => ({
        label: item.name,
        id: item.id,
      }));
  
      setDataPelakuUsaha(result_data);
    } catch (e) {
      return [];
    }
  };