import axios from 'axios';

export const getMasterGudang = async ({ 
  access_token, 
  setData, 
  tipe_gudang = null,
  company_agency = null,
  master_komoditas_id = null,
  kapasitas = null,
  master_pelaku_usaha_id = null,
  province_id = null,
  regency_id = null,
  district_id = null,
  sub_district_id = null,
  company_ids = null,
  onlyForInput = false,
  company_id = null,
}) => {
  // Create an empty URLSearchParams object
  const queryParams = new URLSearchParams();

  if (province_id) {
    queryParams.append('province_id', province_id);
  }

  if (regency_id) {
    queryParams.append('regency_id', regency_id);
  }

  if (district_id) {
    queryParams.append('district_id', district_id);
  }
  
  if (sub_district_id) {
    queryParams.append('sub_district_id', sub_district_id);
  }

  if (tipe_gudang) {
    queryParams.append('tipe_gudang', tipe_gudang);
  }
  
  if (company_agency) {
    queryParams.append('company_agency', company_agency);
  }
  
  if (master_komoditas_id && master_komoditas_id.length > 0) {
    master_komoditas_id.forEach((id) => {
      queryParams.append("master_komoditas_ids", encodeURIComponent(id));
    });
  }
  
  if (kapasitas) {
    queryParams.append('kapasitas', kapasitas);
  }
  
  if(master_pelaku_usaha_id){
    queryParams.append('master_pelaku_usaha_id', master_pelaku_usaha_id);
  }
  
  if(company_id){
    queryParams.append('company_id', company_id);
  }

  if(company_ids){
    for (const company_id of company_ids) {
      queryParams.append('company_id', company_id);
    }
  }

  const queryString = queryParams.toString();
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/master-gudang` + (queryString ? `?${queryString}` : '');
  
  try{
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    
    let result_data = response?.data?.data;

    if(onlyForInput){
      result_data = result_data.map(item => ({
        label: item.nama_gudang,
        id: item.id,
        kapasitas: item.kapasitas,
      }));
    }

    setData(result_data)
  }catch(e){
    setData([])
  }
};

export const getMasterGudangMap = async ({ 
  access_token, 
  setData, 
  tipe_gudang = null,
  master_pelaku_usaha_id = null,
  province_id = null,
  date = null,
  company_id = null,
  onlyForInput = false,
  master_komoditas_id = false,
}) => {
  // Create an empty URLSearchParams object
  const queryParams = new URLSearchParams();

  if (date) {
    queryParams.append('tanggal', date);
  }
  
  if (master_pelaku_usaha_id) {
    queryParams.append('master_pelaku_usaha_id', master_pelaku_usaha_id);
  }

  if (province_id) {
    queryParams.append('province_id', province_id);
  }

  if (company_id) {
    queryParams.append('company_id', company_id);
  }

  if (tipe_gudang) {
    queryParams.append('tipe_gudang', tipe_gudang);
  }

  if (master_komoditas_id && master_komoditas_id.length > 0) {
    master_komoditas_id.forEach((id) => {
      queryParams.append("master_komoditas_ids", encodeURIComponent(id));
    });
  }

  const queryString = queryParams.toString();
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/dashboard/master-gudang` + (queryString ? `?${queryString}` : '');
  
  try{
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    
    let result_data = response?.data?.data;

    if(onlyForInput){
      result_data = result_data.map(item => ({
        label: item.nama_gudang,
        id: item.id,
        kapasitas: item.kapasitas,
      }));
    }

    setData(result_data)
  }catch(e){
    setData([])
  }
};
