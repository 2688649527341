export const alternatingStyles = {
  "& .super-app-theme--header": {
    backgroundColor: "#32AE2F",
    color: "#000000",
    border: 2,
    fontWeight: "bold",
    borderColor: "#FFF",
  },
  "& .MuiDataGrid-row:nth-of-type(odd)": {
    backgroundColor: "#88CC81",
  },
  "& .MuiDataGrid-row:nth-of-type(even)": {
    backgroundColor: "#B1E1AD",
  },
};

export const alternatingRowStyles = {
  "& .MuiDataGrid-row:nth-of-type(odd)": {
    backgroundColor: "#88CC81",
  },
  "& .MuiDataGrid-row:nth-of-type(even)": {
    backgroundColor: "#B1E1AD",
  },
};
