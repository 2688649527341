import {
  Button,
  TablePagination,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  TableSortLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { alternatingStyles } from "../../style/generalStyle";
import PropTypes from "prop-types";

function TableMasterPelakuUsaha({
  data,
  dataProfile,
  handleEditClick,
  openDeleteConfirmationModal,
  itemsPerPageList,
  total,
  size,
  offset,
  handlePageChange,
  handleChangeItemPerPageSelect,
  sorting,
  handleSort,
}) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        ...alternatingStyles,
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell className="super-app-theme--header">
              <TableSortLabel
                active={sorting.column === "name"}
                direction={
                  sorting.column === "name" ? sorting.order : undefined
                }
                onClick={() => handleSort("name")}
              >
                NAMA
              </TableSortLabel>
            </TableCell>
            <TableCell className="super-app-theme--header">
              <TableSortLabel
                active={sorting.column === "company_agency"}
                direction={
                  sorting.column === "company_agency"
                    ? sorting.order
                    : undefined
                }
                onClick={() => handleSort("company_agency")}
              >
                INSTANSI
              </TableSortLabel>
            </TableCell>
            <TableCell className="super-app-theme--header">ACTION</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                backgroundColor: index % 2 === 0 ? "#B1E1AD" : "#88CC81",
              }}
            >
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.company_agency}</TableCell>
              <TableCell align="center">
                {dataProfile?.role === "admin" && (
                  <Tooltip title="Edit">
                    <Button
                      onClick={() => handleEditClick(row)}
                      color="warning"
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                )}
                {/* {dataProfile?.role === "admin" &&
                  <Tooltip title="Delete">
                    <Button
                      onClick={() => openDeleteConfirmationModal(row.id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </Button>
                  </Tooltip>
                } */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={itemsPerPageList}
        component="div"
        count={total}
        rowsPerPage={size}
        page={offset - 1}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeItemPerPageSelect}
      />
    </TableContainer>
  );
}

TableMasterPelakuUsaha.propTypes = {
  data: PropTypes.array.isRequired,
  dataProfile: PropTypes.object,
  handleEditClick: PropTypes.func.isRequired,
  openDeleteConfirmationModal: PropTypes.func.isRequired,
  itemsPerPageList: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  sorting: PropTypes.object.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handleChangeItemPerPageSelect: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
};

export default TableMasterPelakuUsaha;
