import { React, useEffect, useState } from "react";
import { Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { toast } from "react-hot-toast";

// external
import Sidebar from "../../Components/SidebarTwo";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getAccessToken, profilePromise } from '../../Services/AuthService';
import { getAllMasterKomoditas } from "../../Services/KomoditasService";
import AddDataModal from "./AddDataModal";
import EditDataModal from "./EditDataModal";
import DeleteDataModal from "./DeleteDataModal";
import TableMasterKomoditas from "./Table";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const defaultTheme = createTheme();

function MasterKomoditas() {
  const location = useLocation();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  // token
  const accessToken = getAccessToken();
  const [isLoading, setIsLoading] = useState(false);
  const [dataProfile, setDataProfile] = useState(null);
  const [dataMasterKomoditas, setDataMasterKomoditas] = useState([]);
  const [totalDataMasterKomoditas, setTotalDataMasterKomoditas] = useState(0);

  // table
  // pagination
  const currentPage = Number(searchParams.get('page')) || 1;
  let pageSize = Number(searchParams.get('size')) || 20;
  const itemsPerPageList = [5, 10, 20, 50];
  const lastPageSize = itemsPerPageList[itemsPerPageList.length - 1];
  pageSize = itemsPerPageList.includes(pageSize) ? pageSize : lastPageSize;
  // sort
  const SortingStates = {
    ASC: 'asc',
    DESC: 'desc',
  };
  const [sorting, setSorting] = useState({ column: searchParams.get('sort_by') || 'nama', order: searchParams.get('sort_order') || SortingStates.ASC });

  // profile
  const fetchProfile = async (accessToken) => {
    try {
      const profile = await profilePromise(accessToken);
      setDataProfile(profile);
    } catch (error) {
      console.error("Failed to fetch profile")
    }
  };

  // data
  const fetchMasterKomoditas = async (accessToken, customParam) => {
    setIsLoading(true)
    try {
      const data = await getAllMasterKomoditas(accessToken, customParam);
      setDataMasterKomoditas(data.data);
      setTotalDataMasterKomoditas(data.pagination_info.total)
    } catch (error) {
      console.error("Failed to fetch master komoditas")
    }
    setIsLoading(false)
  };

  useEffect(() => {
    fetchProfile(accessToken);
    fetchMasterKomoditas(accessToken, { 
      page: currentPage, 
      size: pageSize,
      sort_by: sorting.column, 
      sort_order: sorting.order
    });
  }, [accessToken])


  // handling modal
  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  // create data
  const [formData, setFormData] = useState({
    id: "",
    nama: "",
    image: "",
  });

  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleImageChange = (file) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: file,
    }));
  };

  const handleCreateSubmit = async () => {
    setIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/komoditas`;

    try {
      const formDataObj = new FormData();
      formDataObj.append("id", formData.id);
      formDataObj.append("nama", formData.nama);
      if (formData.image) {
        formDataObj.append("image", formData.image);
      }

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formDataObj,
      });

      if (response.ok) {
        toast.success("Data berhasil dibuat!");
        await fetchMasterKomoditas(accessToken, { 
          page: currentPage, 
          size: pageSize,
          sort_by: sorting.column, 
          sort_order: sorting.order
        });
        handleCloseAdd();
        setFormData({
          ...formData,
          id: '',
          nama: '',
          image: '',
        });
      } else if (response.status === 422) {
        toast.error("Ada kesalahan saat mengisi form");
      } else if (response.status === 400 || response.status === 404) {
        const data = await response.json();
        toast.error(data.detail || data.data.message)
      } else {
        console.error("Failed to submit data");
        toast.error("Ada kesalahan saat mengisi form");
      }
    } catch (error) {
      console.error("Error during create data submission", error);
    }
    setIsLoading(false);
  };

  // delete table
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const openDeleteConfirmationModal = (itemId) => {
    setItemToDelete(itemId);
    setDeleteModalOpen(true);
  };

  const handleCloseDelete = () => {
    setItemToDelete(null);
    setDeleteModalOpen(false);
  };

  const handleDeleteItem = async (itemId) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/komoditas/${itemId}`;

    try {
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        toast.success("Data berhasil di hapus!");
        await fetchMasterKomoditas(accessToken, { 
          page: currentPage, 
          size: pageSize,
          sort_by: sorting.column, 
          sort_order: sorting.order
        });
      } else {
        toast.error("Data gagal dihapus!");
        console.error("Failed to delete item");
      }
    } catch (error) {
      console.error("Error during item deletion", error);
    }
  };

  // edit function
  const [editModalOpen, setEditModalOpen] = useState(false);

  const handleCloseEdit = () => {
    setEditModalOpen(false);
    setFormData({
      ...formData,
      id: '',
      nama: '',
    })
  };

  const handleEditClick = (row) => {
    setFormData({
      ...formData,
      id: row.id,
      nama: row.nama,
    })
    setEditModalOpen(true);
  };

  const handleUpdateData = async () => {
    setIsLoading(true);

    const formDataObj = new FormData();
    formDataObj.append("nama", formData.nama);
    if (formData.image) {
      formDataObj.append("image", formData.image);
    }

    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/komoditas/${formData.id}`;

      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formDataObj,
      });

      if (response.status === 200) {
        toast.success("data berhasil diubah");
        handleCloseEdit();
        await fetchMasterKomoditas(accessToken, { 
          page: currentPage, 
          size: pageSize,
          sort_by: sorting.column, 
          sort_order: sorting.order
        });
      } else {
        toast.error("data gagal diubah");
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setIsLoading(false);
  };


  // next or previous page
  const handlePageChange = (event, page) => {
    const newPage = Number(page + 1);
    const currentSearchParams = new URLSearchParams(location.search);
    currentSearchParams.set('page', newPage);
    navigate(`${location.pathname}?${currentSearchParams.toString()}`);
    fetchMasterKomoditas(accessToken, { 
      page: newPage, 
      size: pageSize,
      sort_by: sorting.column, 
      sort_order: sorting.order
    });
  };

  // rows per page
  const handleChangeItemPerPageSelect = (event) => {
    const newSize = Number(event.target.value);
    const pageReset = Number(1);
    const currentSearchParams = new URLSearchParams(location.search);
    currentSearchParams.set('size', newSize);
    currentSearchParams.set('page', pageReset);
    navigate(`${location.pathname}?${currentSearchParams.toString()}`);
    fetchMasterKomoditas(accessToken, { 
      page: pageReset, 
      size: newSize,
      sort_by: sorting.column, 
      sort_order: sorting.order
    });
  };

  // sort
  const handleSort = (columnName) => {
    let newOrder;
    if (sorting.column === columnName) {
      newOrder = sorting.order === SortingStates.ASC ? SortingStates.DESC : SortingStates.ASC;
    } else {
      newOrder = SortingStates.ASC;
    }

    setSorting({ column: columnName, order: newOrder });
    
    const currentSearchParams = new URLSearchParams(location.search);
    currentSearchParams.set('sort_by', columnName);
    currentSearchParams.set('sort_order', newOrder);
    navigate(`${location.pathname}?${currentSearchParams.toString()}`);
    fetchMasterKomoditas(accessToken, { 
      page: currentPage, 
      size: pageSize, 
      sort_by: columnName, 
      sort_order: newOrder
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Sidebar />
        <Box
          component="main"
          sx={{
            padding: '20px',
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <div style={{ padding: "25px" }}>
            <div style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
              justifyContent: 'space-between'
            }}>
              <div></div>
              {
                dataProfile?.role === 'admin' && <Button
                  variant="outlined"
                  disableRipple
                  sx={{
                    color: "#32AE2F",
                    borderColor: "#32AE2F",
                  }}
                  onClick={handleOpenAdd}
                >
                  Create
                </Button>
              }
            </div>
            <div>
              {/* delete modal */}
              <DeleteDataModal
                modal={{
                  open: deleteModalOpen
                }}
                handle={{
                  handleClose: handleCloseDelete,
                  handleDeleteItem: handleDeleteItem,
                }}
                isLoading={isLoading}
                itemDeleted={itemToDelete}
              />
              {/* create modal */}
              <AddDataModal
                modal={{
                  open: openAdd
                }}
                handle={{
                  handleClose: handleCloseAdd,
                  handleInputChange: handleInputChange,
                  handleImageChange: handleImageChange,
                  handleCreateSubmit: handleCreateSubmit,
                }}
                isLoading={isLoading}
                formData={formData}
              />
              {/* edit modal */}
              <EditDataModal
                modal={{
                  open: editModalOpen
                }}
                handle={{
                  handleClose: handleCloseEdit,
                  handleInputChange: handleInputChange,
                  handleImageChange: handleImageChange,
                  handleUpdate: handleUpdateData,
                }}
                isLoading={isLoading}
                formData={formData}
              />
            </div>
          </div>
          {
            !isLoading && <TableMasterKomoditas
              data={dataMasterKomoditas}
              dataProfile={dataProfile}
              handleEditClick={handleEditClick}
              openDeleteConfirmationModal={openDeleteConfirmationModal}
              itemsPerPageList={itemsPerPageList}
              total={totalDataMasterKomoditas}
              size={pageSize}
              offset={!totalDataMasterKomoditas || totalDataMasterKomoditas <= 0 ? 1 : currentPage}
              handlePageChange={handlePageChange}
              handleChangeItemPerPageSelect={handleChangeItemPerPageSelect}
              sorting={sorting}
              handleSort={handleSort}
            />
          }
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default MasterKomoditas;
