import Cookies from "js-cookie";
import axios from 'axios';

const TOKEN_KEY = 'jwt_token';

export const loginPromise = async (username, password) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/auth/login`, {
    username,
    password,
  });

  const token = response.data.data.access_token;
  Cookies.set(TOKEN_KEY, token, { httpOnly: false, secure: false });

  return 'Login successful';
};


export const getAccessToken = () => {
  return Cookies.get(TOKEN_KEY) || ""; ;
};

export const profilePromise = async (access_token) => {
  if(!access_token){
    throw new Error("login first");
  }

  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/auth/profile`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });

  return response.data.data;
};
