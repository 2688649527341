export const dataCompanyAgency = [
  {
    label: "bumn",
    id: "bumn",
  },
  {
    label: "swasta",
    id: "swasta",
  },
];


export const dataTipeGudang = [
  {
    label: "berpendingin",
    id: "berpendingin",
  },
  {
    label: "tidak berpendingin",
    id: "tidak berpendingin",
  },
];
