import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import {
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

const alternatingRowStyles = {
  "& .MuiDataGrid-row:nth-of-type(odd)": {
    backgroundColor: "#88CC81",
  },
  "& .MuiDataGrid-row:nth-of-type(even)": {
    backgroundColor: "#B1E1AD",
  },
};

function DataGridLaporan({ isLoading, rows, handleOpenDeleteModal }) {

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "company.company_name",
      headerName: "PERUSAHAAN",
      width: 230,
      valueGetter: (params) => params.row.company?.company_name || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "company.pelaku_usaha.name",
      headerName: "PELAKU USAHA",
      width: 230,
      valueGetter: (params) => params.row.company?.pelaku_usaha?.name || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "master_gudang.nama_gudang",
      headerName: "GUDANG",
      width: 230,
      valueGetter: (params) => params.row.master_gudang?.nama_gudang || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "master_komoditas.nama",
      headerName: "KOMODITAS",
      width: 280,
      valueGetter: (params) => params.row.master_komoditas?.nama || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "master_sub_komoditas.nama",
      headerName: "SUB KOMODITAS",
      width: 280,
      valueGetter: (params) => params.row.master_sub_komoditas?.nama || "",
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "stok",
      headerName: "STOK",
      width: 230,
      editable: false,
      valueFormatter: (params) => {
        return params.value.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 3
        });
      }
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "tanggal",
      headerName: "TANGGAL",
      width: 230,
      editable: false,
    },
    {
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "center",
      field: "tanggal_submit",
      headerName: "TANGGAL SUBMIT",
      width: 230,
      editable: false,
    },
    {
      field: "actions",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <div>
          <Tooltip
            title="Delete"
            onClick={() => handleOpenDeleteModal(params.row)}
          >
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <DataGrid
      sx={{
        mt: 2,
        "& .super-app-theme--header": {
          backgroundColor: "#32AE2F",
          color: "#000000",
          border: 2,
          fontWeight: "bold",
          borderColor: "#FFF",
        },
        ...alternatingRowStyles,
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: 'period', sort: 'asc' }],
        },
      }}
      className="alternating-row-grid"
      loading={isLoading}
      getRowId={(row) => row.id}
      rows={rows}
      columns={columns}
      editMode="row"
    />
  )
}

export default DataGridLaporan
