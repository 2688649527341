import { React } from "react";
import { Modal, Box, Tooltip, IconButton } from "@mui/material";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";
import CloseIcon from "@mui/icons-material/Close";

function ImportDataModal({
  modal,
  isLoading,
  handle,
}) {

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={modal.open}
      onClose={handle.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        border: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          ...style,
          width: 500,
          height: 250,
          border: "0px solid #000",
          boxShadow: 24,
          borderRadius: 3,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            borderRadius: "0 0 3px 3px",
            flex: 1,
          }}
        >
          <Tooltip title="Close Modal">
            <IconButton
              edge="end"
              color="inherit"
              onClick={handle.handleClose}
              sx={{ position: "absolute", top: 20, right: 30 }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <div>
            <div style={{ marginBottom: "25px" }}>
              <center>
                <h3>Import Perusahaan</h3>
              </center>
              <input type="file" onChange={handle.handleFileChange} />{" "}
              {/* File input */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <BootstrapButton
                  variant="outlined"
                  disableRipple
                  sx={{
                    width: "49%",
                    mt: 4,
                    borderRadius: 2.5,
                    color: "#FFF",
                    fontSize: "16px",
                    backgroundColor: "#32AE2F",
                    "&:hover": {
                      backgroundColor: "#1d691b",
                      boxShadow: "none",
                    },
                  }}
                  onClick={handle.handleExportMasterAreaData}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Master Area"}
                </BootstrapButton>
                <BootstrapButton
                  variant="outlined"
                  disableRipple
                  sx={{
                    width: "49%",
                    mt: 4,
                    borderRadius: 2.5,
                    color: "#FFF",
                    fontSize: "16px",
                    backgroundColor: "#32AE2F",
                    "&:hover": {
                      backgroundColor: "#1d691b",
                      boxShadow: "none",
                    },
                  }}
                  onClick={handle.handleExportData}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Template"}
                </BootstrapButton>
                <BootstrapButton
                  variant="contained"
                  disableRipple
                  sx={{
                    width: "49%",
                    ml: "2%",
                    mt: 4,
                    borderRadius: 2.5,
                    fontSize: "16px",
                    backgroundColor: "#32AE2F",
                    "&:hover": {
                      backgroundColor: "#1d691b",
                      boxShadow: "none",
                    },
                  }}
                  onClick={handle.handleImportData}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Upload"}
                </BootstrapButton>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default ImportDataModal;
