import { React } from "react";
import { Modal, Box, TextField, IconButton } from "@mui/material";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

function DeleteDataModal({
  modal,
  isLoading,
  handle,
  itemDeleted
}) {
  return (
    <Dialog
      open={modal.open}
      onClose={handle.handleClose}
    >
      <DialogTitle>Konfirmasi Hapus?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Apakah anda yakin ingin menghapus data ini?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handle.handleClose}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handle.handleDeleteItem(itemDeleted);
            handle.handleClose();
          }}
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDataModal;
