import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import "./style/form.css";
import "./style/navbar.css";
import Laporan from "./Pages/Laporan";
import MasterGudang from "./Pages/MasterGudang";
import MasterPelakuUsaha from "./Pages/MasterPelakuUsaha";
import MasterKomoditas from "./Pages/MasterKomoditas";
import SubKomoditas from "./Pages/SubKomoditas";
import User from "./Pages/User";
import Dashboard from "./Pages/Dashboard";
import Company from "./Pages/Company";
import Login from "./Pages/Login";
import { Toaster } from "react-hot-toast";
import RequireAuth from "./Components/RequireAuth";
import NotFound from "./Pages/NotFound";
import MapArea from "./Pages/MapArea";
import Absensi from "./Pages/Absensi";
import Panduan from "./Pages/Panduan";
import MobileApps from "./Pages/MobileApps";
import DashboardMasterKomoditas from "./Pages/Dashboard/DashboardMasterKomoditas";
import DashboardSubKomoditas from "./Pages/Dashboard/DashboardSubKomoditas";

  function App() {
    return (
      <Router>
        <div>
          <Toaster />
        </div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />
  
          <Route element={<RequireAuth />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="dashboard/:masterKomoditasId" element={<DashboardMasterKomoditas />} />
            <Route path="dashboard/sub-komoditas/:masterKomoditasId" element={<DashboardSubKomoditas />} />
            <Route path="laporan" element={<Laporan />} />
            <Route path="gudang" element={<MasterGudang />} />
            <Route path="pelaku-usaha" element={<MasterPelakuUsaha />} />
            <Route path="komoditas" element={<MasterKomoditas />} />
            <Route path="sub-komoditas" element={<SubKomoditas />} />
            <Route path="user" element={<User />} />
            <Route path="perusahaan" element={<Company />} />
            <Route path="map-area" element={<MapArea />} />
            <Route path="absensi" element={<Absensi />} />
            <Route path="panduan" element={<Panduan />} />
            <Route path="mobileapps" element={<MobileApps />} />
          </Route>
          {/* handling not found */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    );
  }

export default App;
