import { React } from "react";
import { Modal, Box, TextField, IconButton } from "@mui/material";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";
import CloseIcon from "@mui/icons-material/Close";

function AddDataModal({
  modal,
  isLoading,
  handle,
  formData
}) {

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={modal.open}
      onClose={handle.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        border: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          ...style,
          width: 500,
          height: 540,
          border: "0px solid #000",
          boxShadow: 24,
          borderRadius: 3,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            overflowY: "auto",
            borderRadius: "0 0 3px 3px",
            flex: 1,
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "rgba(0, 0, 0, 0.54)",
            }}
            onClick={handle.handleClose}
          >
            <CloseIcon />
          </IconButton>
          <div className="label">
            <p
              className="text-wrapper"
              style={{
                bgcolor: "#000000",
                fontFamily: "Roboto",
                fontWeight: 600,
                fontSize: 18,
                textAlign: "center",
              }}
            >
              Tambah Data
            </p>
          </div>
          <TextField
            id="outlined-select-komoditas-id"
            label="ID"
            required={true}
            sx={{ width: "94%", mt: 2, ml: "3%" }}
            size="small"
            value={formData.id}
            onChange={(e) => handle.handleInputChange("id", e.target.value)}
          ></TextField>
          <TextField
            id="outlined-select-komoditas-nama"
            label="Nama Komoditas"
            required={true}
            sx={{ width: "94%", mt: 2, ml: "3%" }}
            size="small"
            value={formData.nama}
            onChange={(e) =>
              handle.handleInputChange("nama", e.target.value)
            }
          ></TextField>
          <div style={{ marginLeft: "13px" }}>
            <center>
              <h4>Masukkan Gambar</h4>
            </center>{" "}
            <TextField
              id="outlined-select-image"
              sx={{ width: "94%", mt: 2, ml: "3%" }}
              size="small"
              type="file"
              accept="image/*"
              onChange={(e) =>
                handle.handleImageChange(e.target.files[0])
              }
            ></TextField>
          </div>
        </div>
        <BootstrapButton
          variant="contained"
          disableRipple
          disabled={isLoading}
          onClick={handle.handleCreateSubmit}
          sx={{
            width: "94%",
            ml: "2%",
            mt: 4,
            borderRadius: 2.5,
            backgroundColor: "#32AE2F",
            "&:hover": {
              backgroundColor: "#1d691b",
              boxShadow: "none",
            },
          }}
        >
          {isLoading ? "Loading..." : "SUBMIT"}
        </BootstrapButton>
      </Box>
    </Modal>
  );
}

export default AddDataModal;
