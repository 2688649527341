import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  TextField,
  MenuItem,
  Typography,
  Grid,
  Divider,
  Autocomplete,
} from "@mui/material";
import Cookies from "js-cookie";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";

import {
  generatePreviousFridayDates,
  getFriday,
} from "../../Services/TanggalService";
import { getMasterGudang } from "../../Services/MasterGudangService";

const AddDataModal = ({
  company,
  modalStyle,
  formData,
  isLoading,
  handleClose,
  open,
  handleInputChange,
  handleCreateSubmit,
  access_token,
}) => {
  const previousFridayDates = generatePreviousFridayDates();
  const [masterGudang, setMasterGudang] = useState([]);

  const token = Cookies.get("jwt_token") || "";
  const [fetchedKomoditas, setFetchedKomoditas] = useState([]);

  const createNewRow = () => ({
    masterKomoditasId: "",
    master_sub_komoditas_id: "",
    stok: 0,
    master_gudang_id: formData.master_gudang_id || "",
    tanggal: formData.tanggal || "",
  });
  const [tableData, setTableData] = useState([createNewRow()]);
  const [inputChangeGudang, setInputChangeGudang] = useState({
    company_id: "",
    master_gudang_id: "",
    tanggal: getFriday(),
  });

  const handleTableInputChange = (index, key, value) => {
    const newTableData = [...tableData];
    if (newTableData[index]) {
      newTableData[index][key] = value;
      setTableData(newTableData);
    } else {
      console.error(`Error: tableData[${index}] is undefined.`);
    }
  };

  const handleTableCreateSubmit = async () => {
    const postData = {
      master_gudang_id: formData.master_gudang_id || "",
      tanggal: formData.tanggal || getFriday(),
      data: tableData.map((row) => ({
        master_sub_komoditas_id: row.master_sub_komoditas_id,
        stok: row.stok || 0,
      })),
    };

    try {
      await handleCreateSubmit(postData);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  // Handle Change Gudang (When User Change the Gudang at dropdown)
  const handleInputChangeGudang = async (fieldName, value) => {
    let master_gudang_id = inputChangeGudang.master_gudang_id;
    let tanggal = inputChangeGudang.tanggal;
    if (fieldName == "master_gudang_id") {
      master_gudang_id = value;
    }
    if (fieldName == "tanggal") {
      tanggal = value;
    }

    if (master_gudang_id && tanggal) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/data-stok/multiple?tanggal=${tanggal}&master_gudang_id=${master_gudang_id}`;

      try {
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          const { data: apiData } = responseData;

          const flattenedRows = apiData.data.map((item) => ({
            komoditasId: item.master_komoditas_id,
            komoditasName: item.master_komoditas_nama,
            subKomoditas: [
              {
                id: item.master_sub_komoditas_id,
                nama: item.master_sub_komoditas_nama,
                stok: item.stok,
              },
            ],
          }));

          setFetchedKomoditas(flattenedRows);
        } else {
          console.error("Failed to fetch:", response.status);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      setFetchedKomoditas([]);
    }
  };

  useEffect(() => {
    if (fetchedKomoditas.length > 0) {
      const newTableData = fetchedKomoditas.flatMap((komoditas) => {
        return komoditas.subKomoditas.map((subKomoditas) => {
          return {
            masterKomoditasId: komoditas.komoditasId,
            master_sub_komoditas_id: subKomoditas.id,
            stok: subKomoditas.stok,
            master_gudang_id: formData.master_gudang_id || "",
            tanggal: formData.tanggal || getFriday(),
            subKomoditasName: subKomoditas.nama,
          };
        });
      });

      setTableData(newTableData);
    }
  }, [fetchedKomoditas, formData.master_gudang_id, formData.tanggal]);

  useEffect(() => {
    if (!open) {
      setTableData([]);
      setFetchedKomoditas([]);
      handleInputChange("master_gudang_id", "");
      handleInputChange("tanggal", "");
    }
  }, [open]);

  // Get Gudang Capacity
  const showTotalCapacity = () => {
    const selectedGudang = masterGudang.find(
      (gudang) => gudang.id === formData.master_gudang_id
    );

    return selectedGudang ? selectedGudang.kapasitas : 0;
  };
  // Get Remaining Capacity After Fill the form
  const calculateRemainingCapacity = () => {
    let totalStok = 0;

    for (const rowData of tableData) {
      totalStok += rowData.stok ? parseFloat(rowData.stok) : 0;
    }

    const selectedGudang = masterGudang.find(
      (gudang) => gudang.id === formData.master_gudang_id
    );
    const remainingCapacity = selectedGudang
      ? selectedGudang.kapasitas - totalStok
      : 0;

    const remainingCapacityWithThreeDecimals = parseFloat(
      remainingCapacity.toFixed(3)
    );

    return remainingCapacityWithThreeDecimals;
  };

  // Get Total Capacity Filled in Form
  const calculateTotalFilled = () => {
    let totalFilled = 0;

    for (const rowData of tableData) {
      totalFilled += rowData.stok ? parseFloat(rowData.stok) : 0;
    }

    const totalFilledWithThreeDecimals = parseFloat(totalFilled.toFixed(3));

    return totalFilledWithThreeDecimals;
  };

  const [isEditable, setIsEditable] = useState(false);
  const isEditableDay = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/config/day`;

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        const { data: configData } = responseData;

        const today = new Date();
        const dayOfWeek = today.getDay();
        const currentDayConfig = configData.find((day) => day.id === dayOfWeek);

        return currentDayConfig && currentDayConfig.is_able_to_edit;
      } else {
        console.error("Failed to fetch day configuration:", response.status);
        return false;
      }
    } catch (error) {
      console.error("Error fetching day configuration:", error);
      return false;
    }
  };
  useEffect(() => {
    const fetchEditableStatus = async () => {
      const status = await isEditableDay();
      setIsEditable(status);
    };

    fetchEditableStatus();
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ border: 0 }}
    >
      <Box
        sx={{
          ...modalStyle,
          width: 1000,
          height: "auto",
          maxHeight: 600,
          overflowY: "auto",
          border: "0px solid #000",
          boxShadow: 24,
          borderRadius: 3,
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          style={{ position: "absolute", top: 0, right: 15 }}
        >
          <CloseIcon />
        </IconButton>
        <div
          className="text-wrapper"
          style={{
            bgcolor: "#000000",
            fontFamily: "Roboto",
            fontWeight: 600,
            fontSize: 18,
            textAlign: "center",
          }}
        >
          Tambah Laporan
        </div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              id="outlined-select-company"
              options={company}
              getOptionLabel={(option) => option.label || ""}
              renderOption={(props, option) => (
                <li {...props}>{option.label}</li>
              )}
              value={
                company.find((c) => c.id === inputChangeGudang.company_id) ||
                null
              }
              onChange={(event, newValue) => {
                const selectedValue = newValue ? newValue.id : "";
                setInputChangeGudang({
                  ...inputChangeGudang,
                  company_id: selectedValue,
                  master_gudang_id: "",
                });
                getMasterGudang({
                  access_token: access_token,
                  setData: setMasterGudang,
                  company_id: selectedValue,
                });
                handleInputChangeGudang("master_gudang_id", "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nama Perusahaan"
                  variant="outlined"
                  size="small"
                  sx={{ width: "100%", mt: 2 }}
                  disabled={!isEditable}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="outlined-select-gudang"
              options={masterGudang}
              getOptionLabel={(option) => option.nama_gudang || ""}
              renderOption={(props, option) => (
                <li {...props}>
                  {option.nama_gudang}
                  <hr />
                  <Typography variant="caption">
                    PROV {option.province?.name} | KOTA/KAB{" "}
                    {option.regency?.name} <br />
                    KEC {option.district?.name} | KEL{" "}
                    {option.sub_district?.name}
                  </Typography>
                </li>
              )}
              value={
                masterGudang.find((g) => g.id === formData.master_gudang_id) ||
                null
              }
              onChange={(event, newValue) => {
                const selectedValue = newValue ? newValue.id : "";
                setInputChangeGudang({
                  ...inputChangeGudang,
                  master_gudang_id: selectedValue,
                });
                handleInputChangeGudang("master_gudang_id", selectedValue);
                handleInputChange("master_gudang_id", selectedValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nama Gudang"
                  variant="outlined"
                  size="small"
                  sx={{ width: "100%", mt: 2 }}
                  disabled={!isEditable}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-select-currency"
              label="Tanggal"
              select
              sx={{ width: "100%", mt: 2 }}
              size="small"
              value={formData.tanggal || getFriday()}
              onChange={(e) => {
                setInputChangeGudang({
                  ...inputChangeGudang,
                  tanggal: e.target.value,
                });
                handleInputChangeGudang("tanggal", e.target.value);
                handleInputChange("tanggal", e.target.value);
              }}
            >
              {previousFridayDates.map((date) => (
                <MenuItem key={date} value={date}>
                  {new Date(date).toLocaleDateString("id-ID", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        {formData.master_gudang_id ? (
          <div style={{ marginTop: "20px" }}>
            <div style={{ marginTop: "20px" }}>
              <table style={{ width: "100%" }}>
                <thead style={{ backgroundColor: "#32AE2F" }}>
                  <tr>
                    <th style={{ width: "20%", color: "white" }}>Komoditas</th>
                    <th style={{ width: "20%", color: "white" }}>
                      Sub Komoditas
                    </th>
                    <th style={{ width: "10%", color: "white" }}>Stok</th>
                  </tr>
                </thead>
                <tbody>
                  {fetchedKomoditas.map((komoditas, index) =>
                    komoditas.subKomoditas.map((subKomoditas, subIndex) => (
                      <tr key={`${index}-${subIndex}`}>
                        <td>
                          <Typography variant="body1">
                            {komoditas.komoditasName}
                          </Typography>
                        </td>
                        <td>
                          <Typography variant="body1">
                            {subKomoditas.nama}
                          </Typography>
                        </td>
                        <td>
                          <TextField
                            id={`stok-${index}-${subIndex}`}
                            fullWidth
                            size="small"
                            type="number"
                            value={tableData[index]?.stok}
                            onChange={(e) => {
                              handleTableInputChange(
                                index,
                                "stok",
                                e.target.value
                              );
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <Typography
            variant="body1"
            style={{ marginTop: "20px", textAlign: "center", color: "red" }}
          >
            Pilih Perusahaan kemudian gudang terlebih dahulu
          </Typography>
        )}

        <Typography
          variant="body1"
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
          component={"div"}
        >
          <div>Kapasitas gudang: {showTotalCapacity()} Ton</div>
          <div
            style={{
              color: calculateRemainingCapacity() < 0 ? "red" : "inherit",
            }}
          >
            Sisa: {calculateRemainingCapacity()} Ton
          </div>
          <div>Terisi: {calculateTotalFilled()} Ton</div>
        </Typography>

        <BootstrapButton
          variant="contained"
          disableRipple
          onClick={handleTableCreateSubmit}
          disabled={!isEditable}
          sx={{
            width: "20%",
            ml: "80%",
            mt: 4,
            borderRadius: 2.5,
            backgroundColor: "#32AE2F",
            "&:hover": {
              backgroundColor: "#1d691b",
              boxShadow: "none",
            },
          }}
        >
          {isLoading ? "Loading..." : "SUBMIT"}
        </BootstrapButton>
      </Box>
    </Modal>
  );
};

AddDataModal.propTypes = {
  access_token: PropTypes.string.isRequired,
  modalStyle: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleCreateSubmit: PropTypes.func.isRequired,
};

export default AddDataModal;
