import axios from "axios";

  export const fetchDashboardDate = async (
    token,
    setDataDashboardDate,
    masterKomoditasId
  ) => {
    const queryParams = new URLSearchParams();

    if (masterKomoditasId && masterKomoditasId !==  "") {
      queryParams.append("master_komoditas_id", masterKomoditasId);
    }

    const queryString = queryParams.toString();
    const apiUrl =     `${process.env.REACT_APP_API_URL}/api/v1/dashboard/date` +
    (queryString ? `?${queryString}` : "");

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const dataKomoditas = response.data?.data;
      setDataDashboardDate(dataKomoditas);
    } catch (e) {
      setDataDashboardDate([]);
    }
  };

export const fetchCompany = async (
  token,
  setDataCompany,
  selectedProvince,
  selectedMasterPelakuUsaha
) => {
  const queryParams = new URLSearchParams();

  if (selectedProvince && selectedProvince !== "") {
    queryParams.append("province_id", selectedProvince);
  }

  if (selectedMasterPelakuUsaha && selectedMasterPelakuUsaha !== "") {
    queryParams.append("master_pelaku_usaha_id", selectedMasterPelakuUsaha);
  }

  const queryString = queryParams.toString();
  const apiUrl =
    `${process.env.REACT_APP_API_URL}/api/v1/company` +
    (queryString ? `?${queryString}` : "");

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      const data = response?.data?.data;
      setDataCompany(data);
    } else {
      setDataCompany([]);
    }
  } catch (e) {
    setDataCompany([]);
  }
};

export const fetchMasterGudang = async (
  token,
  setDataMasterGudang,
  selectedProvince,
  selectedTipeGudang,
  selectedCompany
) => {
  const queryParams = new URLSearchParams();

  if (selectedProvince && selectedProvince !== "") {
    queryParams.append("province_id", selectedProvince);
  }

  if (selectedTipeGudang && selectedTipeGudang !== "") {
    queryParams.append("tipe_gudang", selectedTipeGudang);
  }

  if (selectedCompany && selectedCompany !== "") {
    queryParams.append("company_id", selectedCompany);
  }

  const queryString = queryParams.toString();
  const apiUrl =
    `${process.env.REACT_APP_API_URL}/api/v1/master-gudang` +
    (queryString ? `?${queryString}` : "");

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      const data = response?.data?.data;
      setDataMasterGudang(data);
    } else {
      setDataMasterGudang([]);
    }
  } catch (e) {
    setDataMasterGudang([]);
  }
};

export const fetchMasterKomoditas = async (token, setDataMasterKomoditas) => {
  const queryParams = new URLSearchParams();

  const queryString = queryParams.toString();
  const apiUrl =
    `${process.env.REACT_APP_API_URL}/api/v1/komoditas` +
    (queryString ? `?${queryString}` : "");

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response?.data?.data;
    setDataMasterKomoditas(data);
  } catch (e) {
    setDataMasterKomoditas([]);
  }
};

export const fetchMasterKomoditasInside = async (
  token,
  setDataMasterKomoditasInside,
  selectedDate,
) => {
  const queryParams = new URLSearchParams();

  if (selectedDate && selectedDate !== "") {
    queryParams.append("tanggal", selectedDate);
  }

  const queryString = queryParams.toString();
  const apiUrl =     `${process.env.REACT_APP_API_URL}/api/v1/dashboard/master-komoditas` +
  (queryString ? `?${queryString}` : "");

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const dataKomoditas = response.data?.data;
    setDataMasterKomoditasInside(dataKomoditas);
  } catch (e) {
    setDataMasterKomoditasInside([]);
  }
};

export const fetchMasterKomoditasById = async (
  token,
  id,
  setDataMasterKomoditas
) => {
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/komoditas/${id}`;

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response?.data?.data;
    setDataMasterKomoditas(data);
  } catch (e) {
    setDataMasterKomoditas([]);
  }
};

export const fetchWeeklyDashboard = async (
  token,
  masterKomoditasId,
  year,
  firstMonth,
  secondMonth,
  selectedMasterGudang,
  selectedCompany,
  selectedTipeGudang,
  selectedProvince,
  selectedRegency,
  selectedMasterPelakuUsaha
) => {
  const queryParams = new URLSearchParams();

  queryParams.append("year", year);
  queryParams.append("first_month", firstMonth);
  queryParams.append("second_month", secondMonth);

  if (selectedMasterGudang && selectedMasterGudang !== "") {
    queryParams.append("master_gudang_id", selectedMasterGudang);
  }

  if (selectedCompany && selectedCompany !== "") {
    queryParams.append("company_id", selectedCompany);
  }

  if (selectedTipeGudang && selectedTipeGudang !== "") {
    queryParams.append("tipe_gudang", selectedTipeGudang);
  }

  if (selectedProvince && selectedProvince !== "") {
    queryParams.append("province_id", selectedProvince);
  }

  if (selectedRegency && selectedRegency !== "") {
    queryParams.append("regency_id", selectedRegency);
  }

  if (selectedMasterPelakuUsaha && selectedMasterPelakuUsaha !== "") {
    queryParams.append("master_pelaku_usaha_id", selectedMasterPelakuUsaha);
  }

  const queryString = queryParams.toString();
  const apiUrl =
    `${process.env.REACT_APP_API_URL}/api/v1/dashboard/master-komoditas/${masterKomoditasId}/weekly` +
    (queryString ? `?${queryString}` : "");

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      let { data } = await response.json();

      if (data.length > 0 && data[data.length - 1].total_stok === null) {
        data.pop();
      }
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchMonthlyDashboard = async (
  token,
  masterKomoditasId,
  year,
  selectedMasterGudang,
  selectedCompany,
  selectedTipeGudang,
  selectedProvince,
  selectedRegency,
  selectedMasterPelakuUsaha,
  viewMode
) => {
  const queryParams = new URLSearchParams();

  queryParams.append("year", year);

  if (selectedMasterGudang && selectedMasterGudang !== "") {
    queryParams.append("master_gudang_id", selectedMasterGudang);
  }

  if (selectedCompany && selectedCompany !== "") {
    queryParams.append("company_id", selectedCompany);
  }

  if (selectedTipeGudang && selectedTipeGudang !== "") {
    queryParams.append("tipe_gudang", selectedTipeGudang);
  }

  if (selectedProvince && selectedProvince !== "") {
    queryParams.append("province_id", selectedProvince);
  }

  if (selectedRegency && selectedRegency !== "") {
    queryParams.append("regency_id", selectedRegency);
  }

  if (selectedMasterPelakuUsaha && selectedMasterPelakuUsaha !== "") {
    queryParams.append("master_pelaku_usaha_id", selectedMasterPelakuUsaha);
  }

  if (viewMode && viewMode !== "") {
    queryParams.append("view_mode", viewMode);
  }

  const queryString = queryParams.toString();
  const apiUrl =
    `${process.env.REACT_APP_API_URL}/api/v1/dashboard/master-komoditas/${masterKomoditasId}/monthly` +
    (queryString ? `?${queryString}` : "");

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const { data } = await response.json();
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchSingleMonthData = async (
  token,
  masterKomoditasId,
  year,
  month,
  selectedMasterGudang,
  selectedCompany,
  selectedTipeGudang,
  selectedProvince,
  selectedRegency,
  selectedMasterPelakuUsaha,
  viewMode
) => {
  const queryParams = new URLSearchParams();

  queryParams.append("year", year);
  queryParams.append("month", month);

  if (selectedMasterGudang && selectedMasterGudang !== "") {
    queryParams.append("master_gudang_id", selectedMasterGudang);
  }

  if (selectedCompany && selectedCompany !== "") {
    queryParams.append("company_id", selectedCompany);
  }

  if (selectedTipeGudang && selectedTipeGudang !== "") {
    queryParams.append("tipe_gudang", selectedTipeGudang);
  }

  if (selectedProvince && selectedProvince !== "") {
    queryParams.append("province_id", selectedProvince);
  }

  if (selectedRegency && selectedRegency !== "") {
    queryParams.append("regency_id", selectedRegency);
  }

  if (selectedMasterPelakuUsaha && selectedMasterPelakuUsaha !== "") {
    queryParams.append("master_pelaku_usaha_id", selectedMasterPelakuUsaha);
  }

  if (viewMode && viewMode !== "") {
    queryParams.append("view_mode", viewMode);
  }

  const queryString = queryParams.toString();
  const apiUrl =
    `${process.env.REACT_APP_API_URL}/api/v1/dashboard/master-komoditas/${masterKomoditasId}/month` +
    (queryString ? `?${queryString}` : "");

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const { data } = await response.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const fetchSingleWeekData = async (
  token,
  masterKomoditasId,
  selectedDate,
  selectedMasterGudang,
  selectedCompany,
  selectedTipeGudang,
  selectedProvince,
  selectedRegency,
  selectedMasterPelakuUsaha,
  viewMode
) => {
  const queryParams = new URLSearchParams();

  if (selectedDate && selectedDate !== "") {
    queryParams.append("tanggal", selectedDate);
  }

  if (selectedMasterGudang && selectedMasterGudang !== "") {
    queryParams.append("master_gudang_id", selectedMasterGudang);
  }

  if (selectedCompany && selectedCompany !== "") {
    queryParams.append("company_id", selectedCompany);
  }

  if (selectedTipeGudang && selectedTipeGudang !== "") {
    queryParams.append("tipe_gudang", selectedTipeGudang);
  }

  if (selectedProvince && selectedProvince !== "") {
    queryParams.append("province_id", selectedProvince);
  }

  if (selectedRegency && selectedRegency !== "") {
    queryParams.append("regency_id", selectedRegency);
  }

  if (selectedMasterPelakuUsaha && selectedMasterPelakuUsaha !== "") {
    queryParams.append("master_pelaku_usaha_id", selectedMasterPelakuUsaha);
  }

  if (viewMode && viewMode !== "") {
    queryParams.append("view_mode", viewMode);
  }

  const queryString = queryParams.toString();
  const apiUrl =
    `${process.env.REACT_APP_API_URL}/api/v1/dashboard/master-komoditas/${masterKomoditasId}/week` +
      (queryString ? `?${queryString}` : "");

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const { data } = await response.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error(error);
    return {};
  }
};
