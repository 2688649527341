export const isProduction = process.env.NODE_ENV === 'production';
export const successComponent = (message) => <b>{message}</b>;
export const errorComponent = (error) => {
  const detail = error.response?.data?.detail;
  if (Array.isArray(detail)) {
    const concatenatedMsg = detail?.map((item) => item.msg).join(', ');
    return (
      <b>
        {concatenatedMsg}
      </b>
    );
  } else {
    return <b>{detail ?? 'Something went wrong'}</b>;
  }
};
