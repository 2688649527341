import React, { useEffect, useState } from "react";
import { Box, TextField, MenuItem, CssBaseline, Toolbar } from "@mui/material";
import Sidebar from "../../Components/Sidebar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getAccessToken } from "../../Services/AuthService";
import SearchBar from "../../Components/SearchBar";
import MapLayout from "../../Components/MapLayout";
import { FormControl, InputLabel, Select } from "@mui/material";
import { getMasterGudangMap } from "../../Services/MasterGudangService";
import { Icon } from "leaflet";
import { fetchProvince } from "../../Services/Hook/AreaService";
import { getCompany } from "../../Services/CompanyService";
import { generatePreviousFridayDates } from "../../Services/TanggalService";
import { fetchDashboardDate } from "../Dashboard/FilterService";
import { getMasterKomoditasPromise } from "../../Services/KomoditasService";
import axios from "axios";
import { format } from "date-fns";
import { id } from "date-fns/locale";

import L from "leaflet";
import ProvinsiData from "../../assets/data/IDN.json";
import * as turf from "@turf/turf";

const defaultTheme = createTheme();

function MapArea() {
  const accessToken = getAccessToken();
  const [mapPosition, setMapPosition] = useState([-2.548, 117.0]);
  const [mapZoom, setMapZoom] = useState(5);
  const [filteredGeoJSONData, setFilteredGeoJSONData] = useState(ProvinsiData);

  const MapIcon = (data) => {
    let iconUrl = "";

    const hasStock = data.master_komoditas.some(
      (komoditas) => komoditas.stok > 0
    );

    if (hasStock) {
      iconUrl =
        "https://img.icons8.com/?width=20&size=50&id=59830&format=png&color=228BE6CC";
    } else {
      iconUrl =
        "https://img.icons8.com/?width=20&size=50&id=59830&format=png&color=FA5252CC";
    }

    const icon = L.icon({
      iconUrl: iconUrl,
      iconSize: [20, 20],
      className: "custom-icon",
    });

    return icon;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [dataMasterGudang, setDataMasterGudang] = useState([]);
  const [dataMasterKomoditas, setDataMasterKomoditas] = useState([]);
  const [dataProvince, setDataProvince] = useState([]);
  const [dataCompany, setDataCompany] = useState([]);
  const [dataPelakuUsaha, setDataPelakuUsaha] = useState([]);
  const dataTanggal = generatePreviousFridayDates();
  const [dataDashboardDate, setDataDashboardDate] = useState([]);

  const [selectedProvinceId, setSelectedProvinceId] = useState("");
  const [selectedProvinceLabel, setSelectedProvinceLabel] = useState("");
  const [selectedPelakuUsahaId, setSelectedPelakuUsahaId] = useState("");
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedMasterKomoditasId, setSelectedMasterKomoditasId] = useState(
    []
  );

  const fetchMasterGudang = async (
    selectedPelakuUsahaId,
    selectedProvinceId,
    selectedDate,
    selectedCompanyId
  ) => {
    setIsLoading(true);
    try {
      await getMasterGudangMap({
        access_token: accessToken,
        setData: setDataMasterGudang,
        master_pelaku_usaha_id: selectedPelakuUsahaId,
        province_id: selectedProvinceId,
        date: selectedDate,
        company_id: selectedCompanyId,
        master_komoditas_id: selectedMasterKomoditasId,
      });
    } catch (error) {
      console.error("Failed to fetch master gudang");
    }
    setIsLoading(false);
  };

  const fetchPelakuUsaha = async () => {
    const queryParams = new URLSearchParams();

    // if (company_agency) {
    //   queryParams.append('company_agency', company_agency);
    // }

    // if (is_dashboard) {
    //   queryParams.append('is_dashboard', is_dashboard);
    // }

    const queryString = queryParams.toString();
    const apiUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/master_pelaku_usaha` +
      (queryString ? `?${queryString}` : "");

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      let data = response?.data?.data;
      setDataPelakuUsaha(data);
    } catch (e) {
      return [];
    }
  };

  const fetchCompany = async () => {
    setIsLoading(true);
    try {
      await getCompany(
        accessToken,
        setDataCompany,
        null,
        true,
        selectedPelakuUsahaId,
        selectedProvinceId
      );
    } catch (error) {
      console.error("Failed to fetch company");
    }
    setIsLoading(false);
  };

  const fetchMasterKomoditas = async () => {
    try {
      await getMasterKomoditasPromise(
        accessToken,
        setDataMasterKomoditas,
        true
      );
    } catch (error) {
      console.error("Failed to fetch master komoditas");
    }
  };

  const fetchAreaProvince = async () => {
    setIsLoading(true);
    try {
      await fetchProvince(accessToken, setDataProvince, true);
    } catch (error) {
      console.error("Failed to fetch province");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAreaProvince();
    fetchMasterKomoditas();
  }, [accessToken]);

  useEffect(() => {
    fetchDashboardDate(accessToken, (data) => {
      setDataDashboardDate(data);
      if (data && data.length > 0) {
        setSelectedDate(data[0].tanggal);
      }
    });
  }, [accessToken]);

  useEffect(() => {
    if (selectedDate) {
      fetchMasterGudang(
        selectedPelakuUsahaId,
        selectedProvinceId,
        selectedDate,
        selectedCompanyId,
        selectedMasterKomoditasId
      );
    }
  }, [
    accessToken,
    selectedPelakuUsahaId,
    selectedProvinceId,
    selectedDate,
    selectedCompanyId,
    selectedMasterKomoditasId,
    selectedMasterKomoditasId,
  ]);

  useEffect(() => {
    fetchPelakuUsaha();
  }, [accessToken]);

  useEffect(() => {
    fetchCompany();
  }, [accessToken, selectedProvinceId, selectedPelakuUsahaId]);

  const handleProvinceChange = (event, newValue) => {
    let id = "";
    let label = "";
    if (newValue) {
      id = newValue.id;
      label = newValue.label;
    }
    setSelectedProvinceId(id);
    setSelectedProvinceLabel(label);

    if (!id) {
      setFilteredGeoJSONData(ProvinsiData);
      setMapPosition([-2.548, 117.0]);
      setMapZoom(5);
    } else {
      const filteredData = {
        type: "FeatureCollection",
        features: ProvinsiData.features.filter(
          (feature) => feature.properties.prov_id.toString() === id
        ),
      };
      setFilteredGeoJSONData(filteredData);

      const bbox = turf.bbox(filteredData);
      const center = [(bbox[1] + bbox[3]) / 2, (bbox[0] + bbox[2]) / 2];

      const bboxWidth = bbox[2] - bbox[0];
      const bboxHeight = bbox[3] - bbox[1];
      const maxDim = Math.max(bboxWidth, bboxHeight);
      const zoom = Math.min(Math.max(9 - Math.log2(maxDim), 5), 10);

      setMapPosition(center);
      setMapZoom(zoom);
    }
  };

  const handleCompanyChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedCompanyId(id);
  };

  const handleMasterKomoditasChange = (newValue) => {
    setSelectedMasterKomoditasId(newValue);
  };

  const mapData = dataMasterGudang.map((item) => ({
    id: item.id,
    nama_gudang: item.nama_gudang,
    tipe_gudang: item.tipe_gudang,
    pelaku_usaha: item.pelaku_usaha,
    tipe_toko: item.store_type,
    instansi: item.perusahaan.instansi,
    nama_pengelola: item.perusahaan.nama_pengelola,
    kapasitas: item.kapasitas,
    latitude: item.latitude,
    longitude: item.longitude,
    address: item.address,
    province: item.province.name,
    regency: item.regency.name,
    district: item.district.name,
    sub_district: item.sub_district.name,
    link_map: item.link_map,
    thumbnail_url: item.thumbnail_url,
    audit_status: item.audit_status,
    master_komoditas: item.master_komoditas,
    icon: MapIcon(item),
  }));

  const detailMasterGudang = (data) => {
    const masterKomoditasAvailable = data.master_komoditas;
    return (
      <article>
        <header>
          <h4>{data.nama_gudang}</h4>
        </header>
        <table>
          <thead>
            <tr>
              <td>tipe</td>
              <td>:</td>
              <td>{data.tipe_gudang}</td>
            </tr>
            <tr>
              <td>kapasitas</td>
              <td>:</td>
              <td>
                {data.kapasitas.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 3,
                })}{" "}
                Ton{" "}
              </td>
            </tr>
            <tr>
              <td>provinsi</td>
              <td>:</td>
              <td>{data.province}</td>
            </tr>
            <tr>
              <td>kota/kabupaten</td>
              <td>:</td>
              <td>{data.regency}</td>
            </tr>
            <tr>
              <td>kecamatan</td>
              <td>:</td>
              <td>{data.district}</td>
            </tr>
            <tr>
              <td>kelurahan</td>
              <td>:</td>
              <td>{data.sub_district}</td>
            </tr>
            <tr>
              <td>alamat</td>
              <td>:</td>
              <td>{data.address} <a target="_blank" href={`https://maps.google.com/?q=${data.latitude},${data.longitude}`}>(kunjungi)</a></td>
            </tr>
            <tr>
              <td>pelaku usaha</td>
              <td>:</td>
              <td>{data.pelaku_usaha?.name || "N/A"}</td>
            </tr>
            <tr>
              <td>instansi</td>
              <td>:</td>
              <td>{data.instansi}</td>
            </tr>
            <tr>
              <td>perusahaan</td>
              <td>:</td>
              <td>{data.nama_pengelola}</td>
            </tr>
            <tr>
              <td>komoditas</td>
              <td>:</td>
              <td>
                <ul>
                  {masterKomoditasAvailable?.map((data) => {
                    return (
                      <li key={data.id}>
                        {data.nama} :{" "}
                        {data.stok.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 3,
                        })}{" "}
                        Ton{" "}
                      </li>
                    );
                  })}
                </ul>
              </td>
            </tr>
          </thead>
        </table>
      </article>
    );
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Sidebar />
        <Box
          component="main"
          sx={{
            ml: "-4%",
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <div style={{ padding: "25px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", gap: "10px" }}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="label-select-date">Tanggal</InputLabel>
                    <Select
                      labelId="label-select-date"
                      id="select-date"
                      value={
                        selectedDate ||
                        (dataDashboardDate[0] &&
                          dataDashboardDate[0].tanggal) ||
                        ""
                      }
                      defaultValue={selectedDate}
                      label="Tanggal"
                      name="date"
                      onChange={(e) => setSelectedDate(e.target.value)}
                      style={{
                        height: "40px",
                      }}
                    >
                      {/* <MenuItem value="">
                        <em>None</em>
                      </MenuItem> */}
                      {dataDashboardDate?.map((item) => (
                        <MenuItem key={item.tanggal} value={item.tanggal}>
                          {format(new Date(item.tanggal), "eeee, dd MMM yyyy", {
                            locale: id,
                          })}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <SearchBar
                  id={"province-autocomplete"}
                  data={dataProvince}
                  value={
                    dataProvince.find(
                      (option) => option.id === selectedProvinceId
                    ) ?? null
                  }
                  handleChange={handleProvinceChange}
                  label={"Provinsi"}
                />

                <Box sx={{ minWidth: 200 }}>
                  <FormControl fullWidth sx={{ size: "small" }}>
                    <InputLabel
                      id="label-select-pelaku-usaha"
                      sx={{ marginTop: "-5px" }}
                    >
                      Pelaku Usaha
                    </InputLabel>
                    <Select
                      labelId="label-select-pelaku-usaha"
                      id="select-pelaku-usaha"
                      value={selectedPelakuUsahaId}
                      defaultValue={""}
                      label="Pelaku Usaha"
                      name="Pelaku Usaha"
                      onChange={(e) => setSelectedPelakuUsahaId(e.target.value)}
                      sx={{ height: "40px" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {dataPelakuUsaha?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <SearchBar
                  id={"company-autocomplete"}
                  data={dataCompany}
                  value={
                    dataCompany.find(
                      (option) => option.id === selectedCompanyId
                    ) ?? null
                  }
                  handleChange={handleCompanyChange}
                  label={"Perusahaan"}
                />

                <TextField
                  id="outlined-select-master-komoditas-id"
                  label="Komoditas"
                  sx={{
                    width: 200,
                    height: 40,
                  }}
                  size="small"
                  select
                  SelectProps={{
                    multiple: true,
                    value: selectedMasterKomoditasId,
                    onChange: (event) =>
                      handleMasterKomoditasChange(event.target.value),
                  }}
                >
                  {dataMasterKomoditas
                    ?.sort((a, b) => a.label.localeCompare(b.label))
                    .map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              </div>
            </div>
            {isLoading ? (
              <div>
                <center style={{ marginTop: "30vh" }}>Loading...</center>
              </div>
            ) : selectedDate ? (
              <MapLayout
                markersData={{
                  data: mapData,
                  icon: <MapIcon style={{ width: "10px", height: "10px" }} />,
                }}
                position={mapPosition}
                zoom={mapZoom}
                scrollWheelZoom={true}
                styleContainer={{ width: "95vw", height: "700px" }}
                renderDataDetail={detailMasterGudang}
                geoJSONData={filteredGeoJSONData}
                pathOptions={{ color: "black" }}
              />
            ) : (
              <MapLayout
                markersData={{
                  data: mapData,
                  icon: <MapIcon style={{ width: "10px", height: "10px" }} />,
                }}
                position={mapPosition}
                zoom={mapZoom}
                scrollWheelZoom={true}
                styleContainer={{ width: "95vw", height: "700px" }}
                renderDataDetail={detailMasterGudang}
                geoJSONData={filteredGeoJSONData}
                pathOptions={{ color: "black" }}
              />
            )}
          </div>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default MapArea;
