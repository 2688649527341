import { format, addDays, startOfWeek } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const generatePreviousFridayDates = (first_month, last_month, year) => {
  const today = new Date();
  const previousFridayDates = [];
  const timeZone = 'Asia/Jakarta';
  const todayInJakarta = utcToZonedTime(today, timeZone);

  let friday = startOfWeek(todayInJakarta, { weekStartsOn: 5 });
  let iterationCount = 0;
  const maxIterations = 1000;  // To prevent infinite loop

  while (previousFridayDates.length < 100 && iterationCount < maxIterations) {
    const formattedDate = format(friday, 'yyyy-MM-dd', { timeZone });
    const date = new Date(formattedDate);

    let withinYear = true;
    let withinMonthRange = true;

    if (year !== undefined) {
      withinYear = (date.getFullYear() === year);
    }

    if (first_month !== undefined && last_month !== undefined) {
      withinMonthRange = (date.getMonth() + 1 >= first_month && date.getMonth() + 1 <= last_month);
    }

    if (withinYear && withinMonthRange) {
      previousFridayDates.push(formattedDate);
    }

    friday = addDays(friday, -7);
    iterationCount++;
  }

  return previousFridayDates.sort((a, b) => new Date(b) - new Date(a));
};

export const getLastFriday = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const difference = (dayOfWeek + 7 - 5) % 7;
  const lastFriday = new Date(today);
  lastFriday.setDate(today.getDate() - difference);
  return lastFriday;
};

export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  return `${year}-${month}-${day}`;
};


export const getFriday = () => {
  const today = new Date();
  const currentDayOfWeek = today.getDay();

  const daysUntilFriday = (currentDayOfWeek + 7 - 5) % 7;

  const millisecondsInADay = 24 * 60 * 60 * 1000;
  const previousFriday = new Date(today - daysUntilFriday * millisecondsInADay);

  return previousFriday.toISOString().split('T')[0];
};
