import { React, useEffect, useState } from "react";
import { Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { toast } from "react-hot-toast";

// external
import Sidebar from "../../Components/SidebarTwo";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getAccessToken, profilePromise } from '../../Services/AuthService';
import AddDataModal from "./AddDataModal";
import EditDataModal from "./EditDataModal";
import DeleteDataModal from "./DeleteDataModal";
import TableMasterPelakuUsaha from "./Table";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getAllMasterPelakuUsahaCustom } from "../../Services/MasterPelakuUsahaService";
import { dataCompanyAgency } from "../../constant";
import SearchBar from "../../Components/SearchBar";

const defaultTheme = createTheme();

function MasterPelakuUsaha() {
  const location = useLocation();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  // token
  const accessToken = getAccessToken();
  const [isLoading, setIsLoading] = useState(false);
  const [dataProfile, setDataProfile] = useState(null);
  const [dataMasterPelakuUsaha, setDataMasterPelakuUsaha] = useState([]);
  const [totalDataMasterPelakuUsaha, setTotalDataMasterPelakuUsaha] = useState(0);

  // filter
  const [selectedCompanyAgency, setSelectedCompanyAgency] = useState(String(searchParams.get('company_agency')) || "");

  // table
  // pagination
  const currentPage = Number(searchParams.get('page')) || 1;
  let pageSize = Number(searchParams.get('size')) || 20;
  const itemsPerPageList = [5, 10, 20, 50];
  const lastPageSize = itemsPerPageList[itemsPerPageList.length - 1];
  pageSize = itemsPerPageList.includes(pageSize) ? pageSize : lastPageSize;
  // sort
  const SortingStates = {
    ASC: 'asc',
    DESC: 'desc',
  };
  const [sorting, setSorting] = useState({ column: searchParams.get('sort_by') || 'company_agency', order: searchParams.get('sort_order') || SortingStates.ASC });

  // profile
  const fetchProfile = async (accessToken) => {
    try {
      const profile = await profilePromise(accessToken);
      setDataProfile(profile);
    } catch (error) {
      console.error("Failed to fetch profile")
    }
  };

  // data
  const fetchMasterPelakuUsaha = async (accessToken, customParams) => {
    setIsLoading(true)
    try {
      const data = await getAllMasterPelakuUsahaCustom(accessToken, customParams);
      setDataMasterPelakuUsaha(data.data);
      setTotalDataMasterPelakuUsaha(data.pagination_info.total)
    } catch (error) {
      console.error("Failed to fetch master master_pelaku_usaha")
    }
    setIsLoading(false)
  };

  useEffect(() => {
    fetchProfile(accessToken);
    fetchMasterPelakuUsaha(accessToken, {
      page: currentPage,
      size: pageSize,
      sort_by: sorting.column,
      sort_order: sorting.order
    });
  }, [accessToken])


  // handling modal
  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  // create data
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    company_agency: "",
  });

  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleCreateSubmit = async () => {
    setIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/master_pelaku_usaha`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Data berhasil dibuat!");
        await fetchMasterPelakuUsaha(accessToken, {
          page: currentPage,
          size: pageSize,
          sort_by: sorting.column,
          sort_order: sorting.order
        });
        handleCloseAdd();
        setFormData({
          ...formData,
          id: '',
          name: '',
          company_agency: '',
        });
      } else if (response.status === 422) {
        toast.error("Ada kesalahan saat mengisi form");
      } else if (response.status === 400 || response.status === 404) {
        const data = await response.json();
        toast.error(data.detail || data.data.message)
      } else {
        console.error("Failed to submit data");
        toast.error("Ada kesalahan saat mengisi form");
      }
    } catch (error) {
      console.error("Error during create data submission", error);
    }
    setIsLoading(false);
  };

  // delete table
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const openDeleteConfirmationModal = (itemId) => {
    setItemToDelete(itemId);
    setDeleteModalOpen(true);
  };

  const handleCloseDelete = () => {
    setItemToDelete(null);
    setDeleteModalOpen(false);
  };

  const handleDeleteItem = async (itemId) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/master_pelaku_usaha/${itemId}`;

    try {
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        toast.success("Data berhasil di hapus!");
        await fetchMasterPelakuUsaha(accessToken, {
          page: currentPage,
          size: pageSize,
          sort_by: sorting.column,
          sort_order: sorting.order
        });
      } else {
        toast.error("Data gagal dihapus!");
        console.error("Failed to delete item");
      }
    } catch (error) {
      console.error("Error during item deletion", error);
    }
  };

  // edit function
  const [editModalOpen, setEditModalOpen] = useState(false);

  const handleCloseEdit = () => {
    setEditModalOpen(false);
    setFormData({
      ...formData,
      id: '',
      name: '',
      company_agency: '',
    })
  };

  const handleEditClick = (row) => {
    setFormData({
      ...formData,
      id: row.id,
      name: row.name,
      company_agency: row.company_agency,
    })
    setEditModalOpen(true);
  };

  const handleUpdateData = async () => {
    setIsLoading(true);

    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/master_pelaku_usaha/${formData.id}`;

      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("data berhasil diubah");
        handleCloseEdit();
        await fetchMasterPelakuUsaha(accessToken, {
          page: currentPage,
          size: pageSize,
          sort_by: sorting.column,
          sort_order: sorting.order
        });
      } else if (response.status === 400 || response.status === 404) {
        const data = await response.json();
        toast.error(data.detail || data.data.message)
      } else {
        toast.error("data gagal diubah");
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setIsLoading(false);
  };

  // filter company agency
  const handleCompanyAgencyChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }

    const pageReset = Number(1);
    const currentSearchParams = new URLSearchParams(location.search);
    currentSearchParams.set('company_agency', id);
    navigate(`${location.pathname}?${currentSearchParams.toString()}`);

    const customParams = {};
    customParams['page'] = pageReset; 
    customParams['size'] = pageSize; 
    customParams['sort_by'] = sorting.column; 
    customParams['sort_order'] = sorting.order;
    if(id){
      customParams['company_agency'] = id;
    }

    fetchMasterPelakuUsaha(accessToken, customParams);
    setSelectedCompanyAgency(id)
  };

  // next or previous page
  const handlePageChange = (event, page) => {
    const newPage = Number(page + 1);
    const currentSearchParams = new URLSearchParams(location.search);
    currentSearchParams.set('page', newPage);
    navigate(`${location.pathname}?${currentSearchParams.toString()}`);
    fetchMasterPelakuUsaha(accessToken, {
      page: newPage,
      size: pageSize,
      sort_by: sorting.column,
      sort_order: sorting.order
    });
  };

  // rows per page
  const handleChangeItemPerPageSelect = (event) => {
    const newSize = Number(event.target.value);
    const pageReset = Number(1);
    const currentSearchParams = new URLSearchParams(location.search);
    currentSearchParams.set('size', newSize);
    currentSearchParams.set('page', pageReset);
    navigate(`${location.pathname}?${currentSearchParams.toString()}`);
    fetchMasterPelakuUsaha(accessToken, {
      page: pageReset,
      size: newSize,
      sort_by: sorting.column,
      sort_order: sorting.order
    });
  };

  // sort
  const handleSort = (columnName) => {
    let newOrder;
    if (sorting.column === columnName) {
      newOrder = sorting.order === SortingStates.ASC ? SortingStates.DESC : SortingStates.ASC;
    } else {
      newOrder = SortingStates.ASC;
    }

    setSorting({ column: columnName, order: newOrder });

    const currentSearchParams = new URLSearchParams(location.search);
    currentSearchParams.set('sort_by', columnName);
    currentSearchParams.set('sort_order', newOrder);
    navigate(`${location.pathname}?${currentSearchParams.toString()}`);
    fetchMasterPelakuUsaha(accessToken, {
      page: currentPage,
      size: pageSize,
      sort_by: columnName,
      sort_order: newOrder
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Sidebar />
        <Box
          component="main"
          sx={{
            padding: '20px',
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <div style={{ padding: "25px" }}>
            <div style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
              justifyContent: 'space-between'
            }}>
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '20px'
              }}>
                <SearchBar
                  id={"company-agency-autocomplete"}
                  data={dataCompanyAgency}
                  value={
                    dataCompanyAgency.find(
                      (option) => option.id === selectedCompanyAgency
                    ) ?? null
                  }
                  handleChange={handleCompanyAgencyChange}
                  label={"Instansi"}
                />
              </div>
              {
                dataProfile?.role === 'admin' && <Button
                  variant="outlined"
                  disableRipple
                  sx={{
                    color: "#32AE2F",
                    borderColor: "#32AE2F",
                  }}
                  onClick={handleOpenAdd}
                >
                  Create
                </Button>
              }
            </div>
            <div>
              {/* delete modal */}
              <DeleteDataModal
                modal={{
                  open: deleteModalOpen
                }}
                handle={{
                  handleClose: handleCloseDelete,
                  handleDeleteItem: handleDeleteItem,
                }}
                isLoading={isLoading}
                itemDeleted={itemToDelete}
              />
              {/* create modal */}
              <AddDataModal
                modal={{
                  open: openAdd
                }}
                handle={{
                  handleClose: handleCloseAdd,
                  handleInputChange: handleInputChange,
                  handleCreateSubmit: handleCreateSubmit,
                }}
                dataOption={{
                  dataCompanyAgency: dataCompanyAgency
                }}
                isLoading={isLoading}
                formData={formData}
              />
              {/* edit modal */}
              <EditDataModal
                modal={{
                  open: editModalOpen
                }}
                dataOption={{
                  dataCompanyAgency: dataCompanyAgency
                }}
                handle={{
                  handleClose: handleCloseEdit,
                  handleInputChange: handleInputChange,
                  handleUpdate: handleUpdateData,
                }}
                isLoading={isLoading}
                formData={formData}
              />
            </div>
          </div>
          {
            !isLoading && <TableMasterPelakuUsaha
              data={dataMasterPelakuUsaha}
              dataProfile={dataProfile}
              handleEditClick={handleEditClick}
              openDeleteConfirmationModal={openDeleteConfirmationModal}
              itemsPerPageList={itemsPerPageList}
              total={totalDataMasterPelakuUsaha}
              size={pageSize}
              offset={!totalDataMasterPelakuUsaha || totalDataMasterPelakuUsaha <= 0 ? 1 : currentPage}
              handlePageChange={handlePageChange}
              handleChangeItemPerPageSelect={handleChangeItemPerPageSelect}
              sorting={sorting}
              handleSort={handleSort}
            />
          }
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default MasterPelakuUsaha;
