import React, { useEffect, useState } from 'react';
import Cookies from "js-cookie";
import { useLocation, Navigate, Outlet, useNavigate } from 'react-router-dom';
import { getAccessToken, profilePromise } from '../Services/AuthService';
import { toast } from 'react-hot-toast';

const TOKEN_KEY = 'jwt_token';

const RequireAuth = () => {
  const [authProfile, setAuthProfile] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const fetchProfile = async () => {
    const accessToken = getAccessToken();
    try {
      const profile = await profilePromise(accessToken);
      setAuthProfile(profile);
    } catch (error) {
      Cookies.remove(TOKEN_KEY);
      const message = error.response?.data?.detail?.message ?? 'Login first';
      toast.error(message);
      navigate('/login');
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [navigate]);

  if (authProfile === null) {
    return null;
  }

  return (
    authProfile?.role ? (
      <Outlet />
    ) : <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;

