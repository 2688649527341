import axios from 'axios';

export const getMasterKomoditasPromise = async (access_token, setData, onlyForInput = false) => {
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/komoditas`;
  
  try{
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    let result_data = response?.data?.data;

    if(onlyForInput){
      result_data = result_data.map(item => ({
        label: item.nama,
        id: item.id,
      }));
    }

    setData(result_data)
  }catch(e){
    setData([])
  }
};

export const getMasterSubKomoditasPromise = async (access_token, setData, master_komoditas_id) => {
  // Create an empty URLSearchParams object
  const queryParams = new URLSearchParams();

  if (master_komoditas_id) {
    queryParams.append('master_komoditas_id', master_komoditas_id);
  }

  const queryString = queryParams.toString();
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/sub-komoditas` + (queryString ? `?${queryString}` : '');
  
  try{
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    const data = response?.data?.data;
    setData(data)
  }catch(e){
    setData([])
  }
};


export const getAllMasterKomoditas = async (access_token, customParam) => {
  try {
    // Construct the query string from customParam
    const queryString = new URLSearchParams(customParam).toString();

    // Append the query string to the API URL
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/komoditas?${queryString}`;

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + access_token,
        'Content-Type': 'application/json'
      },
    });

    if (!response.ok) {
      return {
        data: []
      };
    }

    const datas = await response.json();
    return datas;
  } catch (error) {
    return {
      data: []
    };
  }
};
