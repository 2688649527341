import axios from 'axios';

export const getUserPromise = async (access_token, setData, master_komoditas_id) => {
  // Create an empty URLSearchParams object
  const queryParams = new URLSearchParams();

  if (master_komoditas_id) {
    queryParams.append('user_role', master_komoditas_id);
  }

  const queryString = queryParams.toString();
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/user` + (queryString ? `?${queryString}` : '');
  
  try{
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    const data = response?.data?.data;
    setData(data)
  }catch(e){
    setData([])
  }
};
