import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Cookies from "js-cookie";

// tools
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

// icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import DescriptionIcon from "@mui/icons-material/Description";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import MapIcon from "@mui/icons-material/Map";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoBapanas from "../assets/images/bg/logo.bapanas.png";
import Avatar from "@mui/material/Avatar";
import GroupIcon from "@mui/icons-material/Group";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import FlakyIcon from "@mui/icons-material/Flaky";

const iconArray = [
  DashboardIcon,
  MapIcon,
  DescriptionIcon,
  FlakyIcon,
  WarehouseIcon,
  GroupIcon,
  PictureAsPdfIcon,
  SmartphoneIcon,
];

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function Sidebar() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleLogout = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/auth/logout`,
        {
          method: "POST",
        }
      );

      if (response.status === 200) {
        Cookies.remove("jwt_token");
        window.location.replace("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const token = Cookies.get("jwt_token") || "";

  const [dataInitial, setDataInitial] = useState("");

  async function fetchInitial() {
    const queryParams = new URLSearchParams();

    const queryString = queryParams.toString();
    const apiUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/auth/profile` +
      (queryString ? `?${queryString}` : "");
    try {
      setDataInitial([]);
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const { data } = await response.json();
        setDataInitial(data);
      } else if (response.status === 404) {
        setDataInitial([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchInitial();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          bgcolor: "white",
          color: "black",
          boxShadow: 0,
          borderBottom: 1,
          borderColor: "grey.200",
        }}
      >
        <Toolbar style={{ backgroundColor: "#FFF" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              color: "#000",
              marginRight: 3,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <img src={LogoBapanas} alt="Logo Bapanas" width={"35rem"} />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            style={{
              flexGrow: 1,
              color: "#000",
              fontFamily: "Roboto",
              fontWeight: 600,
              fontSize: 18,
            }}
          >
            Sistem Informasi Pergudangan
            <br /> <span style={{ fontSize: 15 }}>Badan Pangan Nasional</span>
          </Typography>
          <Stack direction="row" spacing={2}>
            <Avatar sx={{ bgcolor: "#32ae2f", m: 1 }} onClick={handleOpenModal}>
              {dataInitial.initial}
            </Avatar>
          </Stack>
        </Toolbar>
      </AppBar>
      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{ fontFamily: "Roboto" }}>
          {[
            { text: "Dashboard", path: "/dashboard" },
            { text: "Map", path: "/map-area" },
            { text: "Laporan", path: "/laporan" },
            { text: "Absensi", path: "/absensi" },
            { text: "Manajemen", path: "/gudang" },
            { text: "User", path: "/user" },
            { text: "Panduan", path: "/panduan" },
            { text: "Aplikasi Mobile", path: "/mobileapps" },
          ].map((item, index) => {
            if (
              dataInitial.role === "admin" ||
              (dataInitial.role === "user" && item.text !== "User")
            ) {
              return (
                <ListItem
                  key={item.text}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    component={Link}
                    to={item.path}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {React.createElement(iconArray[index % iconArray.length])}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                  {item.text === "Manajemen" && open && (
                    <List sx={{ paddingLeft: 2, fontSize: 12 }}>
                      {[
                        "Perusahaan",
                        "Gudang",
                        "Komoditas",
                        "Sub Komoditas",
                        "Pelaku Usaha",
                      ].map((subItem, subIndex) => {
                        if (
                          dataInitial.role === "admin" ||
                          dataInitial.role === "user"
                        ) {
                          return (
                            <ListItem key={subItem} disablePadding>
                              <Link
                                to={`/${subItem
                                  .toLowerCase()
                                  .replace(/\s+/g, "-")}`}
                                style={{ textDecoration: "none" }}
                              >
                                <ListItemButton
                                  sx={{ minHeight: 48, color: "#000" }}
                                >
                                  <ListItemText
                                    primary={subItem}
                                    primaryTypographyProps={{
                                      variant: "body2",
                                    }}
                                  />
                                </ListItemButton>
                              </Link>
                            </ListItem>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </List>
                  )}
                </ListItem>
              );
            } else {
              return null;
            }
          })}
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}

export default Sidebar;
