import React, { useEffect, useState, useRef } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { IconButton, Button, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Sidebar from "../../../Components/Sidebar";
import CloseIcon from "@mui/icons-material/Close";
import { getAccessToken } from "../../../Services/AuthService";
import {
  fetchCompany,
  fetchMasterGudang,
  fetchMasterKomoditasInside,
  fetchMasterKomoditasById,
  fetchDashboardDate,
  fetchSingleMonthData,
  fetchSingleWeekData,
  fetchWeeklyDashboard,
  fetchMonthlyDashboard,
} from "../FilterService";
import DashboardMasterKomoditasFilter from "./DashboardMasterKomoditasFilter";
import Chart from "react-apexcharts";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useParams } from "react-router-dom";
import { getAllMasterPelakuUsaha } from "../../../Services/MasterPelakuUsahaService";
import { formatDate, getLastFriday } from "../../../Services/TanggalService";
import { useNavigate } from "react-router-dom";
import * as htmlToImage from "html-to-image";
import { BootstrapButton } from "../../../Components/Button/BootstrapButton";

const defaultTheme = createTheme();

function getMonthName(monthNumber) {
  const months = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  return months[monthNumber - 1];
}

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

export default function DashboardMasterKomoditas() {
  let accessToken = getAccessToken();
  const { masterKomoditasId } = useParams();

  // filter data
  const [dataCompany, setDataCompany] = useState([]);
  const [dataMasterGudang, setDataMasterGudang] = useState([]);
  const [dataDashboardDate, setDataDashboardDate] = useState([]);
  const [dataMasterKomoditasInside, setDataMasterKomoditasInside] = useState(
    []
  );

  // selected filter
  const lastFriday = getLastFriday();
  const formattedLastFriday = formatDate(lastFriday);

  // selected filter
  const [selectedDate, setSelectedDate] = useState(
    dataDashboardDate &&
      dataDashboardDate.tanggal &&
      dataDashboardDate.tanggal.length > 0
      ? dataDashboardDate.tanggal[0]
      : ""
  );

  const [selectedMasterKomoditas, setSelectedMasterKomoditas] = useState({});
  const [selectedMasterGudang, setSelectedMasterGudang] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedTipeGudang, setSelectedTipeGudang] = useState("");

  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedRegency, setSelectedRegency] = useState("");

  const [selectedMasterPelakuUsaha, setSelectedMasterPelakuUsaha] =
    useState("");

  const [provinces, setProvinces] = useState([]);
  const [regencies, setRegencies] = useState([]);

  const [dataMasterPelakuUsaha, setDataMasterPelakuUsaha] = useState([]);

  const tanggalText = new Date(selectedDate).toLocaleDateString("id-ID", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  useEffect(() => {
    if (selectedDate) {
      const dateObj = new Date(selectedDate);
      const month = dateObj.getMonth() + 1;
      const aMonth = dateObj.getMonth() + -2;
      setSecondMonth(month);
      const timer = setTimeout(() => {
        setFirstMonth(aMonth);
      }, 700);
      return () => clearTimeout(timer);
    }
  }, [selectedDate]);

  // role
  const [dataProfile, setDataProfile] = useState("");

  const [years, setYears] = useState([]);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const currentFirstMonth = 1;
  const currentSecondMonth = new Date().getMonth() + 1;
  const [firstMonth, setFirstMonth] = useState(currentFirstMonth);
  const [secondMonth, setSecondMonth] = useState(currentSecondMonth);

  const monthNames = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const getMonthName = (monthNumber) => {
    return monthNames[monthNumber - 1] ?? "";
  };

  // filter
  const [dataDashboardWeeklyLinechart, setDataDashboardWeeklyLinechart] =
    useState(false);
  const [dataDashboardMonthlyPiechart, setDataDashboardMonthlyPiechart] =
    useState(false);
  const [dataDashboardWeeklyPiechart, setDataDashboardWeeklyPiechart] =
    useState(false);
  const [dataDashboardMonthlyLinechart, setDataDashboardMonthlyLinechart] =
    useState(false);

  const handleYearChange = (event) => {
    const newYear = event.target.value;
    setSelectedYear(newYear);
    setSelectedDate("");
  };

  const handleChangeCompanyFilter = (event) => {
    setSelectedCompany(event.target.value);
    setSelectedMasterGudang("");
  };

  const handleChangeTipeGudangFilter = (event) => {
    setSelectedTipeGudang(event.target.value);
  };

  const navigate = useNavigate();

  const handleChangeMasterGudangFilter = (event) => {
    setSelectedMasterGudang(event.target.value);
  };

  const handleChangeMasterKomoditasFilter = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue) {
      navigate(`/dashboard/${selectedValue}`);
      window.location.reload();
    }
  };

  const handleChangeMasterPelakuUsahaFilter = (event) => {
    setSelectedMasterPelakuUsaha(event.target.value);
  };

  const handleBackDashboard = () => {
    window.location.replace("/dashboard");
  };

  async function fetchProvince() {
    setProvinces([]);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area/provinces`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const { data } = await response.json();
      setProvinces(data);
    } catch (e) {
      setProvinces([]);
    }
  }

  const fetchPelakuUsahaData = async () => {
    try {
      const data = await getAllMasterPelakuUsaha(accessToken, null, true);
      if (data.length > 0) {
        setDataMasterPelakuUsaha(data);
      }
    } catch (error) {
      console.error("Failed to fetch master pelaku usaha");
    }
  };

  async function fetchRegency(selectedProvince) {
    setRegencies([]);
    try {
      if (selectedProvince) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/area/regencies?province_id=${selectedProvince}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.status === 200) {
          const { data } = await response.json();
          setRegencies(data);
        } else {
          throw new Error("Failed to fetch area options");
        }
      }
    } catch (e) {
      setRegencies([]);
    }
  }

  async function fetchProfile() {
    const queryParams = new URLSearchParams();

    const queryString = queryParams.toString();
    const apiUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/auth/profile` +
      (queryString ? `?${queryString}` : "");
    try {
      setDataProfile([]);
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        const { data } = await response.json();
        setDataProfile(data);
      } else if (response.status === 404) {
        setDataProfile([]);
      }
    } catch (error) {}
  }

  function formatWeeklyDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "short", day: "numeric" };
    return date.toLocaleDateString("id-ID", options);
  }

  async function fetchDashboardWeeklyLinechart(
    year,
    firstMonth,
    secondMonth,
    selectedMasterGudang,
    selectedCompany,
    selectedTipeGudang,
    selectedProvince,
    selectedRegency,
    selectedMasterPelakuUsaha
  ) {
    const datas = await fetchWeeklyDashboard(
      accessToken,
      masterKomoditasId,
      year,
      firstMonth,
      secondMonth,
      selectedMasterGudang,
      selectedCompany,
      selectedTipeGudang,
      selectedProvince,
      selectedRegency,
      selectedMasterPelakuUsaha
    );

    if (datas?.length > 0) {
      const chartData = {
        series: [
          {
            name: "Total Stock",
            data: datas.map((data) => {
              const totalStok = data.total_stok;
              return totalStok !== null && totalStok !== undefined
                ? parseFloat(totalStok.toFixed(2))
                : 0;
            }),
          },
        ],
        chart: {
          height: 200,
          type: "line",
          zoom: false,
          toolbar: {
            tools: {
              download: false,
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -15,
          formatter: function (val) {
            return new Intl.NumberFormat("id-ID", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(val);
          },
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "",
          align: "center",
          style: {
            fontSize: "22px",
            fontFamily: "Calibri",
          },
        },
        xaxis: {
          categories: datas?.map((data) => formatWeeklyDate(data.date)),
        },
        yaxis: {
          tickAmount: 4,
          labels: {
            formatter: function (val) {
              return new Intl.NumberFormat("id-ID", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(val);
            },
          },
        },
        legend: {
          showForSingleSeries: false,
          position: "bottom",
          horizontalAlign: "center",
          offsetY: 0,
        },
      };

      setDataDashboardWeeklyLinechart(chartData);
    } else {
      setDataDashboardWeeklyLinechart(false);
    }
  }

  async function fetchDashboardMonthlyLineChart(
    year,
    selectedMasterGudang,
    selectedCompany,
    selectedTipeGudang,
    selectedProvince,
    selectedRegency,
    selectedMasterPelakuUsaha
  ) {
    let viewMode = "clean";

    const datas = await fetchMonthlyDashboard(
      accessToken,
      masterKomoditasId,
      year,
      selectedMasterGudang,
      selectedCompany,
      selectedTipeGudang,
      selectedProvince,
      selectedRegency,
      selectedMasterPelakuUsaha,
      viewMode
    );

    if (datas?.length > 0) {
      const lineChartData = {
        series: [
          {
            name: "Total Stock",
            data: datas.map((data) => {
              const totalStok = data.total_stok;
              return totalStok !== null && totalStok !== undefined
                ? parseFloat(totalStok.toFixed(2))
                : 0;
            }),
          },
        ],
        chart: {
          height: 150,
          type: "line",
          zoom: false,
          toolbar: {
            tools: {
              download: false,
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -25,
          formatter: function (val) {
            return new Intl.NumberFormat("id-ID", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(val);
          },
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "",
          align: "center",
          style: {
            fontSize: "22px",
            fontFamily: "Calibri",
          },
        },
        xaxis: {
          offsetX: -15,
          categories: datas?.map((data) => getMonthName(data.month)),
        },
        yaxis: {
          tickAmount: 4,
          labels: {
            formatter: function (val) {
              return new Intl.NumberFormat("id-ID", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(val);
            },
          },
        },
        legend: {
          showForSingleSeries: false,
          position: "bottom",
          horizontalAlign: "center",
          offsetY: 0,
        },
      };

      setDataDashboardMonthlyLinechart(lineChartData);
    } else {
      setDataDashboardMonthlyLinechart(false);
    }
  }

  async function fetchDashboardMonthlyPieChart(
    year,
    month,
    selectedMasterGudang,
    selectedCompany,
    selectedTipeGudang,
    selectedProvince,
    selectedRegency,
    selectedMasterPelakuUsaha
  ) {
    let viewMode = "grouped_pelaku_usaha";
    if (selectedMasterPelakuUsaha) {
      viewMode = "grouped_company";
    }

    const datas = await fetchSingleMonthData(
      accessToken,
      masterKomoditasId,
      year,
      month,
      selectedMasterGudang,
      selectedCompany,
      selectedTipeGudang,
      selectedProvince,
      selectedRegency,
      selectedMasterPelakuUsaha,
      viewMode
    );

    if (Object.keys(datas).length > 0) {
      // pie chart
      const pieSeriesData = [
        {
          colorByPoint: true,
          data: datas.categories.map((category) => ({
            name: category.name,
            y:
              category.total_stok !== null && category.total_stok !== undefined
                ? parseFloat(category.total_stok.toFixed(2))
                : 0,
          })),
        },
      ];

      const pieChartData = {
        chart: {
          type: "pie",
          backgroundColor: "#f5f5f5",
        },
        title: {
          text: ``,
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              formatter: function () {
                return `<p style="text-transform: capitalize; color: blue">${
                  this.point.name
                }</p> (${this.point.percentage.toFixed(
                  2
                )}% - ${new Intl.NumberFormat("id-ID", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(this.point.y)} Ton)`;
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return `<b>${this.point.name}</b>: ${new Intl.NumberFormat(
              "id-ID",
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            ).format(this.point.y)} Ton (${this.point.percentage.toFixed(2)}%)`;
          },
        },
        series: pieSeriesData,
      };

      setDataDashboardMonthlyPiechart(pieChartData);
    } else {
      setDataDashboardMonthlyPiechart(false);
    }
  }

  async function fetchDashboardWeeklyPieChart(
    selectedDate,
    selectedMasterGudang,
    selectedCompany,
    selectedTipeGudang,
    selectedProvince,
    selectedRegency,
    selectedMasterPelakuUsaha
  ) {
    let viewMode = "grouped_pelaku_usaha";
    if (selectedMasterPelakuUsaha) {
      viewMode = "grouped_company";
    }

    let datas = {};

    if (selectedDate) {
      datas = await fetchSingleWeekData(
        accessToken,
        masterKomoditasId,
        selectedDate,
        selectedMasterGudang,
        selectedCompany,
        selectedTipeGudang,
        selectedProvince,
        selectedRegency,
        selectedMasterPelakuUsaha,
        viewMode
      );
    } else {
      setDataDashboardWeeklyPiechart(false);
    }

    if (Object.keys(datas).length > 0) {
      // bar chart
      const pieTooltipData = {
        pointFormat: "<b><span>{point.y:,.2f}</span></b>",
      };

      const pieSeriesData = [
        {
          colorByPoint: true,
          data: datas.categories.map((category) => ({
            name: category.name,
            y:
              category.total_stok !== null && category.total_stok !== undefined
                ? parseFloat(category.total_stok.toFixed(2))
                : 0,
          })),
        },
      ];

      const pieChartData = {
        chart: {
          type: "pie",
          backgroundColor: "#f5f5f5",
        },
        title: {
          text: ``,
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              formatter: function () {
                return `<p style="text-transform: capitalize; color: blue">${
                  this.point.name
                }</p> (${this.point.percentage.toFixed(
                  2
                )}% - ${new Intl.NumberFormat("id-ID", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(this.point.y)} Ton)`;
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return `<b>${this.point.name}</b>: ${new Intl.NumberFormat(
              "id-ID",
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            ).format(this.point.y)} Ton (${this.point.percentage.toFixed(2)}%)`;
          },
        },
        series: pieSeriesData,
      };

      setDataDashboardWeeklyPiechart(pieChartData);
    } else {
      setDataDashboardWeeklyPiechart(false);
    }
  }

  const tanggalTextFile = new Date(selectedDate).toLocaleDateString("id-ID", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const [selectedFileName, setSelectedFileName] = useState("");

  const handleFileNameChange = (e) => {
    setSelectedFileName(e.target.value);
  };

  const pieChart = useRef(null);

  const downloadImage = async () => {
    const dataUrl = await htmlToImage.toPng(pieChart.current);

    const link = document.createElement("a");
    link.download = `${selectedFileName}.png`;
    link.href = dataUrl;
    link.click();
  };

  const [open2, setOpen2] = React.useState(false);
  const handleOpenDownload2 = () => setOpen2(true);
  const handleCloseDownload2 = () => setOpen2(false);

  const [selectedFileName2, setSelectedFileName2] = useState("");

  const handleFileNameChange2 = (e) => {
    setSelectedFileName2(e.target.value);
  };

  const lineChart = useRef(null);

  const downloadImage2 = async () => {
    const dataUrl = await htmlToImage.toPng(lineChart.current);

    const link = document.createElement("a");
    link.download = `${selectedFileName2}.png`;
    link.href = dataUrl;
    link.click();
  };
  useEffect(() => {
    fetchCompany(
      accessToken,
      setDataCompany,
      selectedProvince,
      selectedMasterPelakuUsaha
    );
  }, [accessToken, selectedProvince, selectedMasterPelakuUsaha]);

  const [open, setOpen] = React.useState(false);
  const handleOpenDownload = () => setOpen(true);
  const handleCloseDownload = () => setOpen(false);

  useEffect(() => {
    fetchDashboardDate(accessToken, setDataDashboardDate, masterKomoditasId);
  }, [accessToken, masterKomoditasId]);

  useEffect(() => {
    fetchMasterGudang(
      accessToken,
      setDataMasterGudang,
      selectedProvince,
      selectedRegency,
      selectedCompany,
      selectedTipeGudang
    );
  }, [
    accessToken,
    selectedProvince,
    selectedRegency,
    selectedCompany,
    selectedTipeGudang,
  ]);

  useEffect(() => {
    if (dataDashboardDate.length > 0) {
      setSelectedDate(dataDashboardDate[0].tanggal);
    }
  }, [dataDashboardDate]);

  useEffect(() => {
    fetchMasterKomoditasInside(
      accessToken,
      setDataMasterKomoditasInside,
      selectedDate
    );
  }, [accessToken, selectedDate]);

  useEffect(() => {
    fetchRegency(selectedProvince);
  }, [selectedProvince]);

  useEffect(() => {
    fetchDashboardWeeklyLinechart(
      selectedYear,
      firstMonth,
      secondMonth,
      selectedMasterGudang,
      selectedCompany,
      selectedTipeGudang,
      selectedProvince,
      selectedRegency,
      selectedMasterPelakuUsaha
    );
    fetchDashboardWeeklyPieChart(
      selectedDate,
      selectedMasterGudang,
      selectedCompany,
      selectedTipeGudang,
      selectedProvince,
      selectedRegency,
      selectedMasterPelakuUsaha
    );
    fetchDashboardMonthlyLineChart(
      selectedYear,
      selectedMasterGudang,
      selectedCompany,
      selectedTipeGudang,
      selectedProvince,
      selectedRegency,
      selectedMasterPelakuUsaha
    );
    fetchDashboardMonthlyPieChart(
      selectedYear,
      secondMonth,
      selectedMasterGudang,
      selectedCompany,
      selectedTipeGudang,
      selectedProvince,
      selectedRegency,
      selectedMasterPelakuUsaha
    );
  }, [
    selectedYear,
    firstMonth,
    secondMonth,
    selectedDate,
    selectedMasterGudang,
    selectedCompany,
    selectedTipeGudang,
    selectedProvince,
    selectedRegency,
    selectedMasterPelakuUsaha,
  ]);

  useEffect(() => {
    fetchProfile();

    fetchPelakuUsahaData();
    fetchProvince();

    fetchMasterKomoditasById(
      accessToken,
      masterKomoditasId,
      setSelectedMasterKomoditas
    );

    const dropdownCurrentYear = new Date().getFullYear();
    const yearOptions = [];
    for (let year = 2020; year <= dropdownCurrentYear; year++) {
      yearOptions.push(year);
    }
    setYears(yearOptions);
  }, []);

  useEffect(() => {
    if (selectedMasterKomoditas) {
      setSelectedFileName(
        `Sebaran stok ${
          selectedMasterKomoditas.nama ?? ""
        } ${tanggalTextFile} (pie chart)`
      );
      setSelectedFileName2(
        `Sebaran stok ${
          selectedMasterKomoditas.nama ?? ""
        } ${tanggalTextFile} (line chart)`
      );
    }
  }, [selectedMasterKomoditas]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Sidebar />
        <Box
          component="main"
          sx={{
            ml: "-4%",
            backgroundColor: defaultTheme.palette.grey[100],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <div
            style={{
              width: "97%",
              marginLeft: "1.7%",
              marginTop: "1.7%",
              marginBottom: "1.7%",
            }}
          >
            <div>
              {/* title */}
              <div style={{ width: "100%" }}>
                <p
                  style={{
                    color: "green",
                    bgcolor: "#000000",
                    fontFamily: "Roboto",
                    fontWeight: 600,
                    fontSize: 23,
                    textAlign: "left",
                    width: "90%",
                    textTransform: "capitalize",
                  }}
                >
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleBackDashboard}
                    aria-label="close-chart"
                    sx={{
                      mr: "20px",
                      mt: "5px",
                      width: "50px",
                      height: "50px",
                    }}
                  >
                    <Tooltip title="Back to Dashboard" arrow>
                      <KeyboardBackspaceIcon />
                    </Tooltip>
                  </IconButton>

                  {dataProfile.role === "admin" ? (
                    <>
                      {!selectedProvince &&
                        !selectedRegency &&
                        "Total Stok Gudang Nasional"}
                      {selectedProvince && "Stok Gudang Provinsi "}
                      {selectedProvince &&
                        provinces.find((prov) => prov.id === selectedProvince)
                          ?.name}
                      {selectedRegency && ", Kota/Kabupaten "}
                      {selectedRegency &&
                        regencies.find((reg) => reg.id === selectedRegency)
                          ?.name}
                      {selectedMasterKomoditas &&
                        ` Untuk ${selectedMasterKomoditas.nama}`}
                    </>
                  ) : (
                    <>
                      {"Total Stok Gudang"}
                      {selectedMasterKomoditas &&
                        ` Untuk ${selectedMasterKomoditas.nama}`}
                    </>
                  )}
                </p>
              </div>

              {/* filter */}
              <DashboardMasterKomoditasFilter
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                firstMonth={firstMonth}
                setFirstMonth={setFirstMonth}
                secondMonth={secondMonth}
                setSecondMonth={setSecondMonth}
                years={years}
                selectedYear={selectedYear}
                dataDashboardDate={dataDashboardDate}
                handleYearChange={handleYearChange}
                selectedCompany={selectedCompany}
                handleChangeCompanyFilter={handleChangeCompanyFilter}
                dataCompany={dataCompany}
                selectedMasterGudang={selectedMasterGudang}
                handleChangeMasterGudangFilter={handleChangeMasterGudangFilter}
                dataMasterGudang={dataMasterGudang}
                masterKomoditasId={masterKomoditasId}
                handleChangeMasterKomoditasFilter={
                  handleChangeMasterKomoditasFilter
                }
                dataMasterKomoditasInside={dataMasterKomoditasInside}
                selectedMasterPelakuUsaha={selectedMasterPelakuUsaha}
                handleChangeMasterPelakuUsahaFilter={
                  handleChangeMasterPelakuUsahaFilter
                }
                dataMasterPelakuUsaha={dataMasterPelakuUsaha}
                selectedTipeGudang={selectedTipeGudang}
                handleChangeTipeGudangFilter={handleChangeTipeGudangFilter}
                selectedProvince={selectedProvince}
                setSelectedProvince={setSelectedProvince}
                provinces={provinces}
                selectedRegency={selectedRegency}
                setSelectedRegency={setSelectedRegency}
                regencies={regencies}
                role={dataProfile.role}
              />
            </div>

            <br />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={handleOpenDownload2}>Download LineChart</Button>
            </div>
            <div
              id="lineChart"
              ref={lineChart}
              style={{ backgroundColor: "#f5f5f5" }}
            >
              {dataDashboardWeeklyLinechart && (
                <>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: "20px",
                      textAlign: "center",
                    }}
                  >
                    Perkembangan Stok {selectedMasterKomoditas.nama ?? ""}{" "}
                    Mingguan Tahun {selectedYear}
                  </p>
                  <Chart
                    options={dataDashboardWeeklyLinechart}
                    series={dataDashboardWeeklyLinechart.series}
                    type="line"
                    height={285}
                  />
                </>
              )}

              {dataDashboardMonthlyLinechart && (
                <>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: "20px",
                      textAlign: "center",
                    }}
                  >
                    Perkembangan Stok {selectedMasterKomoditas.nama ?? ""}{" "}
                    Bulanan Tahun {selectedYear}
                  </p>
                  <Chart
                    options={dataDashboardMonthlyLinechart}
                    series={dataDashboardMonthlyLinechart.series}
                    type="line"
                    height={285}
                  />
                </>
              )}
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={handleOpenDownload}>Download Pie Chart</Button>
            </div>

            <div
              id="pieChart"
              ref={pieChart}
              style={{
                backgroundColor: "#f5f5f5",
                marginTop: "40px",
                minHeight: "600px",
                maxHeight: "1800px",
                paddingBottom: "50px",
              }}
            >
              {dataDashboardMonthlyPiechart && (
                <>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: "20px",
                      textAlign: "center",
                    }}
                  >
                    Sebaran Stok {selectedMasterKomoditas.nama ?? ""} Bulan{" "}
                    {getMonthName(secondMonth)} Tahun {selectedYear}
                  </p>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dataDashboardMonthlyPiechart}
                  />
                </>
              )}

              {dataDashboardWeeklyPiechart && (
                <>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: "20px",
                      textAlign: "center",
                    }}
                  >
                    Sebaran Stok {selectedMasterKomoditas.nama ?? ""} Tanggal{" "}
                    {tanggalText}
                  </p>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dataDashboardWeeklyPiechart}
                  />
                </>
              )}
            </div>
          </div>
          <center>
            <Modal
              open={open}
              onClose={handleCloseDownload}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <center>
                <Box sx={style}>
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      color: "rgba(0, 0, 0, 0.54)",
                    }}
                    onClick={handleCloseDownload}
                  >
                    <CloseIcon />
                  </IconButton>
                  <p
                    id="modal-modal-title"
                    style={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Download
                  </p>
                  <input
                    style={{
                      height: "30px",
                      padding: "0px 8px",
                      border: "2px solid gray",
                      borderRadius: "5px",
                      width: "94%",
                      marginTop: "10px",
                      textAlign: "center",
                      backgroundColor: "#f5f5f5",
                    }}
                    type="text"
                    placeholder="Nama file:"
                    value={selectedFileName}
                    onChange={handleFileNameChange}
                  />
                  <br />
                  <BootstrapButton
                    sx={{
                      width: "94%",
                      mt: 4,
                      borderRadius: 2,
                      backgroundColor: "#32AE2F",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#1d691b",
                        boxShadow: "none",
                      },
                    }}
                    onClick={downloadImage}
                  >
                    Download Pie Chart
                  </BootstrapButton>
                </Box>
              </center>
            </Modal>
            <Modal
              open={open2}
              onClose={handleCloseDownload2}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <center>
                <Box sx={style}>
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      color: "rgba(0, 0, 0, 0.54)",
                    }}
                    onClick={handleCloseDownload2}
                  >
                    <CloseIcon />
                  </IconButton>
                  <p
                    id="modal-modal-title"
                    style={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Download
                  </p>
                  <input
                    style={{
                      height: "30px",
                      padding: "0px 8px",
                      border: "2px solid gray",
                      borderRadius: "5px",
                      width: "94%",
                      marginTop: "10px",
                      textAlign: "center",
                      backgroundColor: "#f5f5f5",
                    }}
                    type="text"
                    placeholder="Nama file:"
                    value={selectedFileName2}
                    onChange={handleFileNameChange2}
                  />
                  <br />
                  <BootstrapButton
                    sx={{
                      width: "94%",
                      mt: 4,
                      borderRadius: 2,
                      backgroundColor: "#32AE2F",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#1d691b",
                        boxShadow: "none",
                      },
                    }}
                    onClick={downloadImage2}
                  >
                    Download Line Chart
                  </BootstrapButton>
                </Box>
              </center>
            </Modal>
          </center>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
