import axios from 'axios';

export const getLaporanPromise = async (access_token, setData, 
  tipe_gudang = null, 
  company_agency = null, 
  master_komoditas_id = null,
  company_id = null,
  master_gudang_id = null,
  master_pelaku_usaha_id = null,
  date_start = null,
  date_end = null,
  exclude_zero_stok= null,
) => {
  // Create an empty URLSearchParams object
  const queryParams = new URLSearchParams();
  
  if (tipe_gudang) {
    queryParams.append('tipe_gudang', tipe_gudang);
  }
  
  if (company_agency) {
    queryParams.append('company_agency', company_agency);
  }
  
  if (master_komoditas_id) {
    queryParams.append('master_komoditas_id', master_komoditas_id);
  }

  if (company_id && company_id !== "") {
    queryParams.append("company_id", company_id);
  }
  
  if (master_gudang_id && master_gudang_id !== "") {
    queryParams.append("master_gudang_id", master_gudang_id);
  }
  
  if (master_pelaku_usaha_id && master_pelaku_usaha_id !== "") {
    queryParams.append("master_pelaku_usaha_id", master_pelaku_usaha_id);
  }
  
  if (exclude_zero_stok && exclude_zero_stok !== "") {
    queryParams.append("exclude_zero_stok", exclude_zero_stok);
  }
  
  if (date_start && date_start !== "") {
    queryParams.append("date_start", date_start);
  }
  
  if (date_end && date_end !== "") {
    queryParams.append("date_end", date_end);
  }

  const queryString = queryParams.toString();
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/data-stok` + (queryString ? `?${queryString}` : '');
  
  try{
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    const data = response?.data?.data;
    setData(data)
  }catch(e){
    setData([])
  }
};
