import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Sidebar from "../../Components/Sidebar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Cookies from "js-cookie";

function Panduan() {
  const token = Cookies.get("jwt_token") || "";
  const [urlPanduan, setUrlPanduan] = useState("");
  const [dataInitial, setDataInitial] = useState("");
  async function fetchInitial() {
    const queryParams = new URLSearchParams();
    const queryString = queryParams.toString();
    const apiUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/auth/profile` +
      (queryString ? `?${queryString}` : "");
      try {
      setDataInitial([]);
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const { data } = await response.json();
        console.log(data);
        setUrlPanduan(
          data.role == "admin"
            ? process.env.REACT_APP_PDF_GUIDE_ADMIN
            : process.env.REACT_APP_PDF_GUIDE_USER
        );
        setDataInitial(data);
      } else if (response.status === 404) {
        setDataInitial([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchInitial();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ ml: "-4%", backgroundColor: (theme) =>
      theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[900],
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
        }}
      >
        <Toolbar />
        <div style={{ padding: "25px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", gap: "10px" }}></div>
        </div>

          <h2>Petunjuk Teknis Sistem Informasi Gudang Bapangnas</h2>

          {/* button download pdf */}
          <Box sx={{ "& button": { m: 1 } }}>
            <a href={urlPanduan} download="Petunjuk Teknis Sistem Informasi Gudang Bapangnas">
              <Button variant="outlined" size="small">
                Download PDF
              </Button>
            </a>
          </Box>
          {/* Shop PDF Iframe */}
          <iframe src={urlPanduan} width="1000" height="490"></iframe>
        </div>
      </Box>
    </Box>
  );
}

export default Panduan;
