import { React, useState, useEffect } from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../../Components/Sidebar";
import SearchBar from "../../Components/SearchBar";
import { getLaporanPromise } from "../../Services/LaporanService";
import { getAccessToken } from "../../Services/AuthService";
import {
  getMasterKomoditasPromise,
} from "../../Services/KomoditasService";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useNavigate } from 'react-router-dom';
import DataGridLaporan from "./DataGrid";
import AddDataModal from "./AddDataModal";
import { getMasterGudang } from "../../Services/MasterGudangService";
import { getCompany } from "../../Services/CompanyService";
import { toast } from "react-hot-toast";
import DeleteDataModal from "./DeleteDataModal";
import { IconButton, MenuItem, Modal, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import { dataCompanyAgency, dataTipeGudang } from "../../constant";
import { getAllMasterPelakuUsaha } from "../../Services/MasterPelakuUsahaService";
import { formatDate, generatePreviousFridayDates, getLastFriday } from "../../Services/TanggalService";

const defaultTheme = createTheme();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  padding: "3px 10px",
  border: "1px solid",
  lineHeight: 2,
  backgroundColor: "#32AE2F",
  fontFamily: ["Roboto"].join(","),
  "&:hover": {
    backgroundColor: "#0696A6",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

export default function Laporan() {
  // selected
  const accessToken = getAccessToken();
  const [selectedTipeGudang, setSelectedTipeGudang] = useState("");
  const [selectedCompanyCategory, setSelectedCompanyCategory] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedMasterGudang, setSelectedMasterGudang] = useState("");
  const [selectedKomoditasId, setSelectedKomoditasId] = useState("");
  const [selectedMasterPelakuUsahaId, setSelectedMasterPelakuUsahaId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [dataLaporan, setDataLaporan] = useState([]);
  const [dataCompany, setDataCompany] = useState([]);
  const [dataMasterGudang, setDataMasterGudang] = useState([]);
  const [dataMasterPelakuUsaha, setDataMasterPelakuUsaha] = useState([]);
  const navigate = useNavigate();

  // selected filter
  const lastFriday = getLastFriday();
  const formattedLastFriday = formatDate(lastFriday);
  const previousFridayDates = generatePreviousFridayDates();

  const [selectedDateStart, setSelectedDateStart] = useState(formattedLastFriday);
  const [selectedDateEnd, setSelectedDateEnd] = useState(formattedLastFriday);

  const [dataKomoditas, setDataKomoditas] = useState([]);

  const [dataInitial, setDataInitial] = useState("");

  async function fetchInitial() {
    const queryParams = new URLSearchParams();

    const queryString = queryParams.toString();
    const apiUrl =
      `${process.env.REACT_APP_API_URL}/api/v1/auth/profile` +
      (queryString ? `?${queryString}` : "");
    try {
      setDataInitial([]);
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        const { data } = await response.json();
        setDataInitial(data);
      } else {
        navigate('/login');
        setDataInitial([]);
      }
    } catch (error) { }
  }

  const fetchPelakuUsahaData = async () => {
    try {
      const data = await getAllMasterPelakuUsaha(accessToken, null, true);
      if (data.length > 0) {
        const result_data = data.map(item => ({
          label: item.name,
          id: item.id,
        }));
        setDataMasterPelakuUsaha(result_data)
      }
    } catch (error) {
      console.error("Failed to fetch master pelaku usaha")
    }
  };

  useEffect(() => {
    fetchInitial();
  }, []);

  useEffect(() => {
    getMasterKomoditasPromise(accessToken, setDataKomoditas, true);
    fetchPelakuUsahaData();
  }, [accessToken]);

  useEffect(() => {
    getCompany(accessToken, setDataCompany, selectedCompanyCategory, true);
  }, [accessToken, selectedCompanyCategory]);

  useEffect(() => {
    getMasterGudang({
      access_token: accessToken,
      setData: setDataMasterGudang,
      tipe_gudang: selectedTipeGudang,
      onlyForInput: true
    });
  }, [accessToken, selectedTipeGudang]);

  useEffect(() => {
    getLaporanPromise(
      accessToken,
      setDataLaporan,
      selectedTipeGudang,
      selectedCompanyCategory,
      selectedKomoditasId,
      selectedCompany,
      selectedMasterGudang,
      selectedMasterPelakuUsahaId,
      selectedDateStart,
      selectedDateEnd,
      true
    );
  }, [
    accessToken,
    selectedTipeGudang,
    selectedCompanyCategory,
    selectedKomoditasId,
    selectedCompany,
    selectedMasterGudang,
    selectedMasterPelakuUsahaId,
    selectedDateStart,
    selectedDateEnd
  ]);

  const handleTipeGudangChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedTipeGudang(id);
  };

  const handleCompanyCategoryChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedCompanyCategory(id);
  };

  const handleCompanyChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedCompany(id);
  };

  const handleMasterGudangChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedMasterGudang(id);
  };

  const handleKomoditasChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedKomoditasId(id);
  };

  const handleMasterPelakuUsahaChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedMasterPelakuUsahaId(id);
  };

  // create data

  const [formData, setFormData] = useState({
    master_sub_komoditas_id: "",
    master_gudang_id: "",
    tanggal: "",
    stok: 0,
  });

  const handleInputChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: isNaN(value) ? value : Number(value),
    }));
  };

  // handling modal add
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const handleOpenModalAdd = () => setOpenModalAdd(true);
  const handleCloseModalAdd = () => setOpenModalAdd(false);

  const handleCreateSubmit = async (rowData) => {
    setIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/data-stok/multiple`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(rowData),
      });

      if (response.ok) {
        toast.success("data berhasil dibuat");
        getLaporanPromise(
          accessToken,
          setDataLaporan,
          selectedTipeGudang,
          selectedCompanyCategory,
          selectedKomoditasId,
          selectedCompany,
          selectedMasterGudang,
          selectedMasterPelakuUsahaId,
          selectedDateStart,
          selectedDateEnd,
          true
        );
        setFormData({
          master_sub_komoditas_id: "",
          master_gudang_id: "",
          tanggal: "",
          stok: 0,
        });
        handleCloseModalAdd();
      } else if (response.status === 422) {
        const detail = await response.json();
        toast.error("Ada kesalahan saat mengisi form");
      } else if (response.status === 400) {
        const detail = await response.json();
        toast.error(detail.detail);
      } else {
        toast.error("data gagal dibuat");
        console.error("Failed to create data");
      }
    } catch (error) {
      console.error("Error during data submission", error);
    }
    setIsLoading(false);
  };

  // delete
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState(null);

  const handleOpenDeleteModal = async (row) => {
    setDeleteModalData(row);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalData(null);
    setDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    if (deleteModalData) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/data-stok/${deleteModalData.id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.ok) {
          toast.success("data berhasil dihapus");
          getLaporanPromise(
            accessToken,
            setDataLaporan,
            selectedTipeGudang,
            selectedCompanyCategory,
            selectedKomoditasId,
            selectedCompany,
            selectedMasterGudang,
            selectedMasterPelakuUsahaId,
            selectedDateStart,
            selectedDateEnd,
            true
          );
          handleCloseDeleteModal();
        } else {
          toast.error("data gagal dihapus");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setIsLoading(false);
  };

  // import
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const [modalAddProduct, setModalAddProduct] = useState(false);

  const handleAddNewProduct = () => {
    setModalAddProduct(true);
  };

  const downloadTemplateFile = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/data-stok/export/template/base`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Template Data Stok.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error(
          "Gagal Mendownload Template. Respon Status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Mendownload Template, error:", error);
    }
    setIsLoading(false);
  };

  const handleUpload = async () => {
    setIsLoading(true);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/data-stok/import/template`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Laporan Error.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          toast.error("Error, ada masalah di excel");
        } else if (response.status === 201) {
          toast.success("Data stok berhasil ditambahkan");
          setModalAddProduct(false);
          await getLaporanPromise(
            accessToken,
            setDataLaporan,
            selectedTipeGudang,
            selectedCompanyCategory,
            selectedKomoditasId,
            selectedCompany,
            selectedMasterGudang,
            selectedMasterPelakuUsahaId,
            selectedDateStart,
            selectedDateEnd,
            true
          );
        } else if (response.status === 400) {
          const data = await response.json();
          toast.error(data.detail);
        } else if (response.status === 500) {
          const data = await response.json();
          toast.error("Internal Server Error");
        } else {
          toast.error("Ada masalah");
        }
      } catch (error) {
        console.error(error);
        toast.error("Error");
      }
    }
    setIsLoading(false);
  };

  // download
  const handleDownloadData = async () => {
    setIsLoading(true);
    const queryParams = new URLSearchParams();

    if (selectedCompanyCategory && selectedCompanyCategory !== "") {
      queryParams.append("company_agency", selectedCompanyCategory);
    }

    if (selectedTipeGudang && selectedTipeGudang !== "") {
      queryParams.append("tipe_gudang", selectedTipeGudang);
    }

    if (selectedKomoditasId && selectedKomoditasId !== "") {
      queryParams.append("master_komoditas_id", selectedKomoditasId);
    }

    if (selectedCompany && selectedCompany !== "") {
      queryParams.append("company_id", selectedCompany);
    }

    if (selectedMasterGudang && selectedMasterGudang !== "") {
      queryParams.append("master_gudang_id", selectedMasterGudang);
    }

    if (selectedMasterPelakuUsahaId && selectedMasterPelakuUsahaId !== "") {
      queryParams.append("master_pelaku_usaha_id", selectedMasterPelakuUsahaId);
    }

    if (selectedDateStart && selectedDateStart !== "") {
      queryParams.append("date_start", selectedDateStart);
    }
    
    if (selectedDateEnd && selectedDateEnd !== "") {
      queryParams.append("date_end", selectedDateEnd);
    }

    queryParams.append("exclude_zero_stok", "true");
    queryParams.append("is_excel", "true");

    const queryString = queryParams.toString();
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/data-stok` +
      (queryString ? `?${queryString}` : "");

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Result Laporan.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to download template. Response Status:", response.status);
      }
    } catch (error) {
      console.error("Error downloading template:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Sidebar />
        <Box
          component="main"
          sx={{
            ml: "-4%",
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <div style={{ padding: "25px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", gap: "10px", flexWrap: 'wrap' }}>
                {dataInitial.role === 'admin' && <SearchBar
                  id={"company-category-autocomplete"}
                  data={dataCompanyAgency}
                  value={
                    dataCompanyAgency.find(
                      (option) => option.id === selectedCompanyCategory
                    ) ?? null
                  }
                  handleChange={handleCompanyCategoryChange}
                  label={"Instansi"}
                />}
                {dataInitial.role === 'admin' && <SearchBar
                  id={"company-autocomplete"}
                  data={dataCompany}
                  value={
                    dataCompany.find(
                      (option) => option.id === selectedCompany
                    ) ?? null
                  }
                  handleChange={handleCompanyChange}
                  label={"Perusahaan"}
                />}
                <SearchBar
                  id={"tipe-gudang-autocomplete"}
                  data={dataTipeGudang}
                  value={
                    dataTipeGudang.find(
                      (option) => option.id === selectedTipeGudang
                    ) ?? null
                  }
                  handleChange={handleTipeGudangChange}
                  label={"Tipe Gudang"}
                />
                <SearchBar
                  id={"master-gudang-autocomplete"}
                  data={dataMasterGudang}
                  value={
                    dataMasterGudang.find(
                      (option) => option.id === selectedMasterGudang
                    ) ?? null
                  }
                  handleChange={handleMasterGudangChange}
                  label={"Gudang"}
                />
                <SearchBar
                  id={"komoditas-autocomplete"}
                  data={dataKomoditas}
                  value={
                    dataKomoditas.find(
                      (option) => option.id === selectedKomoditasId
                    ) ?? null
                  }
                  handleChange={handleKomoditasChange}
                  label={"Komoditas"}
                />
                <SearchBar
                  id={"master_pelaku_usaha-autocomplete"}
                  data={dataMasterPelakuUsaha}
                  value={
                    dataMasterPelakuUsaha.find(
                      (option) => option.id === selectedMasterPelakuUsahaId
                    ) ?? null
                  }
                  handleChange={handleMasterPelakuUsahaChange}
                  label={"Pelaku Usaha"}
                />
                <TextField
                  id="outlined-select-tanggal-awal"
                  label="Tanggal Awal"
                  select
                  // sx={{ width: "32.5%", height: 40 }}
                  size="small"
                  value={selectedDateStart}
                  onChange={(e) => {
                    setSelectedDateStart(e.target.value);
                    if (e.target.value >= selectedDateEnd) {
                      setSelectedDateEnd(e.target.value);
                    }
                  }}
                  InputProps={{
                    endAdornment: selectedDateStart && (
                      <IconButton sx={{ mr: 1.5 }} edge="end" onClick={() => setSelectedDateStart(null)}>
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                  SelectProps={{
                    IconComponent: () => (
                      <FilterAltIcon style={{ fontSize: "16px", color: "grey", marginRight: "10px" }} />
                    ),
                  }}
                >
                  {previousFridayDates.map((date) => (
                    <MenuItem key={date} value={date}>
                      {new Date(date).toLocaleDateString("id-ID", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-select-tanggal-akhir"
                  label="Tanggal Akhir"
                  select
                  // sx={{ width: "32.5%", height: 40 }}
                  size="small"
                  value={selectedDateEnd}
                  onChange={(e) => {
                    setSelectedDateStart(e.target.value);
                    if (e.target.value <= selectedDateStart) {
                      setSelectedDateEnd(e.target.value);
                    }
                  }}
                  InputProps={{
                    endAdornment: selectedDateEnd && (
                      <IconButton sx={{ mr: 1.5 }} edge="end" onClick={() => setSelectedDateEnd(null)}>
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                  SelectProps={{
                    IconComponent: () => (
                      <FilterAltIcon style={{ fontSize: "16px", color: "grey", marginRight: "10px" }} />
                    ),
                  }}
                >
                  {previousFridayDates.map((date) => (
                    <MenuItem key={date} value={date}>
                      {new Date(date).toLocaleDateString("id-ID", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // width: "30%",
                }}
              >
                <Button
                  variant="outlined"
                  disableRipple
                  sx={{
                    width: "90px",
                    height: 40,
                    ml: 8,
                    color: "#32AE2F",
                    borderColor: "#32AE2F",
                    mr: 1,
                  }}
                  onClick={handleOpenModalAdd}
                >
                  Create
                </Button>
                <Button
                  variant="outlined"
                  disableRipple
                  sx={{
                    width: "90px",
                    height: 40,
                    color: "#32AE2F",
                    borderColor: "#32AE2F",
                    mr: 1,
                  }}
                  onClick={handleAddNewProduct}
                >
                  Import
                </Button>
                <Button
                  variant="outlined"
                  disableRipple
                  sx={{
                    width: "90px",
                    height: 40,
                    color: "#32AE2F",
                    borderColor: "#32AE2F",
                  }}
                  disabled={isLoading}
                  onClick={handleDownloadData}
                >
                  {isLoading ? "Loading..." : "Download"}
                </Button>
              </div>
            </div>
            <DataGridLaporan
              loading={isLoading}
              rows={dataLaporan}
              handleOpenDeleteModal={handleOpenDeleteModal}
            />
          </div>
        </Box>
        <AddDataModal
          modalStyle={style}
          formData={formData}
          isLoading={isLoading}
          company={dataCompany}
          handleClose={handleCloseModalAdd}
          open={openModalAdd}
          handleInputChange={handleInputChange}
          handleCreateSubmit={handleCreateSubmit}
          access_token={accessToken}
        />
        <DeleteDataModal
          modalStyle={style}
          isLoading={isLoading}
          deleteModalOpen={deleteModalOpen}
          handleCloseDeleteModal={handleCloseDeleteModal}
          handleDelete={handleDelete}
        />
        {/* import modal */}
        <Modal
          open={modalAddProduct}
          onClose={() => setModalAddProduct(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            border: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              ...style,
              width: 500,
              height: 250,
              border: "0px solid #000",
              boxShadow: 24,
              borderRadius: 3,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                borderRadius: "0 0 3px 3px",
                flex: 1,
              }}
            >
              <Tooltip title="Close Modal">
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setModalAddProduct(false)}
                  sx={{ position: "absolute", top: 20, right: 30 }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <div>
                <div style={{ marginBottom: "25px" }}>
                  <center>
                    <h3>Import Laporan</h3>
                  </center>
                  <input type="file" onChange={handleFileChange} />{" "}
                  {/* File input */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <BootstrapButton
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "49%",
                        mt: 4,
                        borderRadius: 2.5,
                        color: "#FFF",
                        fontSize: "16px",
                        backgroundColor: "#32AE2F",
                        "&:hover": {
                          backgroundColor: "#1d691b",
                          boxShadow: "none",
                        },
                      }}
                      onClick={downloadTemplateFile}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Template"}
                    </BootstrapButton>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{
                        width: "49%",
                        ml: "2%",
                        mt: 4,
                        borderRadius: 2.5,
                        fontSize: "16px",
                        backgroundColor: "#32AE2F",
                        "&:hover": {
                          backgroundColor: "#1d691b",
                          boxShadow: "none",
                        },
                      }}
                      onClick={handleUpload}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Upload"}
                    </BootstrapButton>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
}
