import axios from "axios";

export const getPaginationTotal = async (access_token, setTotalDataMasterAbsensi, selectedDate) => {
  try {
    const queryParams = new URLSearchParams();

    if (selectedDate && selectedDate !== "") {
      queryParams.append("tanggal", selectedDate);
    }

    const queryString = queryParams.toString();

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/attendance?${queryString}`;

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + access_token,
        'Content-Type': 'application/json'
      },
    });

    let result_data = response?.data?.data;

    setTotalDataMasterAbsensi(result_data);
  } catch (error) {
    return {
      data: []
    };
  }
};

export const fetchDashboardDate = async (token, setDataDashboardDate) => {
  const queryParams = new URLSearchParams();

  const queryString = queryParams.toString();
  const apiUrl =
    `${process.env.REACT_APP_API_URL}/api/v1/dashboard/date` +
    (queryString ? `?${queryString}` : "");

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    let result_data = response?.data?.data;
    setDataDashboardDate(result_data);
  } catch (e) {
    setDataDashboardDate([]);
  }
};

export const fetchMasterKomoditas = async (token, setDataMasterKomoditas) => {
  const queryParams = new URLSearchParams();

  const queryString = queryParams.toString();
  const apiUrl =
    `${process.env.REACT_APP_API_URL}/api/v1/komoditas` +
    (queryString ? `?${queryString}` : "");

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    let result_data = response?.data?.data;
    setDataMasterKomoditas(result_data);
  } catch (e) {
    setDataMasterKomoditas([]);
  }
};

export const fetchPelakuUsaha = async (token, setDataPelakuUsaha) => {
  const queryParams = new URLSearchParams();

  const queryString = queryParams.toString();
  const apiUrl =
    `${process.env.REACT_APP_API_URL}/api/v1/master_pelaku_usaha` +
    (queryString ? `?${queryString}` : "");

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    let result_data = response?.data?.data;
    setDataPelakuUsaha(result_data);
  } catch (e) {
    setDataPelakuUsaha([]);
  }
};

export const fetchPerusahaan = async (
  token,
  setDataPerusahaan,
  selectedPelakuUsaha
) => {
  const queryParams = new URLSearchParams();

  if (selectedPelakuUsaha && selectedPelakuUsaha !== "") {
    queryParams.append("master_pelaku_usaha_id", selectedPelakuUsaha);
  }

  const queryString = queryParams.toString();
  const apiUrl =
    `${process.env.REACT_APP_API_URL}/api/v1/company` +
    (queryString ? `?${queryString}` : "");

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    let result_data = response?.data?.data;
    setDataPerusahaan(result_data);
  } catch (e) {
    setDataPerusahaan([]);
  }
};

export const fetchAbsensi = async (
  token,
  setDataMasterAbsensi,
  selectedDate,
  selectedMasterKomoditas,
  selectedPelakuUsaha,
  selectedPerusahaan,
  selectedStatusKomoditas
) => {
  const queryParams = new URLSearchParams();

  if (selectedDate && selectedDate !== "") {
    queryParams.append("tanggal", selectedDate);
  }

  if (selectedMasterKomoditas && selectedMasterKomoditas !== "") {
    queryParams.append("master_komoditas_id", selectedMasterKomoditas);
  }

  if (selectedPelakuUsaha && selectedPelakuUsaha !== "") {
    queryParams.append("master_pelaku_usaha_id", selectedPelakuUsaha);
  }

  if (selectedPerusahaan && selectedPerusahaan !== "") {
    queryParams.append("company_id", selectedPerusahaan);
  }

  if (selectedStatusKomoditas && selectedStatusKomoditas !== "") {
    queryParams.append("kapasitas_status", selectedStatusKomoditas);
  }

  const queryString = queryParams.toString();
  const apiUrl =
    `${process.env.REACT_APP_API_URL}/api/v1/attendance` +
    (queryString ? `?${queryString}` : "");

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    let result_data = response?.data?.data;
    setDataMasterAbsensi(result_data);
  } catch (e) {
    setDataMasterAbsensi([]);
  }
};
